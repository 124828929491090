import { AxiosError } from 'axios';
import { ApiErrorResponse, IResponseData } from '@/shared/types';
import { isDev } from '@/shared/utils/constants/global.config';
import { createDefaultApiResponseData } from '../config/default-response-data';
import useManagersList from '../manager/useManagersList';

const managersList = useManagersList();

export default async function useApiErrorResponseInterceptor(
  error: AxiosError<IResponseData>
): Promise<ApiErrorResponse> {
  isDev &&
    console.error(
      'Received an error in the BaseApiErrorManagerController',
      error
    );

  for (let index = 0; index < managersList.length; index++) {
    const manager = managersList[index];

    if (manager.checkIfErrorMatchesTheResponse(error)) {
      const managerResult = await manager.handleErrorResponse(error);

      if (managerResult) return managerResult;

      break;
    }
  }
  const errorResponse = createDefaultApiResponseData(
    error.response?.data as IResponseData
  );

  return errorResponse;
}
