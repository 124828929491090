import { ACTION_TYPE, ROUTE_KEY_TYPE } from '@/shared/utils/constants/common.ts';

export const depositsRoutes = [
  {
    path: '/deposits',
    name: 'deposits',
    meta: {
      keyName: ROUTE_KEY_TYPE.ACCOUNTS,
      keyNameAction: ACTION_TYPE.VIEW
    },
    component: () =>
      import('@/pages/deposits/deposits-list/DepositsList.vue')
  },
  {
    path: '/deposits/open',
    name: 'deposits.open',
    meta: {
      keyName: ROUTE_KEY_TYPE.ACCOUNTS,
      keyNameAction: ACTION_TYPE.VIEW
    },
    component: () =>
      import('@/pages/deposits/open-new-deposit/OpenNewDeposit.vue')
  },
  {
    path: '/deposits/open/term',
    name: 'deposits.open.term',
    meta: {
      keyName: ROUTE_KEY_TYPE.ACCOUNTS,
      keyNameAction: ACTION_TYPE.VIEW
    },
    component: () =>
      import('@/pages/deposits/open-term-deposit/OpenTermDeposit.vue')
  },
  {
    path: '/deposits/open/overnight',
    name: 'deposits.open.overnight',
    meta: {
      keyName: ROUTE_KEY_TYPE.ACCOUNTS,
      keyNameAction: ACTION_TYPE.VIEW
    },
    component: () =>
      import('@/pages/deposits/open-overnight-deposit/OpenOvernightDeposit.vue')
  },
  {
    path: '/deposits/:id',
    name: 'deposits.details',
    meta: {
      keyName: ROUTE_KEY_TYPE.ACCOUNTS,
      keyNameAction: ACTION_TYPE.VIEW
    },
    component: () =>
      import('@/pages/deposits/deposit-details/DepositDetailsPage.vue')
  }
];
