import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useCommonStore = defineStore(
  'common',
  () => {
    const appVersion = ref('v1');

    const showStubModal = ref(false);
    const showOnboardingHelpModal = ref(false);
    const isShowHeaderStickyAlert = ref(false);
    const isInited = ref(false);
    const showUzumMarketBannerOnce = ref(true);
    const showInformationModalChangeText = ref(false);
    const showCounterpartyOnce = ref(true);
    const showMunisInformationOnce = ref(true);

    return {
      showStubModal,
      showOnboardingHelpModal,
      isShowHeaderStickyAlert,
      isInited,
      appVersion,
      showUzumMarketBannerOnce,
      showInformationModalChangeText,
      showCounterpartyOnce,
      showMunisInformationOnce
    };
  },
  {
    persist: {
      key: 'common',
      storage: window.localStorage,
      paths: [
        'appVersion',
        'showInformationModalChangeText',
        'showUzumMarketBannerOnce',
        'showCounterpartyOnce',
        'showMunisInformationOnce'
      ]
    }
  }
);
