import { AxiosError } from 'axios';
import { ApiErrorResponse } from '@/shared/types';
import { IBaseApiErrorManager } from './managers.types';

export default function useApiPassErrorManager(): IBaseApiErrorManager {
  return {
    checkIfErrorMatchesTheResponse(error: AxiosError): boolean {
      return !!error.config?.passError;
    },

    async handleErrorResponse(error: AxiosError): Promise<ApiErrorResponse> {
      return Promise.reject(error);
    }
  };
}
