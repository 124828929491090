export enum AuthBrowserStoreKey {
  accessToken = 'accessToken',
  refreshToken = 'refreshToken',
}

export enum EPhoneVerificationState {
  NOT_VERIFIED,
  VERIFIED,
  VERIFICATION_REQUIRED
}

export interface ILogin {
  accessToken: string | null;
  refreshToken: string | null;
  userId: number;
  userName: string;
  needConfirm: boolean;
  confirmToken?: string;
  confirmPhone?: string;
  confirmEmail: string | null;
}

export interface ILoginForm {
  login: string | null;
  password: string | null;
  confirmCode?: string | null;
  confirmToken?: string | null;
  confirmType?: EConfirmType | null;
}

export interface IAction {
  id: number;
  name: string;
  key: string;
}

export interface IActionPage extends IAction {
  actions?: IAction[];
}

export interface IChangePasswordConfirm {
  confirmToken: string;
  confirmCode: string;
  password: string;
  newPassword: string;
}

export interface IChangePasswordResponse {
  confirmToken: string;
  confirmPhone: string;
}

export interface IChangePhoneResponse extends IChangePasswordResponse {}

export interface IChangePhoneConfirm {
  confirmToken: string;
  confirmCode: string;
  newPhone: string;
}

export interface IPasswordCheckParams {
  password: string;
}

export interface IPasswordCheckResponse {
  userId: number;
  isCorrectPassword: boolean;
}

export interface IRefreshToken {
  accessToken: string;
  refreshToken: string;
}

export interface IResendConfirmCode {
  confirmToken: string;
}

export interface IAuthConfirm {
  userId: number;
  confirmToken: string;
  confirmCode?: string;
  keyType?: number;
  sign?: {
    objectId: number;
    textToSign: string;
    signature: string;
  };
}

export interface IFetchConfirmTypesPayload {
  login: string;
  password: string;
}

export interface IFetchConfirmTypesResponse {
  confirmTypes: EConfirmType[];
  needConfirm: boolean;
  userId: number;
  confirmPhone: string | null;
  confirmCertificates: string[];
  phoneVerificationState: EPhoneVerificationState;
}

export enum EConfirmType {
  bySms,
  bySignature,
  byEmail
}

export interface ICheckCertificate {
  isActual: boolean;
  isBelongToUser: boolean;
}

export interface IResendPhonePayload {
  userId: number;
  confirmToken: string;
}
