import axios, { AxiosResponse } from 'axios';
import {
  ICertificates,
  IEpassSignature,
  IResponseData,
  ISign
} from '@/shared/types';
import commonApi from '../plugins/axios';

interface IResponse {
  error: boolean;
}

const api = axios.create({
  baseURL: 'http://localhost:6210'
});

api.interceptors.response.use(
  (response): AxiosResponse => {
    if (typeof response.data === 'string') {
      response.data = {
        error: true
      };
      return response.data;
    }

    if (!response.data) response.data = {};
    response.data.error = false;

    return response.data;
  },
  () => {
    return {
      error: true
    };
  }
);

export const fetchCertificates = (): Promise<IResponse & ICertificates> => {
  return api.get('/crypto/getCertInfo');
};

//ISign

export const signText = (
  textToSign: string,
  eSign: string
): Promise<IResponse & ISign> => {
  return api.post('/crypto/signMSG', {
    obj: textToSign,
    cms: false,
    detached: false,
    cert_sn: eSign
  });
};

export const fetchEpassSignature = async (): Promise<
  IResponseData<IEpassSignature>
> => {
  const { data } = await commonApi
    .get(':baseUrl/signatures/epass/active', {
      passError: true
    })
    .catch(() => ({ data: { error: true } }));
  return data;
};
