export const ACCOUNT_NUMBER_LENGTH = 20;
export const INN_LENGTH = 9;
export const MAX_LABEL_LENGTH = 80;
export const MAX_NUMBER_LENGTH = 50;
export const MAX_COMMENT_LENGTH = 450;
export const MIN_COMMENT_LENGTH = 3;
export const INN_OR_PINFL_LENGTH = 14;
export const ID_MAX_LIMIT = 10;
export const localeFromStorage = 'locale';
export const SELECT_ALL = 'all';
export const MAX_PAGE_SIZE = 200000;
export const CONTACT_PHONE_NUMBER = '+998781484008';

export const ROUTE_KEY_TYPE = {
  CURRENCY_TRANSFERS: 'CURRENCY_TRANSFERS',
  PAYROLL_ONTO_CARDS: 'PAYROLL_ONTO_CARDS',
  EMPLOYEES_LIST_HUMO: 'EMPLOYEES_LIST_HUMO',
  PAYROLL_LIST_HUMO: 'PAYROLL_LIST_HUMO',
  TELEGRAM_INFO: 'TELEGRAM_INFO',
  MUNIS_PAYMENT: 'MUNIS_PAYMENT',
  CHARITY_DOCUMENTS: 'CHARITY_DOCUMENTS',
  EMAIL_INFO: 'EMAIL_INFO',
  SMS_INFO: 'SMS_INFO',
  DOCUMENTS: 'DOCUMENTS',
  WORK_WITH_DOCUMENTS_ONLINE: 'WORK_WITH_DOCUMENTS_ONLINE',
  DOCUMENTS_LOADED: 'DOCUMENTS_LOADED',
  DOCUMENTS_FOR_ACCEPT: 'DOCUMENTS_FOR_ACCEPT',
  DOCUMENT_TEMPLATES: 'DOCUMENT_TEMPLATES',
  DOCUMENTS_IN_BANK: 'DOCUMENTS_IN_BANK',
  WORK_WITH_DOCUMENTS: 'WORK_WITH_DOCUMENTS',
  PAYROLL_LIST: 'PAYROLL_LIST',
  EMPLOYEES_LIST: 'EMPLOYEES_LIST',
  CONTRACTS: 'CONTRACTS',
  ACCOUNTS: 'ACCOUNTS',
  BUY_SELL_CURRENCY_REQUEST: 'BUY_SELL_CURRENCY_REQUEST',
  CARDFILE_2: 'CARDFILE_2',
  CARDFILE_1: 'CARDFILE_1',
  SWIFT: 'SWIFT',
  CONVERSION_REQUESTS: 'CONVERSION_REQUESTS',
  CARDFILE_ELECTRONIC: 'CARDFILE_ELECTRONIC',
  CARDS: 'CARDS',
  // reports
  REP_ACCOUNT_STATEMENT: 'REP_ACCOUNT_STATEMENT',
  REP_PAYMENT_DOCUMENTS: 'REP_PAYMENT_DOCUMENTS',
  REP_CORP_CARDS: 'REP_CORP_CARDS',
  REP_TERMINALS: 'REP_TERMINALS',
  REP_CARDFILE_LIST: 'REP_CARDFILE_LIST',
  REP_ACCOUNT_WORKING_INFO: 'REP_ACCOUNT_WORKING_INFO',
  REP_PROCESSED_DOCUMENTS_LIST_FOR_PERIOD:
    'REP_PROCESSED_DOCUMENTS_LIST_FOR_PERIOD',
  REP_STATEMENT_FOR_PERIOD: 'REP_STATEMENT_FOR_PERIOD',
  REP_PERSONAL_ACCOUNT_STATEMENT_EXTENDED:
    'REP_PERSONAL_ACCOUNT_STATEMENT_EXTENDED',
  REP_TURNOVER_BALANCE_LIST: 'REP_TURNOVER_BALANCE_LIST', // SALDO
  REP_PERSONAL_ACCOUNT_STATEMENT: 'REP_PERSONAL_ACCOUNT_STATEMENT',
  REP_PERSONAL_ACCOUNT_STATEMENT_CREDIT_TXT:
    'REP_PERSONAL_ACCOUNT_STATEMENT_CREDIT_TXT',
  REP_CARD_TERMINAL_HISTORY: 'REP_CARD_TERMINAL_HISTORY',
  DEPOSITS: 'DEPOSITS',
  APPL_FOR_ACC_OPEN: 'APPL_FOR_ACC_OPEN',
  STATISTICS: 'STATISTICS'
};

export const ACTION_TYPE = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  SEND: 'SEND',
  CORRECT: 'CORRECT',
  APPROVE: 'APPROVE',
  CREATE: 'CREATE',
  SEND_TO_BANK: 'SEND_TO_BANK',
  DELETE: 'DELETE',
  ADD_DOCUMENT_BY_TEMPLATE: 'ADD_DOCUMENT_BY_TEMPLATE',
  SAVE_AS_TEMPLATE: 'SAVE_AS_TEMPLATE',
  COPY_DOCUMENT: 'COPY_DOCUMENT',
  LOAD_DOCUMENTS_FROM_FILE: 'LOAD_DOCUMENTS_FROM_FILE',
  SAVE_AND_CREATE_NEW_DOCUMENT: 'SAVE_AND_CREATE_NEW_DOCUMENT',
  DOCUMENT_ACTIONS_HISTORY: 'DOCUMENT_ACTIONS_HISTORY',
  PRINT_DOCUMENT: 'PRINT_DOCUMENT',
  NULLIFY: 'NULLIFY',
  VIEW: 'VIEW',
  ANOR_TO_ACCOUNT: 'ANOR_TO_ACCOUNT',
  ANOR_TO_TREASURY: 'ANOR_TO_TREASURY',
  ANOR_TO_BUDGET: 'ANOR_TO_BUDGET',
  INPUT_BUY_APPLICATION: 'INPUT_BUY_APPLICATION',
  CONFIRM_BUY_APPLICATION: 'CONFIRM_BUY_APPLICATION',
  APPROVE_BUY_APPLICATION: 'APPROVE_BUY_APPLICATION',
  INPUT_SALE_APPLICATION: 'INPUT_SALE_APPLICATION',
  CONFIRM_SALE_APPLICATION: 'CONFIRM_SALE_APPLICATION',
  APPROVE_SALE_APPLICATION: 'APPROVE_SALE_APPLICATION',
  PAYMENT: 'PAYMENT',
  PARTIAL_REJECT: 'PARTIAL_REJECT',
  REJECT: 'REJECT',
  RENEWAL: 'RENEWAL',
  ACCEPT: 'ACCEPT',
  INPUT_SWIFT: 'INPUT_SWIFT',
  CHECK_SWIFT: 'CHECK_SWIFT',
  APPROVE_SWIFT: 'APPROVE_SWIFT',
  CONFIRM_CONVERSION: 'CONFIRM_CONVERSION'
};

export const ACCOUNT_TYPE = {
  CAN_PAY_GENERALLY: 'CAN_PAY_GENERALLY',
  REPLENISHMENT_CORP: 'REPLENISHMENT_CORP',
  REPLENISHMENT_DIVIDENDS: 'REPLENISHMENT_DIVIDENDS',
  REPLENISHMENT_OTHER: 'REPLENISHMENT_OTHER',
  TRANSIT_ACCOUNT_UZCARD: 'TRANSIT_ACCOUNT_UZCARD',
  TRANSIT_ACCOUNT_HUMO: 'TRANSIT_ACCOUNT_HUMO',
  TRANSIT_ACCOUNT_VISA: 'TRANSIT_ACCOUNT_VISA',
  TRANSIT_ACCOUNT_MASTER: 'TRANSIT_ACCOUNT_MASTER',
  TRANSIT_ACCOUNT_UPI: 'TRANSIT_ACCOUNT_UPI',
  CAN_PAY_SWIFT: 'CAN_PAY_SWIFT',
  CAN_BUY_CURRENCY_FROM: 'CAN_BUY_CURRENCY_FROM',
  BUY_CURRENCY_VAL_ACC: 'BUY_CURRENCY_VAL_ACC',
  BUY_CURRENCY_SPEC_VAL_ACC: 'BUY_CURRENCY_SPEC_VAL_ACC',
  BUY_CURRENCY_REZERV_ACC: 'BUY_CURRENCY_REZERV_ACC',
  CAN_SELL_CURRENCY_FROM: 'CAN_SELL_CURRENCY_FROM',
  SELL_CURRENCY_SUM_ACC: 'SELL_CURRENCY_SUM_ACC',
  SELL_CURRENCY_SPEC_VAL_ACC: 'SELL_CURRENCY_SPEC_VAL_ACC',
  SELL_CURRENCY_REZERV_ACC: 'SELL_CURRENCY_REZERV_ACC',
  QR_ONLINE_TRANSIT_ACC: 'QR_ONLINE_TRANSIT_ACC'
};

export const PAYMENT_HISTORY_SOURCE = {
  DBO: 1,
  BANK: 2
};

export const LOCALES_API = {
  uz: 'uz-Latn-UZ',
  ru: 'ru-RU',
  en: 'en-US'
} as const;
