import { createApp } from 'vue';
// lib
import Maska from 'maska';
import { create, NButton } from 'naive-ui';
// plugins
import i18n from './shared/plugins/i18n';
import pinia from './shared/plugins/pinia';
import router from './app/router';
import './shared/plugins/firebase';
// styles
import './app/styles/main.scss';
// components
import App from './App.vue';

// app
const app = createApp(App);

const naive = create({
  components: [ NButton ]
});

app.config.errorHandler = () => {};

app.use(naive);
app.use(Maska);
app.use(router);
app.use(i18n);
app.use(pinia);
app.mount('#app');
