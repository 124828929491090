import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyBpVepaz_qWJ4YPDPPpEP9io3Ly6NneLh4',
  authDomain: 'kb-dbo.firebaseapp.com',
  projectId: 'kb-dbo',
  storageBucket: 'kb-dbo.appspot.com',
  messagingSenderId: '159587492211',
  appId: '1:159587492211:web:7561a2b0a2bd257f3e2506',
  measurementId: 'G-K9DN5EK00R'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

export { analytics };
