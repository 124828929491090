import { AxiosResponse } from 'axios';
import { IResponseData } from '@/shared/types';

export default async function useApiSuccessResponseInterceptor(
  response: AxiosResponse
): Promise<AxiosResponse<IResponseData>> {
  if (!response.data) response.data = {};

  const resultData = response.data?.result;

  const result = resultData?.items || resultData;

  response.data.error = false;
  response.data.items = [];

  response.data.totalCount = resultData?.totalCount || 0;
  response.data.totalPages = resultData?.totalPages || 0;

  if (Array.isArray(result)) {
    response.data.items = result;
  } else {
    response.data.item = result === undefined ? {} : result;
  }

  return response;
}
