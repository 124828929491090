import {
  Component,
  computed,
  defineAsyncComponent,
  shallowRef,
  watch
} from 'vue';
import { IProps } from './base-icon.types';

export default function useBaseIcon(props: IProps) {
  const SVGComponent = shallowRef<Component | undefined>(undefined);

  const isSlot = computed(() => typeof props.icon !== 'string');

  const updateSVGComponent = () => {
    SVGComponent.value = defineAsyncComponent(
      () => import(`../../icons/${props.icon}.svg`)
    );
  };

  watch(() => props.icon, updateSVGComponent, { immediate: true });

  return {
    SVGComponent,
    isSlot
  };
}
