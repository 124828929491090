import { logEvent } from 'firebase/analytics';
import { analytics } from '@/shared/plugins/firebase';
import { useAuthStore } from '@/app/store/authStore';

export const firebaseLogEvent = ({
  eventName,
  user_properties,
  event_params
}: {
  eventName: string;
  user_properties?: object;
  event_params?: object;
}) => {
  const { selectedBranch, user } = useAuthStore();

  logEvent(analytics, eventName, {
    event_time: new Date(),
    ...user_properties,
    ...event_params,
    os: 'new_web',
    login: user?.userName,
    current_business_id: selectedBranch.replace('-', '_')
  });
};
