import {
  IPaymentCard,
  IResponseData
} from '@/shared/types';

export const mapClientCardsResponse = (data: IResponseData<IPaymentCard>): IResponseData<IPaymentCard> => {
  return {
    ...data,
    items: data?.items.map((v: IPaymentCard) => {
      return {
        ...v,
        label: `${v.maskedCard} - ${v.accountName}`,
        value: {
          maskedPAN: v.maskedCard?.replace(/\s/g, ''),
          cardAccount: v.cardAccountNumber
        },
        maskedCard: `${v.maskedCard} - ${v.embossedChName}`
      };
    }) || []
  };
};