<script lang="ts" setup>
import { computed, provide } from 'vue';

const props = defineProps({
  version: {
    type: String,
    default: 'v1'
  }
});

provide(
  'version',
  computed(() => props.version)
);
</script>

<template>
  <slot />
</template>
