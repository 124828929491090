import { AxiosError, AxiosRequestConfig } from 'axios';
import axiosInstance from '@/shared/plugins/axios';
import { ApiErrorResponse } from '@/shared/types';
import { IBaseApiErrorManager } from './managers.types';

export default function useApiRetryManager(): IBaseApiErrorManager {
  return {
    checkIfErrorMatchesTheResponse(error: AxiosError): boolean {
      return !!error.config?.retryCount;
    },

    async handleErrorResponse(error: AxiosError): Promise<ApiErrorResponse> {
      if (error.config?.retryCount) {
        error.config.retryCount--;

        if (error.config.retryCount === 1) error.config.retryCount = 0;

        return axiosInstance.request(error.config as AxiosRequestConfig);
      }
    }
  };
}
