import { ACTION_TYPE, ROUTE_KEY_TYPE } from '@/shared/utils/constants/common.ts';

export const inquiriesRoutes = [
  {
    path: '/my-inquiries',
    name: 'inquiries',
    meta: {
      keyName: ROUTE_KEY_TYPE.APPL_FOR_ACC_OPEN,
      keyNameAction: ACTION_TYPE.VIEW
    },
    component: () => import('@/pages/inquiries/my-inquiries/MyInquiriesPage.vue')
  },
  {
    path: '/my-inquiries/create',
    name: 'inquiries.create',
    meta: {
      keyName: ROUTE_KEY_TYPE.APPL_FOR_ACC_OPEN,
      keyNameAction: ACTION_TYPE.ADD
    },
    component: () => import('@/pages/inquiries/create-inquiries-form/CreateInquiriesPage.vue')
  },
  {
    path: '/my-inquiries/statistics',
    name: 'inquiries.stats',
    meta: {
      keyName: ROUTE_KEY_TYPE.STATISTICS,
      keyNameAction: ACTION_TYPE.VIEW
    },
    component: () => import('@/pages/inquiries/inquiries-statistics-page/InquiriesStatisticsPage.vue')
  }
];
