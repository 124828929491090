import api from '../plugins/axios';
import { IResponseData } from '../types';
import {
  IActionPage,
  IAuthConfirm,
  IChangePasswordConfirm,
  IChangePasswordResponse,
  IChangePhoneConfirm,
  IChangePhoneResponse,
  IFetchConfirmTypesPayload,
  IFetchConfirmTypesResponse,
  ILogin,
  ILoginForm,
  IPasswordCheckParams,
  IPasswordCheckResponse,
  IRefreshToken,
  IResendConfirmCode,
  IResendPhonePayload
} from '../types/auth.types';

export const fetchConfirmTypes = async (
  reqData: IFetchConfirmTypesPayload
): Promise<IResponseData<IFetchConfirmTypesResponse>> => {
  const { data } = await api.post('/api/confirm-types', reqData, {
    optionalAuth: true,
    hideErrorMessage: true
  });

  return data;
};

export const signIn = async (
  reqData: ILoginForm
): Promise<IResponseData<ILogin>> => {
  const { data } = await api.post('/api/auth', reqData, {
    optionalAuth: true
  });

  return data;
};

export const confirmOtpCode = async (
  reqData: IAuthConfirm
): Promise<IResponseData<ILogin>> => {
  const { data } = await api.put('/api/auth/confirm', reqData, {
    optionalAuth: true,
    hideErrorMessage: true
  });

  return data;
};

export const signOut = async (
  accessToken: string,
  skipRefreshToken = true
): Promise<IResponseData<ILogin>> => {
  const { data } = await api.get('/api/logout', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    optionalAuth: true,
    skipRefreshTokenHandling: skipRefreshToken
  });

  return data;
};

export const fetchActions = async (): Promise<IResponseData<IActionPage>> => {
  const { data } = await api.get('/api/auth/:businessCode/:branch/actions');

  return data;
};

export const fetchAgentsActions = async (): Promise<IResponseData<IActionPage>> => {
  const { data } = await api.get('/api/auth/:partnerId/actions');
  return data;
};
export const changePasswordRequest = async (): Promise<
  IResponseData<IChangePasswordResponse>
> => {
  const { data } = await api.get('/api/password/change');

  return data;
};

export const changePasswordConfirm = async (
  reqData: IChangePasswordConfirm
): Promise<IResponseData> => {
  const { data } = await api.post('/api/password/change/confirm', reqData);

  return data;
};

export const passwordCheck = async (
  params: IPasswordCheckParams
): Promise<IResponseData<IPasswordCheckResponse>> => {
  const { data } = await api.get('/api/password/check', { params });

  return data;
};

export const refreshToken = async (
  reqData: IRefreshToken
): Promise<IResponseData<IRefreshToken>> => {
  const { data } = await api.post('/api/refresh', reqData, {
    logoutOnError: true,
    retryCount: 3,
    hideErrorMessage: true,
    skipRefreshTokenHandling: true
  });

  return data;
};

export const resendConfirmCode = async (
  reqData: IAuthConfirm
): Promise<IResponseData<IResendConfirmCode>> => {
  const { data } = await api.put('/api/auth/resend', reqData, {
    optionalAuth: true
  });

  return data;
};

export const confirmBySignature = async (
  reqData: IAuthConfirm
): Promise<IResponseData<IRefreshToken>> => {
  const { data } = await api.put('/api/auth/confirm-by-signature', reqData, {
    optionalAuth: true
  });

  return data;
};

export const confirmByEmail = async (
  reqData: IAuthConfirm
): Promise<IResponseData<IRefreshToken>> => {
  const { data } = await api.put('/api/auth/confirm-by-email-code', reqData, {
    optionalAuth: true
  });

  return data;
};

export const changePhoneRequest = async (
  accessToken: string,
  newPhone: string
): Promise<IResponseData<IChangePhoneResponse>> => {
  const { data } = await api.get('/api/phone/change', {
    params: { newPhone },
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    optionalAuth: true,
    skipRefreshTokenHandling: true
  });

  return data;
};

export const changePhoneConfirm = async (
  accessToken: string,
  reqData: IChangePhoneConfirm
): Promise<IResponseData> => {
  const { data } = await api.post('/api/phone/change/confirm', reqData, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    optionalAuth: true,
    skipRefreshTokenHandling: true
  });

  return data;
};

export const changePhoneResend = async (
  accessToken: string,
  reqData: IResendPhonePayload
): Promise<IResponseData<IResendConfirmCode>> => {
  const { data } = await api.put('/api/auth/resend', reqData, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    optionalAuth: true,
    skipRefreshTokenHandling: true
  });

  return data;
};
