import { ref, toRefs } from 'vue';
import { useRoute } from 'vue-router';
import { defineStore } from 'pinia';
import { ApiAuth } from '@/shared/api';
import { IActionPage } from '@/shared/types/auth.types';
import { useCommonStore } from './commonStore';

export const usePermissionStore = defineStore('permission', () => {
  const route = useRoute();
  const { appVersion } = toRefs(useCommonStore());

  const permissions = ref<IActionPage[]>([]);

  const fetchActions = async () => {
    const { items } = await ApiAuth.fetchActions();

    if (items) {
      permissions.value = items;

      const WEB_NEW_DESIGN = permissions.value.find(
        (item) => item.key === 'WEB_NEW_DESIGN'
      );

      appVersion.value = WEB_NEW_DESIGN ? 'v2' : 'v1';

      return permissions.value;
    }
  };

  const fetchAgentActions = async () => {
    const { items } = await ApiAuth.fetchAgentsActions();

    if (items) {
      permissions.value = items;

      appVersion.value = 'v2';

      return permissions.value;
    }
  };

  const has = (action?: string | string[], routeMetaKeyName?: string) => {
    const currentActionPage = permissions.value.find(
      (item) => item.key === (routeMetaKeyName || route.meta.keyName)
    );

    return currentActionPage?.actions?.some((item) => {
      if (Array.isArray(action)) {
        return action.includes(item.key);
      }
      return item.key === action;
    });
  };

  return {
    permissions,
    fetchActions,
    fetchAgentActions,
    has
  };
});
