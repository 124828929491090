import Cookies, { CookieSetOptions } from 'universal-cookie';
import { AuthBrowserStoreKey } from '../types/auth.types';

const AUTH_EXPIRATION_IN_HOURS = Number(
  import.meta.env.VITE_AUTH_EXPIRATION_IN_HOURS || 24
);

const cookies = new Cookies();

const cookiesCommonOptions: CookieSetOptions = {
  path: '/',
  get expires() {
    const dateInstance = new Date();

    dateInstance.setHours(dateInstance.getHours() + AUTH_EXPIRATION_IN_HOURS);

    return dateInstance;
  }
  // TODO: need enable
  // secure: true,
};

export default {
  get accessToken(): string {
    return cookies.get(AuthBrowserStoreKey.accessToken) || '';
  },

  set accessToken(value: string) {
    cookies.set(AuthBrowserStoreKey.accessToken, value, cookiesCommonOptions);
  },

  get refreshToken(): string {
    return cookies.get(AuthBrowserStoreKey.refreshToken) || '';
  },

  set refreshToken(value: string) {
    cookies.set(AuthBrowserStoreKey.refreshToken, value, cookiesCommonOptions);
  }
};
