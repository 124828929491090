import { Component, h, PropType } from 'vue';
import { IconProps } from 'naive-ui';
import BaseIcon from '../components/base-icon/BaseIcon.vue';
import { IconName } from '../types/icon.types';

interface RenderIconProps extends IconProps {
  scale?: string;
}

export default function renderIcon(
  icon: IconName | PropType<Component>,
  props: RenderIconProps = {}
) {
  const isIconString = typeof icon === 'string';

  return () =>
    isIconString
      ? h(BaseIcon, {
        icon,
        ...props
      })
      : h(BaseIcon, props, () => h(icon));
}

export function createIconRenderer(iconWrapperProps: IconProps = {}) {
  return (icon: IconName) => renderIcon(icon, iconWrapperProps);
}
