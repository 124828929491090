import { ACTION_TYPE, ROUTE_KEY_TYPE } from '@/shared/utils/constants/common';

export const currencyRoutes = [
  {
    path: '/currency-conversion',
    name: 'currency-conversion',
    meta: {
      keyName: ROUTE_KEY_TYPE.CONVERSION_REQUESTS,
      keyNameAction: ACTION_TYPE.VIEW
    },
    component: () =>
      import('@/pages/documents/currency-conversion/CurrencyConversionPage.vue')
  },
  {
    path: '/currency-conversion/create',
    name: 'currency-conversion-create',
    meta: {
      keyName: ROUTE_KEY_TYPE.CONVERSION_REQUESTS,
      keyNameAction: ACTION_TYPE.VIEW
    },
    component: () =>
      import(
        '@/pages/documents/currency-conversion/create/CurrencyConversionCreatePage.vue'
      )
  },
  {
    path: '/currency-conversion/:id/details',
    name: 'currency-conversion-details',
    meta: {
      keyName: ROUTE_KEY_TYPE.CONVERSION_REQUESTS,
      keyNameAction: ACTION_TYPE.VIEW
    },
    component: () =>
      import(
        '@/pages/documents/currency-conversion/details/CurrencyConversionDetailsPage.vue'
      )
  }
];
