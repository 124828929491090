export default {
  "ru": {
    "workWithDocumentsTitle": "Работа с документами",
    "tax": "Налог",
    "print": "Распечатать",
    "importDocuments": "Импорт документов",
    "override": "Аннулировать",
    "createDocumentByTemplate": "Создать документ по шаблону",
    "numberId": "Номер ID",
    "documentDate": "Дата документа",
    "templateName": "Наименование шаблона",
    "signDK": "Признак Д/К",
    "createDocumentCopy": "Создать копию документа",
    "debit": "Дебет",
    "credit": "Кредит",
    "other": "Прочее",
    "senderInn": "ИНН отправителя",
    "senderName": "Наименование отправителя",
    "receiverName": "Наименование получателя",
    "unpostedBalance": "Непроведенное сальдо",
    "accountBalance": "Сальдо по счёту",
    "selectCard": "Номер карты",
    "replenishmentTypeCard": "На карту",
    "treasuryAccount": "Счёт казначейства {0}",
    "findTreasuryAccount": "Найти счёт казначейства",
    "budgetReceiverAccount": "Счёт бюджетополучателя {0}",
    "inn": "ИНН",
    "senderInnOrPinfl": "ИНН или ПИНФЛ отправителя",
    "receiverInnOrPinfl": "ИНН или ПИНФЛ получателя",
    "findBudgetReceiverAccount": "Найти счёт бюджетополучателя",
    "sum": "Сумма",
    "paymentDestinationCode": "Код назначения платежа",
    "sumInWords": "Сумма прописью",
    "printDocument": "Распечатать документ №{n}",
    "copiesNumber": "Количество экземпляров:",
    "printCopies": "нет копий | {n} копия | {n} копии | {n} копий",
    "budgetReceiversSearch": "Поиск бюджетополучателей",
    "enterAccountOrInnOrName": "Введите счёт, ИНН или наименование бюджетополучателя",
    "treasuryReceiversSearch": "Поиск счётов казначейства",
    "treasurySearchLabel": "Введите счёт, маску, уникальный номер или наименование казначейства",
    "documentNumberHint": "Номер документа сгенерирован автоматически. Вы можете изменить его.",
    "login": "Логин",
    "password": "Пароль",
    "forgot-password": "Забыли пароль",
    "sign-in": "Войти в систему",
    "feedback-form": "Форма обратной связи",
    "main-page": "Главная страница",
    "work-with-documents-online-24": "Работа с документами онлайн 24/7",
    "documents": "Документы",
    "additionally": "Дополнительно",
    "reports": "Отчеты",
    "letters": "Письма",
    "settings": "Настройки",
    "logout": "Выход",
    "fromDocumentDate": "С даты документа",
    "documentType": "Тип документа",
    "documentNumber": "Номер документа",
    "byDocumentDate": "По дату документа",
    "destinationCode": "Код назначения",
    "filterTemplates": "Фильтр Шаблоны",
    "success": "Успешно!",
    "search": "Поиск",
    "clear": "Очистить",
    "cancel": "Отменить",
    "find": "Найти",
    "delete": "Удалить",
    "save": "Сохранить",
    "saveAndCreateAnother": "Сохранить и создать новый",
    "update": "Обновить",
    "areYouSure": "Вы уверены?",
    "validation": {
      "required": "Поле не может быть пустым",
      "min": "Минимальное количество символов: {length}!",
      "max": "В поле должно быть не более {length} символов!",
      "inputFieldRowsCountMax": "В поле должно быть не более {length} строк",
      "inputFieldRowLengthMax": "В строке {row} должно быть не более {length} символов",
      "receiverAccountEqualToSenderAccount": "Счёт получателя должен отличаться от счёта отправителя.",
      "receiverAccountNumberInvalid": "Номер счёта введён неверно. Попробуйте снова.",
      "receiverAccountNumberInvalidLength": "Счёт должен содержать 20 цифр.",
      "budgetAccountNumberInvalid": "Номер счёта должен содержать 27 цифр",
      "treasuryAccountNumberInvalid": "Номер счёта должен содержать 25 цифр",
      "innInvalid": "Некорректный ИНН",
      "swiftInnInvalid": "ИНН может содержать 10 или 12 символов",
      "pinflInvalid": "Некорректный ПИНФЛ",
      "cardNumberLength": "Номер карты должен содержать 16 цифр",
      "cardNumberInvalid": "Номер карты введен неверно",
      "enterLogin": "Введите логин",
      "enterPassword": "Введите пароль",
      "invalidLogin": "Некорректный логин",
      "invalidPassword": "Некорректный пароль",
      "enterOldPassword": "Введите старый пароль",
      "enterNewPassword": "Введите новый пароль",
      "repeatEnterNewPassword": "Введите новый пароль повторно",
      "confirmPassword": "Пароли не совпадают",
      "invalidOtpCode": "Некорректный СМС код",
      "firstName": "Введите имя",
      "phone": "Введите номер телефона",
      "email": "Введите email",
      "invalidEmail": "Некорректный Email",
      "idnInvalid": "ИДН должен содержать 27 цифр",
      "inputFieldInvalid": "Поле содержит недопустимые символы",
      "amountZero": "Сумма не может быть 0",
      "maximum": "Превышен максимальный лимит символов",
      "invalidFormat": "Неверный формат",
      "numbersOnly": "В поле должны быть только цифры",
      "innOrPinflInvalid": "ИНН содержит 9 цифр, а ПИНФЛ — 14.",
      "minLength": "Минимум @:symbols-count",
      "receiverAccountMainInvalid": "Поле должно содержать основной счёт компании",
      "paymentPurpose": "Назначение платежа должно включать от 3 до 450 символов"
    },
    "branch": "Филиал",
    "account": "Счёт",
    "accounts": "Счета",
    "name": "Наименование",
    "dayStartBalance": "Остаток на начало дня",
    "dayEndBalance": "Остаток на конец дня",
    "volumeDebit": "Оборот дебет",
    "volumeCredit": "Оборот кредит",
    "state": "Состояние",
    "statementPrevious": "Выписка за предыдущий день",
    "statementCurrent": "Выписка за текущий день",
    "statementPeriod": "Выписка за период",
    "lastOperationDate": "Дата последней операции",
    "openingDate": "Дата открытия",
    "closingDate": "Дата закрытия",
    "filter": "Фильтр",
    "accountOrName": "Счёт или наименование",
    "accountInformation": "Информация по счёту",
    "payrolls": "Ведомости по зарплате",
    "payroll": "Ведомость по зарплате",
    "details": "Детали",
    "sendToBank": "Отправить в банк",
    "employeesList": "Список сотрудников",
    "orderNumber": "Порядковый номер",
    "orderCardNumber": "Порядковый номер карты",
    "paymentPurpose": "Назначение платежа",
    "year": "Год",
    "month": "Месяц",
    "loadDate": "Дата загрузки",
    "file": "Файл",
    "fileType": "Тип файла",
    "payrollsFilter": "Фильтр ведомостей по зарплате",
    "for": "За",
    "description": "Описание",
    "mainInformation": "Основная информация",
    "unloadTemplateFile": "Выгрузить файл шаблон",
    "uploadFile": "Загрузить файл",
    "unloadFileForPrint": "Выгрузить ведомость для печати",
    "statement": "Ведомость",
    "encoding": "Кодировка",
    "user": "Пользователь",
    "totalSumPayroll": "Общая сумма ведомости",
    "employeeAccount": "Счёт сотрудника",
    "employeeName": "ФИО сотрудника",
    "embossedName": "Эмбоссированное имя",
    "swiftAbbr": "SWIFT",
    "resetFilter": "Сбросить фильтр",
    "add": "Добавить",
    "attachFiles": "Прикрепить файлы",
    "saveAsTemplate": "Сохранить как шаблон",
    "paymentDocument": "Платежный документ",
    "messageType": "Вид сообщения (MT)",
    "senderBank": "Банк отправителя",
    "swift": {
      "senderBicCode": "БИК код отправителя&nbsp;(52A)",
      "senderBank": "Банк отправителя&nbsp;(52A)",
      "receiverBank57A": "Банк получателя&nbsp;(57A)",
      "intermediaryBank56A": "Банк посредника&nbsp;(56A)",
      "intermediaryBankCountry": "Страна банка-посредника&nbsp;({field})",
      "receiverBankCountry": "Страна банка-получателя&nbsp;({field})",
      "valueDate": "Дата валютирования&nbsp;(32A)",
      "amount": "Сумма перевода&nbsp;(32A)",
      "currency": "Валюта перевода&nbsp;(32A)",
      "sender50A": "Наименование и адрес отправителя&nbsp;(50A)",
      "senderAccount": "Счёт отправителя&nbsp;(50A)",
      "intermediaryBank": "Банк посредника&nbsp;(56)",
      "receiverBank": "Банк получателя&nbsp;(57)",
      "intermediaryBankName": "Наименование банка посредника&nbsp;(56A)",
      "receiverAccount": "Счёт получателя&nbsp;(59K)",
      "receiver59A": "Наименование и адрес получателя&nbsp;(59A)",
      "remittanceInformation": "Детали платежа&nbsp;(70)",
      "chargePayer": "Детали расходов&nbsp;(71A)",
      "beneficiaryCorrespondentBank": "Банк корреспондент банка получателя &nbsp;(56A)",
      "bicCodeField": "БИК код&nbsp;({field})",
      "receiverBankName": "Наименование банка получателя&nbsp;(57A)",
      "bankDataField": "{field} – Данные банка",
      "bankDataNameAndAddressField": "{field} – Данные банка - Наименование и адрес",
      "nameAndAddressField": "Наименование и адрес банка&nbsp;({field})",
      "BEN": "BEN Получатель",
      "OUR": "OUR Отправитель",
      "SHA": "SHA Оба",
      "operation": {
        "type1": "1 - Импорт",
        "type2": "2 - Займ гарантированный",
        "type3": "3 - Займ негарантированный",
        "type4": "4 - Репатриация",
        "type5": "5 - Прочие",
        "type6": "6 - Экспорт",
        "type7": "7 - На счет до выяснения"
      },
      "prepaymentForGoods": "10100 - Предоплата за товар",
      "paymentForGoods": "10200 - Оплата за товар",
      "prepaymentForServices": "20100 - Предоплата за услуги",
      "paymentForServices": "20200 - Оплата за услуги",
      "other": "99090 - Прочее",
      "currencyOperationCode": "Код валютной операции&nbsp;(70)",
      "bicNumber": "Номер (БИК)",
      "correspondentAccountNumber": "Корреспондентский счёт&nbsp;(57D)",
      "receiverKpp": "КПП получателя"
    },
    "receiverBank": "Банк получателя",
    "senderCountry": "Страна отправитель",
    "receiverCountry": "Страна получатель",
    "comment": "Комментарий",
    "swiftFilter": "Фильтр SWIFT",
    "newSwiftTransfer": "Новый SWIFT перевод",
    "operationType": "Тип операции",
    "senderAccount": "Счёт отправителя",
    "receiverAccount": "Счёт получателя",
    "receiver": "Получатель",
    "senderToReceiverInformation": "Дополнительные детали платежа&nbsp;(72B)",
    "idn": "ИДН {n}",
    "paymentTerms": "Условия оплаты",
    "transactionData": "Данные об операции",
    "template": "Шаблон",
    "templates": "Шаблоны",
    "templateComment": "Комментарий к шаблону",
    "sender": "Отправитель",
    "bankCorrespondent": "Банк - корреспондент",
    "correspondentBank": "Банк корреспондента (Поле:&nbsp;56D)",
    "months": {
      "january": "Январь",
      "february": "Февраль",
      "march": "Март",
      "april": "Апрель",
      "may": "Май",
      "june": "Июнь",
      "july": "Июль",
      "august": "Август",
      "september": "Сентябрь",
      "october": "Октябрь",
      "november": "Ноябрь",
      "december": "Декабрь"
    },
    "detailedInformation": "Подробная информация",
    "parameters": "Параметры",
    "extractOnTheDate": "Выписка на дату",
    "accountMask": "Маска счёта",
    "sorting": "Сортировка",
    "MFO": "МФО",
    "startingDate": "Начальная дата",
    "endDate": "Конечная дата",
    "turnoverDebit": "Обороты дебет",
    "turnoverCredit": "Обороты кредит",
    "edit": "Редактировать",
    "Upload-the-report-to-t": "Upload-the-report-to-t",
    "Print-the-report": "Распечатать отчет",
    "Employee-filter": "Фильтр сотрудников",
    "documentsInBank": "Документы в банке",
    "Document_templates": "Шаблоны документов",
    "Do-you-want-to-leave": "Вы действительно хотите уйти? У вас есть несохраненные изменения!",
    "document": "Документ №{0}",
    "documentHistory": "История действий по документу",
    "Modal-to-protect-the-entered-data": "Порядковый номер карты сотрудника",
    "income": "Приход",
    "soato": "СОАТО",
    "uniqueAccountNumber": "Уникальный номер счета",
    "treasuryName": "Наименование казначейства",
    "Search-for-templates": "Поиск шаблонов",
    "templateDetails": "Детали шаблона",
    "newTemplate": "Новый шаблон",
    "numberAndContractDate": "Номер и Дата контракта",
    "idnContract": "ИДН контракта",
    "number": "Номер",
    "date": "Дата",
    "failedToSign": "Не удалось подписать",
    "failedToDelete": "Не удалось удалить",
    "selectAccountToCreatePaymentOrder": "Выберите счет для создания платежного поручения",
    "salaryPayrollSentFromAnotherBank": "Пл.поручение на зп отправлено из другого банка",
    "transitAccountForSalary": "Транзитный счет для зарплаты",
    "clientAccount": "Счет клиента",
    "paymentOrderNumber": "Номер пл.поручения",
    "transitAccountBalance": "Остаток транзитного счета",
    "payrollAmount": "Сумма ведомости",
    "paymentPurposeNote": "*Примечание: в назначении платежа необходимо указать номер договора, период з/п, наименование платежа, номер ведомости. В противном случае зачисление на ПК не будет произведено.",
    "sendPayroll": "Отправить ведомость",
    "sendPayrollToBank": "Отправить ведомость в банк",
    "Support-service-24-7": "Cлужба поддержки 24/7",
    "Login": {
      "title": "Войдите в аккаунт",
      "desc": "Пожалуйста, введите логин и пароль, выданные в отделении банка."
    },
    "All-documents": "Все документы",
    "All-accounts": "Все счета",
    "auth-form": {
      "title": "Вход в систему"
    },
    "Amount": "Сумма",
    "Show-all": "Показать все",
    "newDocument": "Новый документ",
    "General-information": "Общая информация",
    "List-of-banks": "Список банков",
    "Payment-destination-codes": "Коды назначения платежей",
    "References": "Справочники",
    "Code": "Код",
    "Code-or-name": "Код или наименование",
    "Sending-a-payment-via-the-Online-24-7-service": "Отправка платежа через сервис Онлайн 24/7",
    "The-commission-for-online-payment-24-7-may-vary": "Комиссия за платёж Онлайн 24/7 может отличаться.",
    "exchangeRates": "Курсы валют",
    "currentDate": "Текущая дата",
    "businessDay": "Операционный день",
    "businessDayState": "Состояние опер. дня",
    "contacts": "Контакты",
    "Password-change": "Смена пароля",
    "Change-password": "Изменить пароль",
    "Old-password": "Старый пароль",
    "New-password": "Новый пароль",
    "Repeat-password": "Повторить пароль",
    "The-password-has-been-changed": "Пароль был изменен",
    "Enter-the-code-from-the-SMS": "Введите код из СМС",
    "We-sent-an-SMS-with-a-code-to-the-number": "Мы отправили СМС с кодом на номер",
    "The-code-from-the-SMS": "Код из СМС",
    "You-can-repeat-the-sending-via": "Повторить отправку можно через",
    "Plural-after-second": "{n} секунд | {n} секунду | {n} секунды | {n} секунд",
    "Repeat": "Повторить",
    "accountStatement": "Выписка по счету",
    "byDocumentNumber": "По номеру документа",
    "byAccounts": "По счетам",
    "byDebit": "По дебетовым оборотам",
    "byCredit": "По кредитовым оборотам",
    "htmlReport": "Скачать в HTML",
    "rtfReport": "Скачать в RTF",
    "pdfReport": "Скачать в PDF",
    "wordReport": "Скачать в Word",
    "excelReport": "Скачать в Excel",
    "accountStatementForPeriod": "Выписка по счету за период",
    "all": "Все",
    "debitCreditTurnover": "Обороты дебет/Обороты кредит",
    "partiallySigned": "Подписано частично",
    "helpModal": {
      "title": "Если что-то не работает или непонятно как работает, то обращайтесь к нам:",
      "recomendedBrowsersText": "Для работы в личном кабинете рекомендуем использовать один из следующих браузеров:"
    },
    "Employees-card-number": "Номер карты сотрудника",
    "onboardingWelcomeModal": {
      "title": "Благодарим за доверие и вход в новый личный кабинет для юридических лиц от Kapitalbank и Uzum Business.",
      "recomendedBrowsersText": "Новым ЛК можно пользоваться в любом из современных браузеров:",
      "ieSupport": "Internet Explorer более не поддерживается.",
      "advantages": "Новый ЛК будет становиться более функциональным и удобным для использования. Все функции остались на прежних местах.",
      "changes": "Изменения коснулись раздела “Работа с документами 24/7”. Теперь, чтобы отправить перевод в любое время суток, достаточно создать обычный платёжный документ и при создании указать использование сервиса отправки платежей 24/7."
    },
    "contactTechnicalSupport": "В случае вопросов можно обратиться в техническую поддержку:",
    "onboardingHelpButtonText": "Нужна помощь!",
    "To-the-account": "На счет",
    "approve": "Утвердить",
    "check": "Проверить",
    "mfo": "МФО",
    "currencySum": "Сумма в валюте",
    "totalSum": "Общая сумма",
    "currency": "Валюта",
    "contractNumber": "Номер контракта",
    "operDate": "Опер дата",
    "byPhone": "По телефону",
    "byTelegram": "В Telegram",
    "itIsClear": "Понятно",
    "chrome": "Chrome",
    "opera": "Opera",
    "firefox": "Mozilla Firefox",
    "yandexBrowser": "Яндекс Браузер",
    "edge": "Edge",
    "singleContactCenterNumber": "Единый номер контакт-центра:",
    "technicalSupportAndAdditionalInformation": "Техническая поддержка и дополнительная информация:",
    "userSupportDepartment": "Отдел поддержки пользователей",
    "workingHours": "Время работы:",
    "resendCode": "Отправить код ещё раз",
    "confirmLogin": "Подтвердите вход",
    "confirmWithSmsCode": "СМС кодом",
    "confirmWithDigitalSignature": "Ключом ЭЦП",
    "confirmSecurityNote": "В целях безопасности, Вам необходимо подтвердить свою учетную запись. Код подтверждения будет отправлен по СМС на номер {n}",
    "confirmWithDigitalSignatureNote": "Если у Вас нет доступа к данному номеру Вы можете выбрать альтернативное подтверждение с помощью ключа ЭЦП.",
    "confirmLoginByDigitalSignature": "Подтвердите вход ключом ЭЦП",
    "insertDigitalSignatureToken": "Вставьте ЭЦП ключ и нажмите на кнопку \"Готово\"",
    "done": "Готово",
    "createDocumentFromTemplate": "Создать документ по шаблону",
    "send": "Отправить",
    "Contracts": "Контракты",
    "Name-of-the-contract-holder": "Наименование контрактодержателя",
    "IDN": "ИДН",
    "Name-of-the-foreign-partner": "Наименование иностранного партнера",
    "Country-of-the-foreign-partner": "Страна иностранного партнера",
    "Contract-number": "Номер контракта",
    "Date-of-conclusion-of-the-contract": "Дата заключения контракта",
    "Sign-of-contract-activity": "Признак активности контракта",
    "MFI-code-of-the-authorized-bank/number-of-the-service-center": "Код МФО уполномоченного банка/номер центра оказания услуг",
    "accountNumber": "Номер счета",
    "accountStatementExtended": "Выписка лицевых счетов расширенная",
    "txtReport": "Скачать в TXT",
    "reportFailed": "Не удалось получить отчет",
    "Temporary-blocked-modal": {
      "title": "Мы временно заблокировали аккаунт",
      "subtitle": "Для разблокировки аккаунта обратитесь в службу поддержки банка.",
      "call-to": "Звоните",
      "call-to-text": "Позвоните и скажите оператору кодовое слово. Сотрудник банка восстановит доступ удаленно.",
      "write-to": "Пишите",
      "write-to-text": "Напишите в банк и сообщите службе поддержки кодовое слово. Сотрудник банка восстановит доступ удаленно.",
      "write-to-telegram": "Написать в Telegram"
    },
    "Contract-details": "Детали контракта",
    "Additional-agreements": "Дополнительные соглашения",
    "Declarations": "Декларации",
    "Payment": "Оплата",
    "Specifications": "Спецификации",
    "Close": "Закрыть",
    "Signature-token-not-found": "Ключ ЭЦП не обнаружен",
    "No-signature-token-message": "Не удалось обнаружить в компьютере ключ ЭЦП.\r\nВставьте ключ ЭЦП в компьютер и повторите попытку.",
    "Contact-support-service": "По возникшим вопросам можете обратиться в службу поддержки:",
    "Contract-date": "Дата контракта",
    "Subject-of-the-contract": "Предмет контракта",
    "Contract-currency": "Валюта контракта {n}",
    "Contract-amount-in-contract-currency": "Сумма контракта в валюте контракта {n}",
    "Serial-number-of-the-addendum": "Порядковый номер доп. соглашения",
    "Document-status": "Статус документа",
    "Contract-currency-rate": "Курс валюты контракта",
    "Name-of-the-importer-recipient": "Наименование импортёра (получателя)",
    "Customs-regime": "Таможенный режим",
    "Contract-currency-code": "Код валюты контракта",
    "Total-invoice-value": "Общая фактурная стоимость",
    "Code-of-the-customs-post": "Код таможенного поста",
    "Registration-number-of-the-declaration": "Регистрационный номер декларации",
    "Date-of-registration-of-the-declaration": "Дата регистрации декларации",
    "Country-code-of-departure": "Код страны отправления",
    "Code-of-the-nature-of-the-transaction": "Код характера сделки",
    "Date-of-shipment-receipt-of-the-goods": "Дата отгрузки (поступления) товара",
    "Name-of-the-exporter-sender": "Наименование экспортёра (отправителя)",
    "Contract-Specification-number": "Номер спецификации по контракту",
    "Attribute-import-export": "Признак {attribute}",
    "Serial-number-of-the-specification-in-the-EEISVO": "Порядковый номер спецификации в ЕЭИСВО",
    "Date-and-time-of-data-entry-into-the-EEISVO": "Дата и время ввода данных в ЕЭИСВО",
    "Specification-date": "Дата спецификации",
    "Specification-amount": "Сумма спецификации",
    "Specification-currency": "Код валюты спецификации",
    "Attribute-goods-services": "Признак {attribute}",
    "Payment-date": "Дата оплаты",
    "Payment-currency": "Валюта оплаты",
    "Source-of-currency-funds": "Источник валютных средств",
    "Receipt-amount": "Сумма оплаты",
    "The-amount-of-receipt-for-the-goods-in-the-currency-of-the-contract": "Сумма оплаты за товар в валюте контракта {n}",
    "The-amount-of-receipt-for-services-works-in-the-currency-of-the-contract": "Сумма оплаты за услуги (работы) в валюте контракта {n}",
    "Full-name-of-the-bank-user-who-entered-edited-the-document": "ФИО пользователя банка который ввел (редактировал) документ",
    "Payment-number-in-ABS": "Номер платежа в АБС",
    "The-serial-number-of-the-document-in-the-EEISVO": "Порядковый номер документа в ЕЭИСВО",
    "Setting-notifications-for-Telegram": "Настройка уведомлений для Telegram",
    "Additional-Information": {
      "title": "Дополнительна информация",
      "content": "Данная информация может быть использована для восстановления пароля, email и sms рассылок."
    },
    "First-name": "Имя",
    "Phone": "Телефон",
    "Email": "Электронная почта",
    "File-name": "Наименование файла",
    "Imported-documents": "Импортируемые документы",
    "No-phone-message": "Номер телефона для Вашей учетной записи не был указан при регистрации или указан неверно. Вы можете подтвердить авторизацию ключом ЭЦП или обратиться в техническую поддержку:",
    "Support-service-contacts": "По телефону - {phone}\r\nВ Telegram - {telegram}",
    "Uploaded-files": "Загруженные файлы",
    "Generated-documents-according-to-the-file": "Порожденные документы согласно файлу",
    "Payment-documents": "Платёжные документы",
    "System": "Система",
    "User-login": "Логин пользователя",
    "User-name": "Имя пользователя",
    "IP-address": "IP адрес",
    "Action": "Действие",
    "Payment-history-modal": {
      "title": "История действий по документу №",
      "unloading-date": "Дата выгрузки:",
      "unloading-author": "Выгрузил:"
    },
    "Certificate-not-actual-message": "Ваш ключ для ЭЦП неактивен.\r\nДля выпуска нового ключа ЭЦП необходимо обратиться в филиал банка.",
    "Certificate-not-belong-to-user-message": "Ключ ЭЦП, вставленный в компьютер, не соответствует вашей учётной записи.\r\nВставьте верный ключ ЭЦП в компьютер и повторите попытку.",
    "filters": "Фильтры",
    "Enter-the-name-or-code-of-the-payment-destination": "Введите название или код назначения платежа",
    "Back": "Назад",
    "Dear-user": "Уважаемый пользователь!",
    "No-confirm-types-message": "Для успешной авторизации необходимо подтвердить вход по СМС или по ЭЦП. Так как к Вашей учетной записи не привязан номер телефона или сертификат ЭЦП необходимо обратиться в банк.",
    "Bank-news": "Новости банка",
    "User-manual": {
      "title": "Настройка компьютера для работы c программой интернет банк с ЭЦП ключом",
      "tab-new-users": "Для новых пользователей",
      "tab-old-users": "Для пользователей pc.bank24.uz"
    },
    "FAQ": {
      "title": "Часто задаваемые вопросы",
      "login": "По вопросам пароля и логина",
      "deleted": "Если платежное поручение не принято к исполнению «Удален»",
      "rejected": "Если платежное поручение не принято к исполнению «Забраковано»"
    },
    "Contacts-tab": {
      "title": "Контакты",
      "tech-support-phone": "Телефон службы поддержки 24/7",
      "tech-support-telegram": "Телеграм службы поддержки 24/7"
    },
    "Swift-details": "Детали SWIFT перевода",
    "Error": "Ошибка",
    "message type": "Вид сообщения (МТ)",
    "Save-changes": "Сохранить изменения",
    "cntrTtype": "Тип контракта",
    "bankId": "Код МФО уполномоченного банка",
    "okpo": "Код ОКПО (контрактора)",
    "okonx": "Код ОКОНХ (контрактора)",
    "contractorUzParentCompany": "Наименование вышестоящей организации контрактодержателя",
    "contractorUzName": "Наименование контрактодержателя",
    "rgnCode": "Область контрактодержателя",
    "contractorUzAddress": "Адрес контрактодержателя",
    "cntrSubject": "Предмет контракта",
    "currCode1": "Валюта контракта 1",
    "currCode2": "Валюта контракта 2",
    "amount1": "Сумма контракта в валюте 1",
    "amount2": "Сумма контракта в валюте 2",
    "accCurrCode1": "Валюта расчёта 1",
    "accCurrCode2": "Валюта расчёта 2",
    "contractorForName": "Наименование иностранного партнёра",
    "contractorForCountryCode": "Страна иностранного партнёра",
    "contractorForAddress": "Юридический адрес иностранного партнёра",
    "bankForAttributes": "Банковские реквизиты иностранного партнёра",
    "productsName": "Общее наименование товаров",
    "docNo": "Номер контракта",
    "docDate": "Дата заключения контракта",
    "execType": "Срок исполнения контракта",
    "execDate": "Дата исполнения контракта",
    "cntrStatus": "Признак активности контракта",
    "lockDate": "Дата аннулирования (удаления, блокировки, исполнения) контракта",
    "cstDate": "Дата постановки на учёт контракта в таможенных органах",
    "contractorUzInn": "ИНН контрактодержателя",
    "receivableTotal1": "Сумма дебиторской задолженности в валюте контракта 1",
    "receivableOverdue1": "Сумма просроченной дебиторской задолженности в валюте контракта 1",
    "receivableTotal2": "Сумма дебиторской задолженности в валюте контракта 2",
    "receivableOverdue2": "Сумма просроченной дебиторской задолженности в валюте контракта 2",
    "payableTotal1": "Сумма кредиторской задолженности в валюте контракта 1",
    "payableOverdue1": "Сумма просроченной кредиторской задолженности в валюте контракта 1",
    "payableTotal2": "Сумма кредиторской задолженности в валюте контракта 2",
    "payableOverdue2": "Сумма просроченной кредиторской задолженности в валюте контракта 2",
    "insTime": "Дата и время ввода данных в ЕЭИСВО",
    "noContractData": "По данному контракту данных не было найдено",
    "status": "Статус",
    "Failed-to-check": "Не удалось проверить",
    "Failed-to-approve": "Не удалось утвердить",
    "Failed-to-override": "Не удалось переопределить",
    "Download-file": "Выгрузить файл",
    "Download-failed": "Не удалось получить файл",
    "Contract-number-additional": "Номер дополнительного соглашения",
    "Contract-date-additional": "Дата дополнительного соглашения",
    "Subject-of-the-contract-additional": "Предмет дополнительного соглашения",
    "contractorUzPhone": "Телефон контрактодержателя",
    "bankForName": "Наименование банка иностранного партнёра",
    "bankForCountryCode": "Страна Банка Иностранного партнёра ",
    "bankForAddress": "Юридический адрес банка иностранного партнёра",
    "Instruction": "Инструкция",
    "Swift-user-instruction": "Пользовательская инструкция создания SWIFT перевода",
    "Currency-exchange": {
      "confirm-modal": {
        "content-text": "Вы действительно хотите удалить заявку?"
      }
    },
    "Currency-conversion": {
      "page-title": "Новая заявка",
      "details-page-title": "Детали завки на конверсию",
      "form": {
        "currency-from": "Валюта к списанию",
        "currency-to": "Валюта к пополнению",
        "account-from": "Счет списания",
        "account-to": "Счет пополнения",
        "account-from-balance": "Остаток на счете списания",
        "account-to-balance": "Остаток на счете пополнения",
        "required-amount-currency": "Необходимая сумма в валюте к пополнению",
        "purpose": "Назначение",
        "approved-amount": "Одобренная сумма"
      }
    },
    "UZS amount": "Сумма в UZS",
    "Back-to-list": "Назад к списку",
    "Sign-and-send": "Подписать и отправить",
    "Change": "Изменить",
    "Code-for-support": "Код для поддержки",
    "Creation-date": "Дата создания",
    "Payment-details": "Детали платежа",
    "Organization-name": "Название организации",
    "Bank-branch": "МФО Банка",
    "Requirement-details": "Детали требования",
    "Payment-type": "Вид платежа",
    "Nonpayment-code": "Шифр неплатежа",
    "Destination-code": "Код назначения",
    "removed payment requirement records": "Платежное требование успешно удалено",
    "did not remove payment requirement records": "Не удалось удалить платежное требование",
    "Exchange-rate": "Курс обмена валют",
    "Rejection-reason": "Причина отклонения",
    "Reject-reason": "Причина отказа",
    "Extend-reason": "Причина продления",
    "Partial-payment-reason": "Причина частичной оплаты",
    "Payment-request": "Платёжное требование",
    "Payment-requests": "Платёжные требования",
    "Payment-request-number": "Платёжное требование №{n}",
    "Payment-destination-code-for-unpaid-documents": "Код назначения по неоплаченным документам",
    "Failed-to-remove": "Не удалось удалить",
    "Are-you-sure-to-remove-payment-requirement": "Вы уверены, что хотите удалить платёжное требование?",
    "Are-you-sure-to-remove-payment-requirements": "Вы уверены, что хотите удалить платёжные требования?",
    "Payment-requirement-removing-warning": "После удаления платёжного требования вы не сможете его восстановить.",
    "Payment-requirements-removing-warning": "После удаления платёжных требований вы не сможете их восстановить.",
    "no payment reqs for sign": "Нет платежных требований на подписании",
    "payment reqs for sign will appear here": "Платежные требования на подписании появятся на этой странице.",
    "create payment req": "Создать платёжное требование",
    "Create": "Создать",
    "InnOrPinfl": "ИНН или ПИНФЛ",
    "PaymentCodeForUnpaidDoc": "Назначение платежа по неоплаченным документам",
    "Amount-can-not-be-equal": "Сумма не может быть равна {amount}",
    "Successfully-checked-number": "Успешно проверено: {n}",
    "Failed-to-check-number": "Не удалось проверить: {n}",
    "Successfully-approved-number": "Успешно утверждено: {n}",
    "Failed-to-approve-number": "Не удалось утвердить: {n}",
    "Only allowed chars": "Поле должно содержать {n} символов",
    "Failed-to-sign-number": "Не удалось подписать: {n}",
    "Successfully-signed-number": "Успешно подписано: {n}",
    "Document-sending-date": "Дата отправки документа",
    "No-payment-reqs-in-card-index-second": "Нет платёжных требований в Картотеке 2",
    "No-payment-reqs-in-card-index-second-will-appear": "Платёжные требования из Картотеки 2 появятся на этой странице.",
    "Test-text": "ТЕСТ ТЕКСТ",
    "No-payment-reqs-in-card-index-first": "Нет платёжных требований в Картотеке 1",
    "No-payment-reqs-in-card-index-first-will-appear": "Платёжные требования из Картотеки 1 появятся на этой странице.",
    "Please-confirm": "Прошу подтвердить",
    "card-index-first": "Картотека 1",
    "card-index-second": "Картотека 2",
    "waiting-for-approval": "Ожидают действия",
    "Pay": "Оплатить",
    "Partial-pay": "Оплатить частично",
    "Reject": "Отклонить ",
    "Reason": "Причина",
    "Requirement-amount-value": "Сумма требования: {amount} {currency}",
    "Extend": "Продлить",
    "Partial-pay-success": "Частичная оплата прошла успешно.",
    "download-template": "Скачайте шаблон",
    "check-template": "Проверьте данные",
    "upload-template": "Загрузите заполненный шаблон",
    "documents-import": "Импорт документов",
    "Insert-token-to-do-this-action": "Вставьте ключ ЭЦП и обновите страницу, чтобы совершить данное действие с документом",
    "choose-file-or-drag": "Выберите файл или перетащите сюда",
    "xlxs-no-more-20mb": "XLSX не более 20 MB",
    "file size should be less than 20mb": "Размер файла не должен превышать 20 MB.",
    "choose file to proceed": "Выберите файл",
    "Specify-payment-requirement-reject-reason": "Укажите причину отклонения от оплаты платёжного требования.",
    "Requirement-reject-success": "Требование успешно отклонено.",
    "Pay-success": "Оплата прошла успешно.",
    "Pay-failed": "Не удалось оплатить.",
    "create-payment-claim": "Создать платёжное требование",
    "closed-payment-requirements": "Закрытые",
    "Document-create-date": "Дата создания документа",
    "currency-conversion-title": "Заявка на покупку/продажу ин. валюты",
    "try-again-later": "Ошибка! Попробуйте позже",
    "get-back-to-import": "Вернуться к импорту",
    "remove-error-and-go": "Удалить незагруженные и продолжить",
    "cancel-import": "Отменить загрузку",
    "download-template-for-upload": "Скачайте шаблон для загрузки",
    "template-will-be-automatically-downloaded": "Шаблон документа будет автоматически скачен на ваш компьютер. Это абсолютно безопасно. Вы сможете открыть шаблон в программе Excel или другом табличном редакторе.",
    "continue": "Продолжить",
    "upload-ready-template": "Загрузите заполненный шаблон",
    "upload-template-we-will-create-docs": "После заполнения шаблона на компьютере перетащите файл в окно загрузки.",
    "unfortunately-we-couldnt-create-from-your-file": "К сожалению, мы не смогли загрузить платежи из вашего файла",
    "probably-you-sent-incorrect-file": "Возможно, вы отправили незаполненный файл. Пожалуйста, попробуйте загрузить другой файл.",
    "check-files": "Проверьте корректность загруженных платежей",
    "if-you-found-mistakes": "Если вы нашли ошибку, вы можете отредактировать платёж в разделе работа с документами после создания.",
    "documents-uploaded": "Загружено документов",
    "payment-requirements": "Платежей",
    "payment-req": "Платёж",
    "are-you-sure-you-want-to-cancel-upload": "Вы уверены, что хотите отменить загрузку документа?",
    "not-able-to-restore": "Восстановить данные будет невозможно. В следующий раз документ придётся импортировать заново.",
    "couldnot-upload-properly": "К сожалению, часть документов не удалось загрузить корректно",
    "partly-incorrect": "Часть полей отсутствует или введены некорректные данные. Вы можете продолжить создание успешно загруженных платежей или вернуться к импорту.",
    "couldnot-store": "Не удалось загрузить",
    "show-mistakes": "Показать ошибки",
    "paymentNumber": "Номер документа",
    "senderAccountNumber": "Счёт плательщика",
    "receiverBranch": "МФО Банка",
    "receiverAccountNumber": "Счёт получателя",
    "receiverInn": "ИНН получателя",
    "amount": "Сумма",
    "paymentPurposeCode": "Код назначения платежа",
    "No-closed-payment-reqs-for-sign": "Нет закрытых платежных требований на подписании",
    "No-closed-payment-reqs-in-card-index-first": "Нет закрытых платёжных требований в Картотеке 1",
    "No-closed-payment-reqs-in-card-index-second": "Нет закрытых платёжных требований в Картотеке 2",
    "No-closed-payment-reqs": "Нет закрытых платёжных требований",
    "Payment-reqs-will-appear-here": "Закрытые платежные требования появятся на этой странице",
    "Extend-payment-date": "Продлить дату оплаты",
    "Extend-payment-date-description": "Дата оплаты будет продлена.",
    "amount_left": "Осталось оплатить   ",
    "Chars max length": "Максимальное количество символов не может превышать {n} символов",
    "Step-number": "Шаг {current} из {total}",
    "Confirm-number": "Подтвердить номер",
    "Confirm-number-change-with-token-key-text": "Подтвердите смену номера ключом ЭЦП.\nВставьте флешку с ключом в компьютер и&nbsp;нажмите кнопку \"Подтвердить номер\".",
    "card-index-first-details-title": "Картотека 1 — №{ n }",
    "card-index-second-details-title": "Картотека 2 — №{ n }",
    "Date-of-review": "Дата ознакомления",
    "Date-of-expected-payment": "Дата предполагаемой оплаты",
    "Token-key-not-found": "Мы не смогли обнаружить ключ ЭЦП в компьютере",
    "Insert-token-key-and-try-again": "Вставьте ключ в компьютер и попробуйте снова.",
    "Insert-correct-token-key-and-try-again": "Вставьте верный ключ ЭЦП в компьютер и&nbsp;попробуйте снова.",
    "Token-key-not-match": "Ключ ЭЦП не соответствует учётной записи",
    "Enter-new-phone-number": "Введите новый номер. Мы отправим СМС кодом подтверждения.",
    "Send-code": "Отправить код",
    "Phone-number": "Номер телефона",
    "Find-department-address": "Найти адрес отделения",
    "Actualize-phone-number": "Изменение номера телефона",
    "Contact-bank-to-change-phone-number": "Для продолжения работы обратитесь в банк. Сотрудник банка поможет изменить номер телефона для входа в интернет-банк.",
    "Operating-day-is-closed-for-actions-with-payment": "Операционный день банка закрыт для действий с платёжными требованиями в Картотеке 1",
    "Available-for-actions-with": "Доступно для действий с { time }",
    "Network-error": "Ошибка сети",
    "back-to-work-with-documents": "К работе с документами",
    "No-results-found-for-your-search": "По запросу ничего не найдено",
    "Try-searching-using-a-different-parameter": "Попробуйте выполнить поиск по другому параметру",
    "There-are-no-closed-payment-requests-in-card-index-1": "Нет закрытых платёжных требований в Картотеке 1",
    "There-are-no-closed-payment-requests-in-card-index-2": "Нет закрытых платёжных требований в Картотеке 2",
    "create_payments": "Создать платежи",
    "could-not-import-because-of-lack-of-rights": "К сожалению часть документов не получилось создать из-за ограничения прав доступа",
    "next-payments-have-limitations": "Следующие строки содержат ограничения по созданию платежей:",
    "please-make-sure-to-check-before-import": "Пожалуйста убедитесь, что у Вас есть право создания платежей и/или доступ к операциям по данным счетам.",
    "Partially-completed": "Выполнено частично",
    "Left-to-pay": "Осталось оплатить",
    "please-make-sure-you-have-access-for-account": "Пожалуйста убедитесь, что у Вас есть право создания платежей и/или доступ к операциям по данным счетам.",
    "Try-changing-the-filter-values": "Попробуйте изменить значение фильтров",
    "No-active-payment-requests": "Нет активных платёжных требований",
    "Active-payment-requests-will-appear-on-this-page": "Активные платежные требования появятся на этой странице",
    "file size format is not xlxs": "Неподходящий формат файла. Формат должен быть XLSX",
    "New-requirements": "Новые требования",
    "You-have-new-payment-requirements-in-Card-Index-1": "У вас новые платёжные требования в Картотеке 1.",
    "Please-confirm-that-you-have-read-the-requirements": "Подтвердите, что вы ознакомились с требованиями.",
    "To-perform-this-action-familiarize-yourself-with-new-payment-requirements": "Для выполнения данного действия необходимо сначала ознакомиться с новыми платежными требованиями в Картотеке 1",
    "There-are-no-payment-requests-in-this-status": "Нет платежных требований в данном статусе",
    "Payment-requests-in-status-will-appear-on-this-page": "При наличии, платежные требования { status } появятся на этой странице",
    "In-status": "в статусе",
    "Application-for-conversion-(SKV-SKV)": "Заявка на конверсию (СКВ-СКВ)",
    "Payment-requests-and-card-index": "Платёжные требования и картотеки",
    "deposits-for-business": "Депозиты для бизнеса",
    "let-money-work-and-increase-your-budger": "Пусть деньги работают: поможем преумножить ваши средства.",
    "open-until": "Открыт до {n}",
    "open-new-deposit": "Открыть новый депозит",
    "promo": "Промо",
    "open_deposit": "Открыть депозит",
    "back_to_deposits": "К депозитам",
    "overnight": "Проценты каждый день",
    "term_deposit": "Срочный депозит",
    "turnover_money": "Деньги полностью остаются в обороте",
    "seven_percent_deposit": "7% годовых — начисляем каждый день",
    "from_one_to_month": "От 1 до 24 месяцев",
    "till_twenty_one_percent": "До 17% годовых",
    "untouchable_deposit_24_month": "Неснимаемый депозит на 24 месяца",
    "seven_percent_every_month": "7% годовых — ежемесячно",
    "deposit_term": "Депозит “Срочный”",
    "choose-conditions": "Выберите условия",
    "sign-the-application": "Подпишите заявку",
    "overnight-deposit": "Депозит “Проценты каждый день”",
    "payments-history": "История выплат",
    "deposit-information": "Информация о депозите",
    "Applications-for-conversion-SKV-SKV": "Заявки на конверсию (СКВ-СКВ)",
    "Applications-for-the-purchase-of-foreign-currencies": "Заявки на покупку ин. валюты",
    "Accounts-and-cards": "Счета и карты",
    "Loading-onto-plastic-cards-salary": "Загрузка на пласт.карты (зарплата)",
    "Salary-statements": "Ведомости по зарплате",
    "List-of-employees": "Список сотрудников",
    "List-of-currencies": "Список валют",
    "Exchange-rates": "Курс валют",
    "Expanded-issuance-of-personal-accounts": "Выписка лицевых счетов расширенная",
    "Plastic-cards-info": "Информация по пластиковым картам",
    "Document-id": "ID документа",
    "Application-amount": "Сумма заявки",
    "Write-off-account-balance": "Остаток счета списания",
    "Replenishment-account-balance": "Остаток счета пополнения",
    "Date-time": "Дата проведения",
    "Enter-code-or-name": "Введите код или наименование",
    "Setting-up-notifications-in-telegram": "Настройка уведомлений в Telegram",
    "Adding-a-new-number": "Добавление нового номера",
    "The-user-will-automatically-have-access-to-the-telegram-bot": "Пользователь получит автоматически доступ к Telegram боту.",
    "Special-account-in-foreign-currency": "Спецсчет в валюте",
    "Foreign-currency-account": "Валютный счет",
    "Account-for-reservation": "Счет для резервирования",
    "Client-name": "Наименование клиента",
    "Address": "Адрес",
    "Director": "Директор",
    "Chief-accountant": "Главный бухгалтер",
    "Fax": "Факс",
    "Input-information": "Информация для ввода",
    "Application-for-the-purchase-of-foreign-currency": "Заявка на покупку ин.валюты",
    "Currency-name": "Наименование валюты",
    "Maximum-rate": "Максимальный курс",
    "Minimum-rate": "Минимальный курс",
    "Total-amount": "Сумма общая",
    "Interest-rate-in%": "Процентная ставка (в%)",
    "Commission-amount": "Сумма комиссии",
    "Purpose-of-use": "Цель использования",
    "Brief-description-of-the-goal": "Краткое описание цели",
    "Country-to-which-currency-funds-are-sent": "Страна, в которую отправляются валютные средства",
    "Attach-copies-of-documents": "Прикрепить копии документов",
    "Find-a-contract": "Найти контракт",
    "Select-a-file": "Выбрать файл",
    "Attached-files": "Прикреплённые файлы",
    "Download": "Скачать",
    "Are-you-sure-you-want-to-delete-the-file": "Вы точно хотите удалить файл?",
    "Search-for-a-contract": "Поиск контракта",
    "Enter-IDN-contract-number-or-contract-date": "Введите наименование контрактодержателя, ИДН, номер или дату контракта",
    "Forgot-your-username-or-password": "Забыли логин или пароль?",
    "Forgot-your-username-or-password-content-text": "Вам необходимо позвонить в банк и назвать оператору кодовое слово. Сотрудник банка восстановит доступ к приложению удаленно.",
    "How-to-log-into-Internet-Banking": "Как войти в Интернет-банк?",
    "Install-software-files-download-link": "Установите 3 файла ПО. Ссылка на скачивание",
    "Download-file-n": "Скачать файл { n }",
    "If-an-error-message-appears-during-installation-click-skip": "Если при установке появится сообщение об ошибке, нажать “Пропустить”.",
    "Step": "Шаг { n }",
    "Follow-the-link-below-and-log-in-using-your-username-and-password": "Перейдите по ссылке ниже и войдите с помощью логина и пароля.",
    "Instruction-step-3-content-text": "После ввода логина и пароля, мы попросим вас подтвердить вход с помощью единоразового кода из СМС. Если номер телефона неактуален, вы можете восстановить его с помощью ключа ЭЦП или обратившись в банк.",
    "Change-phone-number": "Изменить номер телефона",
    "No-access-to-number": "Нет доступа к номеру",
    "With-total-for-each-day-of-discharge": "С итогом за каждый день выписки",
    "Corporate-cards": "Корпоративные карты",
    "Statement-of-documents-processed-for-the-period": "Ведомость проведенных документов за период",
    "The-rate-cannot-be-equal": "Курс не может быть равен { rate }",
    "The-amount-in-currency-cannot-be-equal-to": "Сумма не может быть равна { rate }",
    "There-is-no-client-account-in-this-currency-and-it-must-be-opened-at-the-bank": "Счет клиента в данной валюте отсутствует и его необходимо открыть в банке",
    "main-page-sidebar-title": "Главная",
    "deposit_overnight": "Депозит “Проценты каждый день”",
    "Balance-and-turnover-statement": "Сальдово-оборотная ведомость",
    "products": "Продукты",
    "statement-project": "зарплатный проект",
    "workers": "Сотрудники",
    "swift-transfers": "Swift - переводы",
    "exchange-currency": "Обмен валюты",
    "buy-sell": "Покупка/Продажа",
    "kartoteka-payment-requirement": "Картотека и требования",
    "ved": "вэд",
    "others": "Другое",
    "Select-all-cards": "Выбрать все карты",
    "Personal-account-statement": "Выписка лицевых счетов",
    "Account-statement-reference": "Справка о работе счета",
    "Report-on-terminals": "Отчет по терминалам",
    "Terminal": "Tерминал",
    "You-do-not-have-corporate-cards": "У Вас нет корпоративных карт",
    "Please-note-that-the-entered-amount-is-greater-than-the-contract-amount": "Обратите внимание что введенная сумма больше суммы контракта.",
    "need-to-fill-the-sum": "Вам нужно заполнить сумму",
    "deposit_sum": "Сумма депозита",
    "choose-time-and-interest-of-deposit": "Выберите срок и ставку депозита",
    "what-account-to-use-in-order-to-fill": "С какого счета пополнить",
    "how-much": "Сколько",
    "automatically-from-bank-account": "Автоматически с расчетного счета, после обработки заявки банком",
    "deposit-fulfillment": "Пополнение депозита",
    "interest-rate": "Процентная ставка",
    "every-month-on-date": "Каждый месяц — { day } числа",
    "interest-payment": "Выплата процентов",
    "i-agree-to-open-deposit": "Я соглашаюсь с правилами оформления депозита",
    "everymonth-payments": "выплаты ежемесячно",
    "deposit_type_and_term": "Тип и срок депозита",
    "deposit_percent": "Процентная ставка",
    "accept-agreement": "Подтвердите согласие",
    "Expanded-issuance": "Выписка расширенная",
    "processed-reports-period": "Ведомость за период",
    "valueDate": "Дата валютирования",
    "orderingCustomer": "Наименование и адрес отправителя (50A)",
    "fieldType56": "Банк посредника (56)",
    "fieldType57": "Банк получателя (57)",
    "receiver59A": "Наименование и адрес получателя (59A)",
    "remittanceInformation": "Детали платежа (70)",
    "chargePayer": "Детали расходов (71A)",
    "intermediaryBank": "Банк посредника (56)",
    "intermediaryBankCountry": "Страна банка-посредника (56A)",
    "beneficiaryCorrespondentBank": "Наименование банка посредника (56A)",
    "nameAndAddress56": "Наименование и адрес банка (56D)",
    "partyIdentifier56": "БИК код (56D)",
    "receiverBankBic": "Банк получателя (57A)",
    "receiverBankName": "Наименование банка получателя (57A)",
    "receiverBankCountry": "Страна банка-получателя (57A)",
    "partyIdentifier57": "БИК код (57D)",
    "nameAndAddress57": "Наименование и адрес банка (57D)",
    "Please-note-that-the-contract-amount-is-large": "Обратите внимание, что заключена большая сумма контракта",
    "The-file-exceeds-the-maximum-size": "Файл превышает максимальный размер { size }",
    "KB": "КБ",
    "MB": "МБ",
    "GB": "ГБ",
    "TB": "ТБ",
    "The-selected-period-should-not-exceed": "Период не должен превышать {n}",
    "days": "{n} дней | {n} день | {n} дня | {n} дней",
    "The-dates-of-the-period-are-entered-incorrectly": "Неверно введены даты периода",
    "report-saldo-reference": "Оборотно-сальдовая ведомость",
    "report-accounts": "Справка по счету",
    "deposits": "Депозиты",
    "Personal-data": "Личные данные",
    "Notifications": "Уведомления",
    "Your-password": "Ваш пароль",
    "My-documents": "Мои документы",
    "My-documents-subtitle": "Здесь вы найдете все документы и информацию об их состоянии.",
    "organization": "Организация",
    "create-document": "Создать документ",
    "Current-password": "Текущий пароль",
    "Last-change": "Последнее изменение",
    "This-is-the-password-you-will-use-to-log-into-online-banking": "Этот пароль вы будете использовать для входа в онлайн-банк",
    "New-again": "Новый ещё раз",
    "Selected": "Выбрано",
    "Update-your-name-and-passport-details-in-the-office": "Актуализируйте ФИО и паспортные данные в офисе",
    "We-can-change-your-data-only-if-you-contact-bank-branch-in-person": "Мы можем изменить ваши данные только в том случае, если вы лично обратитесь в отделение банка.",
    "Download-the-application-for-changing-data": "Скачать заявление на изменение данных",
    "All-clear": "Все понятно",
    "Update-phone-number": "Актуализировать номер телефона",
    "Confirm-the-number-change-with-the-digital-signature-key": "Подтвердите смену номера ключом ЭЦП. Вставьте флешку с ключом в компьютер и нажмите кнопку “Подтвердить номер”.",
    "Update-email-address": "Актуализировать адрес электронной почты",
    "Enter-a-new-email-address": "Введите новый адрес электронный почты. Мы отправим письмо с кодом подтверждения.",
    "Email-address": "Электронная почта",
    "Wrong-login-or-password": "Неверный логин или пароль",
    "You-have-successfully-changed-your-password": "Вы успешно сменили пароль.",
    "This-password-can-only-be-used-to-log-into-this-personal-account": "Данный пароль может быть использован для входа только в данный личный кабинет.",
    "To-log-into-the-updated-persona-account": "Для входа в обновлённый личный кабинет",
    "Link-to-new-personal-account": "ссылка на новый ЛК",
    "and-the-mobile-application-will-need-to-enter-your-old-password": "и мобильное приложение необходимо будет ввести ваш старый пароль",
    "Passport": "Паспорт",
    "New": "Новый",
    "Pending": "Ожидает выполнения",
    "Waiting-execution": "Ожидает выполнения",
    "For-security-purposes-you-need-to-verify-your-account": "В целях безопасности, вам необходимо подтвердить свою учетную запись",
    "Such-a-number-exists": "Такой номер существует",
    "Minimum-length-characters": "Минимальная длина {n} знаков",
    "Characters": "знаков",
    "To-increase-the-security-of-your-data-bla-bla-bla": "Для повышения безопасности Ваших данных сложность пароля должна быть высокой, также нельзя передавать пароль другим лицам, если это случилось, немедленно измените свой пароль",
    "Virtual-signatures": "Виртуальные подписи",
    "Virtual-digital-signatures": "Виртуальные ЭЦП",
    "Change-pin-code": "изменить Pin-код",
    "Active": "Активна",
    "Expired": "Срок действия истёк",
    "Issue-a-new-signature": "Выпустить новую подпись",
    "You-do-not-have-a-virtual-digital-signature-yet": "У вас ещё нет виртуальной ЭЦП. Виртуальная ЭЦП позволяет подписывать документы, используя только PIN-код., без физического носителя.",
    "You-need-to-download-and-install-the-software-on-your-personal-computer": "Вам необходимо скачать и установить ПО на свой персональный комьютер. После завершения установки, нажмите “Выпустить новую ЭЦП”.",
    "Change-signing-pin-code": "Изменить Pin-код для подписания",
    "It-will-need-to-be-entered-each-time-you-sign-documents": "Его будет необходимо вводить каждый раз при подписании документов.",
    "Create-a-new-Pin-code": "Придумайте новый Pin-код",
    "Repeat-your-Pin-code": "Повторите ваш Pin-код",
    "Pin-code-does-not-match-try-again": "Pin-код не совпадает. Попробуйте снова",
    "Pin-code-has-been-successfully-changed": "Pin-код успешно изменён",
    "Are-you-sure-you-want-to-remove-the-signature": "Вы уверены, что хотите удалить подпись?",
    "Next-time-it-will-have-to-be-released-again": "В следующий раз её придется выпускать заново.",
    "Confirm": "Подтвердить",
    "Virtual-signature-released": "Виртуальная подпись выпущена",
    "Install-signature-release-software": "Установите программное обеспечение для выпуска подписи",
    "You-need-to-download-and-install-the-software-on-your-pc": "Вам необходимо скачать и установить ПО на свой персональный комьютер. После завершения установки, повторите попытку и нажмите “Продолжить”.",
    "Download-installation-software": "Скачать ПО для установки",
    "Software-not-found": "ПО не найдено",
    "please-install-software-and-try-again": "Пожалуйста, установите ПО на компьютер и попробуйте снова.",
    "You-already-have-an-active-digital-signature-on-this-computer": "У вас уже есть активная ЭЦП на этом компьютере",
    "You-can-use-it": "Вы можете возпользоваться ей",
    "Signature-limit-exceeded": "Превышен лимит подписей",
    "You-can-have-a-maximum-of-3-signatures-on-3-different-devices": "У вас может быть не более 3 подписей на 3 разных устройствах. Чтобы выпустить новую виртуальную подпись, пожалуйста, удалите одну из текущих подписей.",
    "New-virtual-signatures": "Новые \nвиртуальные подписи",
    "Release-the-signature-on-your-work-pc-and-the-USB-key-will-no-longer-be-needed": "Выпустите подпись на рабочем компьютере и USB-ключ больше не понадобится.",
    "Later": "Позже",
    "Release-signature": "Выпустить подпись",
    "Date-and-time": "Дата и время",
    "Repeat-the-payment": "Повторить платёж",
    "Create-a-template": "Создать шаблон",
    "Email-address-changed": "Адрес электронной почты изменен",
    "Change-email": "Изменить адрес электронной почты",
    "From-the-template": "Из шаблона",
    "There-are-no-documents-in-the-bank": "Нет документов в банке",
    "Sign-and-send-the-documents-for-processing-to-the-bank": "Подпишите и отправьте документы \nна обработку в банк",
    "There-are-no-documents-for-approval": "Нет документов на утверждение",
    "Create-a-new-one-or-wait-for-the-approval-documents-to-appear": "Создайте новый или подождите, когда появятся документы для утверждения",
    "There-are-no-documents-to-sign": "Нет документов на подпись",
    "Create-a-new-one-or-wait-for-the-documents-to-be-signed": "Создайте новый или подождите, когда появятся документы для подписания",
    "No-future-payments": "Нет будущих платежей",
    "Schedule-the-payment-for-a-future-date-and-it-will-appear-here": "Запланируйте платеж на будущую дату, и он появится здесь",
    "Phone-number-changed": "Номер телефона изменен",
    "There-are-no-matches": "Нет совпадений",
    "Try-to-change-the-query": "Попробуйте изменить запрос",
    "Until": "до",
    "Search-my-documents-placeholder": "Поиск по счету получателя, сумме платежа или номеру документа",
    "ID-card": "ID-карта Республики Узбекистан",
    "Passport-uz": "Паспорт гражданина Республики Узбекистан",
    "Military-id": "Воинское удостоверение",
    "Service-id": "Служебное удостоверение",
    "International-passport": "Паспорт иностранного гражданина",
    "Residence-permit": "Вид на жительство",
    "Biometric-passport": "Биометрический паспорт гражданина Респ.Узбекистан",
    "Birth-certificate": "Свидетельство о рождении",
    "Drivers-license": "Национал.водительское удостоверение нового образца",
    "Reset-the-request": "Сбросить запрос",
    "At-the-signing": "На подписании",
    "Signed": "Подписан",
    "Rejected": "Отклонён",
    "On-performance": "На исполнении",
    "You-entered-the-wrong-password": "Вы ввели неправильный пароль",
    "Signed-by": "Подписать",
    "Incorrect-code": "Неверный код",
    "Enter-the-code-from-the-email": "Введите код из письма",
    "We-sent-an-email-with-a-code-to-the-address": "Мы отправили письмо с кодом на адрес",
    "Awaiting-approval": "Ожидают утверждения",
    "Awaiting-signing": "Ожидают подписания",
    "Future-payments": "Будущие платежи",
    "Documents-in-the-bank": "Документы в банке",
    "Connection-error": "Ошибка подключения",
    "Internal-Server-Error": "Внутренняя ошибка сервера",
    "Something-went-wrong-on-our-end": "Пожалуйста, повторите попытку позже или свяжитесь со службой поддержки",
    "Apply": "Применить",
    "Reset": "Сбросить",
    "Download-document": "Скачать документ",
    "Create-signing-pin-code": "Придумайте Pin-код  для подписания",
    "Completed": "Исполнен",
    "Open-details": "Открыть детали",
    "The-bank-will-consider-your-proposed-rate-when-considering-your-application": "Банк рассмотрит предлагаемый Вами курс при рассмотрении заявки",
    "Account-from": "Счёт списания",
    "Payment-number": "Платёж №{n}",
    "Action-history": "История действий",
    "Pinfl": "ПИНФЛ",
    "inquiries": "Заявки",
    "my-inquiries": "Мои заявки",
    "about-client": "О клиенте",
    "Loading-data-error": "Ошибка загрузки данных",
    "Receiver-card": "Карта получателя",
    "Transit-account": "Транзитный счёт",
    "full_name": "Фамилия Имя Отчество",
    "inn_or_pinfl": "ИНН или ПИНФЛ организации",
    "product": "Продукт",
    "enter_number": "Введите номер",
    "email": "Электронная почта",
    "additional": "Дополнительно",
    "manager_comments": "Комментарий для менеджера — необязательно",
    "add-comment": "Добавить комментарий",
    "send-inquiry": "Отправить заявку",
    "new-inquiry": "Новая заявка",
    "incorrect-email": "Введите электронную почту в корректном формате",
    "manager-will-contact": "В ближайшее время менеджер банка свяжется с клиентом — следите за статусом.",
    "account-open": "Открытие расчетного счета",
    "Sign": "Подписать",
    "Logout": "Выйти",
    "Sorting-by-date": "Сортировка по дате",
    "From-new-payments-to-old-ones": "От новых платежей к старым",
    "From-old-payments-to-new-ones": "От старых платежей к новым",
    "sum-purpose": "Сумма и назначение",
    "payment-amount": "Введите сумму платежа",
    "account-tin-name": "Счёт, ИНН или наименование бюджетополучателя",
    "who-send": "Кому отправить",
    "corporate-card": "На корпоративную карту",
    "to-individual": "Физическому лицу",
    "min-max-letters": "{0}/{1} символов",
    "from-account-send": "С какого счёта отправить",
    "template-info": "Информация о шаблоне",
    "template-name": "Название шаблона",
    "select-bank": "Выбор банка получателя",
    "bank-name-mfo": "Наименование банка или МФО",
    "name-template": "Назовите свой шаблон",
    "create-payment": "Создать платёж",
    "update-template": "Обновить шаблон",
    "save-template": "Сохранить шаблон",
    "template-created": "Шаблон создан",
    "make-payment-by-template": "Совершите платёж по созданному шаблону.",
    "template-updated": "Шаблон обновлён",
    "selecting-payment-purpose": "Выбор кода назначения платежа",
    "purpose-name-code": "Наименование назначения или код",
    "type": "Тип",
    "sender-branch": "Банк получателя {0}",
    "receiver-branch": "Банк отправителя {0}",
    "create-templates-and-chill": "Создавайте шаблоны и упростите свои процессы.",
    "create-template": "Создать шаблон",
    "find-by-account-amount-documents": "Поиск по счету получателя, сумме платежа или номеру документа",
    "no-templates": "Нет шаблонов",
    "no-templates-create": "Здесь пока нет шаблонов. Создайте свои, чтобы сделать процессы еще более эффективными и удобными",
    "template-deleted": "Шаблон удалён",
    "create-new-template-and-chill": "Создайте новый шаблон и упростите свои процессы.",
    "cant-delete-template": "Не удалось удалить шаблон",
    "try-delete-template-later": "Попробуйте удалить его позже.",
    "document-details": "Детали документа",
    "no-active-inq": "У вас нет активных заявок",
    "invite-and-watch": "Начните приглашать клиентов и следите здесь за статусом заявок",
    "Client-name-or-INN": "Наименование клиента или ИНН",
    "Created": "Созданы",
    "In-process": "В работе",
    "Client-refusal": "Отказ клиента",
    "Finished": "Завершена",
    "statements": {
      "title": "Выписки",
      "desc": "Создавайте и просматривайте выписки без лишних сложностей.",
      "tab1": "Новая выписка",
      "tab2": "Ранее созданные",
      "item1": {
        "title": "Выписка по счёту",
        "desc": "В формате Excel"
      },
      "item2": {
        "title": "Выписка расширенная",
        "desc": "В формате TXT"
      },
      "item3": {
        "title": "Справка о работе счёта",
        "desc": "Excel и TXT"
      },
      "item4": {
        "title": "Выписка лицевых счетов",
        "desc": "В формате TXT"
      },
      "item5": {
        "title": "Оборотно-сальдовая ведомость",
        "desc": "В формате TXT"
      },
      "item6": {
        "title": "Ведомость за период",
        "desc": "Проведенные документы по выбранным счетам"
      },
      "item7": {
        "title": "Платёжные документы",
        "desc": "PDF со всеми документами "
      },
      "item8": {
        "title": "Корпоративные карты",
        "desc": "В формате Excel"
      },
      "item9": {
        "title": "Отчёт по терминалам",
        "desc": "Проведённые транзакции по терминалу "
      }
    },
    "Inquiry-number": "Заявка №",
    "client": "Клиент",
    "phone": "Телефон",
    "Full-name": "Фамилия Имя Отчество",
    "The-current-account-has-been-opened": "Расчетный счет был открыт",
    "Payments-creation": "Создание платежей",
    "Opened": "Открыта",
    "Sum-payments": "Сумовые",
    "Currency-payments": "Валютные",
    "To-any-Uzbekistan-bank": "В любой банк Узбекистана",
    "To-card": "На карту",
    "To-budget": "В бюджет",
    "By-requisites": "По реквизитам",
    "To-treasury": "В казначейство",
    "Government-services": "Государственные услуги",
    "Payment-via-MUNIS": "Оплата через МУНИС",
    "Swift-transfer": "SWIFT - перевод",
    "All-over-the-world": "По всему миру",
    "Buy-currency": "Покупка валюты",
    "Sell-currency": "Продажа валюты",
    "To-currency-account": "На валютный счет",
    "From-currency-account": "С валютного счета",
    "currencyConversion": "Конвертация валюты",
    "Discharge-information": "Информация о выписке",
    "Sorting-and-turnover-type": "Сортировка и тип оборота",
    "Loan-contract": "Кредитный контракт",
    "Loan-repayment-account": "Счет для погашения кредита",
    "In-the-presence-of": "при наличии",
    "payment-history": "История платежей",
    "here-find-history": "Здесь вы найдете все проведенные документы и информацию об их состоянии.",
    "search-payment": "Поиски по платежам",
    "in-out": "Входящие/Исходящие",
    "incoming": "Входящие",
    "outcoming": "Исходящие",
    "date-range": "Дата или период",
    "buy": "Купить",
    "sell": "Продать",
    "out-sum": "Сумма cписания",
    "in-sum": "Сумма зачисления",
    "short-payrolls": "ЗП ведомости",
    "convertation": "Конвертация",
    "in-currency": "Валюта зачисления",
    "in-account": "Счёт зачисления",
    "out-currency": "Валюта списания",
    "gov-services": "Государственные уcлуги",
    "try-change-request": "Попробуйте изменить ваш запрос",
    "no-payments": "Нет проведённых платежей",
    "create-payment-and-store-here": "Создайте новый платёж и он будет храниться здесь",
    "history": "История",
    "confirmWithOnlineDigitalSignature": "Виртуальной подписью",
    "enter-virtual-signature-pin": "Введите Pin-код виртуальной ЭЦП",
    "forgot-pin-code": "Забыли Pin-код?",
    "found-n-results": "Найдено {n} результатов. | Найден {n} результат. | Найдено {n} результата. | Найдено {n} результатов.",
    "Period": "Период",
    "Select-a-service": "Выберите услугу",
    "failed-to-submit-modal": "Возникла ошибка, попробуйте позже",
    "submit-review": "Отправить отзыв",
    "tell-us-why-you-evaluated-that-way": "Расскажите, почему вы поставили такую оценку? (это необязательно)",
    "very-bad": "Очень плохо",
    "nice": "Прекрасно",
    "we-see-that-you-recently-have-opened-the-account": "Видим, что вы недавно открыли счёт в нашем банке.",
    "tell-us-how-it-was": "Расскажите, как всё прошло — мы хотим стать ещё лучше.",
    "evaluate-the-service": "Оцените качество обслуживания",
    "rate-us-before-submitting": "Пожалуйста, заполните поле с оценкой",
    "review-is-submitted": "Отзыв отправлен",
    "thank-you-for-making-uzum-better": "Спасибо, что помогаете делать Uzum Business лучше :)",
    "services": "Услуги",
    "wrong-login-or-password": "Неверно указан логин или пароль",
    "input-placeholder": "Ввести",
    "create-payment-order-for-transit-salary-account": "Создать платежное поручение на транзитный зарплатный счет",
    "gov-service": "Государственная услуга",
    "add-payroll": "Добавить ведомость",
    "user-not-activated": "Пользователь не активен",
    "": "Государственная услуга",
    "import-document": "Импорт документа",
    "create-from-template": "Создать из шаблона",
    "payment-to-account": "Платёж на счёт",
    "payment-to-card": "Платёж на карту",
    "payment-to-budget": "Платёж в бюджет",
    "payment-to-treasury": "Платёж в казначейство",
    "Create-statement": "Создайте выписку",
    "Create-statement-desc": "Вы сможете найти все ваши ранее созданные выписки на этой странице",
    "Restore-statements": "Восстановить выписки",
    "Create-a-new-statement": "Создайте новую выписку",
    "All-reports-have-been-deleted": "Все выписки удалены",
    "Are-you-sure-you-want-to-clean-everything-up": "Вы уверены что хотите всё очистить?",
    "The-report-is-being-generated-and-will-be-uploaded-soon": "Отчет формируется и скоро будет загружен",
    "Report-succes-notify-title": "Выписка создана",
    "Report-succes-notify-desc": "Вы можете найти её в разделе “Ранее созданные” в любое время.",
    "purpose": "Назначение",
    "document-info": "Информация о документе",
    "phone-for-call": "Телефон для связи",
    "copy-currency-contract": "Копии валютного контракта",
    "document-name": "Наименование документа",
    "variant": "Экземпляр",
    "no-document": "Документ отсутствует",
    "request-for-currency-operation": "Заявка на операции с валютой",
    "currency-conversion-info-text": "Для принятия положительного решения по Вашей заявке необходимо\n          резервирование средств на блок счет по конвертации.",
    "currency-conversion-info-text-sub": "Настоятельно рекомендуется зарезервировать средства.",
    "left-sum-block-account": "Остаток на блок счете",
    "sum-request-variant": "Сумма заявки в эквиваленте",
    "send-from-my-documents-page": "Отправьте в банк документ из раздела Меню \"Мои документы\".",
    "are-you-sure-you-to-continue": "Вы уверены что хотите продолжить?",
    "yes": "Да",
    "no": "Нет",
    "cancel-download": "Отменить скачивание",
    "Poisk-documentov": "Поиск документов",
    "Try-to-choose-other-dates": "Попробуйте выбрать другие даты",
    "Try-to-choose-other-filters": "Попробуйте выбрать другие фильтры",
    "Creating-an-extract": "Формируем выписку",
    "Error-during-formation": "Ошибка при формировании",
    "An-error-has-occurred-please-try-again-later": "Возникла ошибка, повторите попытку позже",
    "invalid-date": "Не валидная дата",
    "Reset-dates": "Сбросить даты",
    "Reset-filters": "Сбросить фильтры",
    "Requisites": "Реквизиты",
    "Service1": "Услуга",
    "Service2": "Сервис",
    "Region": "Область",
    "Area": "Район",
    "Branch": "Отделение",
    "Create-statement2": "Создать выписку",
    "payment-monthly": "выплаты ежемесячно",
    "deposit-terms-agreement": "Я соглашаюсь с правилами оформления депозита",
    "extended-successfully": "Успешно продлено",
    "extended-unsuccessfully": "Не удалось продлить",
    "Select-a-card": "Выберите карту",
    "date-from": "Дата с",
    "date-to": "по",
    "agreed": "Ознакомился",
    "turn-on-tg-bot": "Включить доступ в бот",
    "available-for-tg-users": "Доступен только для пользователей Telegram",
    "add-phone-number": "Добавить новый номер",
    "are-you-sure-delete-number": "Вы уверены, что хотите удалить номер",
    "nex-time-should-add-again": "В следующий раз его придется добавлять заново",
    "number-added-successfully": "Номер был успешно добавлен",
    "number-deleted-successfully": "Номер был успешно удален",
    "for-safety-confirm-account": "В целях безопасности, вам необходимо подтвердить свою учетную запись",
    "enter-corresponded-account-user": "Введите Корреспондентский счёт банка получателя",
    "show-client-name-account-name-payment-name": "Укажите наименование клиента, номер счета клиента, назначение платежа",
    "open-deposit-title": "Открыт на {term} месяца под {rate}% годовых — выплата процентов 25 числа каждого месяца.",
    "Terminal-number": "Номер терминала",
    "forbidden-page": "Запрещенный код 403 не имеет доступа к этому маршруту.",
    "back-to-main": "Вернутся на главную",
    "password-change-hint-text": "Для повышения безопасности Ваших данных сложность пароля должна быть\n          высокой, также нельзя передавать пароль другим лицам, если это\n          случилось, немедленно измените свой пароль",
    "password-hint-text-start": "При смене пароля его можно будет использовать для входа в данный личный\n        кабинет и мобильное приложение. Для входа в старый личный кабинет",
    "password-hint-text-end": "необходимо будет воспользоваться старым паролем.",
    "language": "Язык",
    "key-is-not-for-account": "Ключ не соответствует учётной записи",
    "put-the-proper-key-and-try-again": "Вставьте верный ключ и попробуйте снова.",
    "we-could-not-find-virtual-key": "Не смогли обнаружить ключ ЭЦП",
    "put-the-key-and-try-again": "Вставьте ключ и попробуйте снова.",
    "your-key-is-out-of-date": "Ваш ключ ЭЦП устарел",
    "try-with-virtual-signature-or-contact-us": "Продолжите с виртуальной подписью или закройте и обратитесь в поддержку.",
    "send-via-anor": "Отправить через Анор 24/7",
    "payment-can-be-sent-at-any-time": "Платёж можно отправить в любое время",
    "even-after-end-of-banking-day": "Даже после окончания операционного дня в банке",
    "search-documents": "Поиск документов",
    "Destination-codes": "Коды платежей",
    "Select-account": "Выберите счёт",
    "Munis-subtitle": "Подписывайте документы или просматривайте историю платежей.",
    "Supplier": "Поставщик",
    "receiverNameNew": "Наименование счета получателя",
    "Debit-turnover": "Дебетовые обороты",
    "Credit-turnover": "Кредитовые обороты",
    "Munis": "Мунис",
    "Personal-account": "Лицевой счёт",
    "Payment-created": "Платёж создан",
    "Commission": "Комиссия",
    "waiting-documents": "Документы в ожидании",
    "Select-service": "Выберите сервис",
    "Select-region": "Выберите область",
    "Select-area": "Выберите район",
    "Select-branch": "Выберите отделение",
    "Enter-amount": "Введите сумму",
    "Sum-account": "Сумовой счёт",
    "failed-to-load-inquiry-details": "Произошла ошибка при загрузке деталей заявки. Попробуйте позже",
    "partner": "Партнёр",
    "inquiries-created": "Всего заявок создано",
    "inquiries-in-progress": "Заявок в работе",
    "inquiries-declined": "Заявок отклонено",
    "inquiries-succeed": "Успешных заявок",
    "You-can-use-it-or-delete-to-create": "Вы можете воспользоваться ей или удалить существующую, чтобы создать новую.",
    "incorrect-pin-try-again": "PIN-код введён неверно. Попробуйте снова.",
    "if-no-styx-on-the-device": "Если у вас еще нет ЭЦП на этом устройстве",
    "enter-sms-to-commit": "Введите код из СМС для подтверждения",
    "enter-sms-to-delete": "Введите SMS-код,чтобы удалить подпись",
    "styx-removed": "Виртуальная подпись удалена.",
    "how-use-styx": "Как использовать ЭЦП?",
    "download-and-install-by-button": "Скачайте и установите ПО на ваш персональный компьютер, нажав на кнопку ниже.",
    "download-mac": "Скачать на Mac",
    "download-windows": "Скачать на Windows",
    "new-styx-by-button-and-pin": "Нажмите на кнопку ниже, чтобы выпустить новую подпись. Мы предложим вам установить PIN-код.",
    "understandable": "Всё понятно",
    "styx-guide": "Для использования виртуальной ЭЦП скачайте необходимое ПО в настройках виртуальной подписи.\n\nДля использования виртуальной ЭЦП на вашем устройстве, оно должно быть привязано к ней. \n\nОдно устройство может быть привязано к одной виртуальной ЭЦП. В рамках вашей учётной записи вы можете выпустить до 3 виртуальных ЭЦП для 3 разных устройств.\n\nЕсли вы забыли PIN-код или у виртуальной ЭЦП истёк срок действия, удалите её с компьютера и выпустите новую, создав новый PIN-код.",
    "how-does-it-work": "Как это работает?",
    "deposit-pay": "Суммируем проценты, накопленные за месяц, и выплачиваем их на расчётный счёт раз в месяц.",
    "deposit-at-night": "Вечером деньги зачисляются",
    "deposit-money-will-go-in-the-day-end": "Деньги с расчётного счёта перейдут на депозитный счёт в конце дня.",
    "deposit-money-works-at-night": "Ночью деньги работают",
    "deposit-money-are-located-in-account": "Деньги находятся на депозитном счёте, и на них начисляются проценты.",
    "deposit-in-the-morning": "Утром деньги спишутся",
    "deposit-money-back-in-the-morning": "Деньги с депозитного счёта вернутся на расчётный счёт в 9:00 утра.",
    "choose-account": "Выберите счет",
    "what-will-be-interest": "Какая будет процентная ставка?",
    "what-will-be-term": "На какой срок?",
    "how-to-increase-deposit": "Как пополнить депозит?",
    "can-i-withdraw-before": "Можно ли закрыть досрочно?",
    "how-much-to-deposit": "Сколько внести на депозит?",
    "only-for-24-month": "Только на 24 месяца",
    "progressive-deposit-rate": "В этом депозите прогрессивная процентная ставка — увеличивается на 1 процент в течение трёх месяцев.",
    "deposit-is-taken-from-account": "Депозит пополнится с выбранного расчетного счета, сразу после обработки вашей заявки",
    "you-can-do-it-only-in-the-bank-office": "Сделать это можно только в офисе банка, расчет процентов будет зависеть от срока",
    "everyday-for-the-leftover-amopunt": "Ежедневно, на сумму остатка выбранного счёта",
    "how-interest-is-paid": "Как начисляются проценты?",
    "what-account": "С какого счета пополнить?",
    "only-for-12-month": "на 12 месяцев",
    "sever-percent-interest": "7% годовых",
    "you-dont-need-to-do-anything-bank-will-do": "Вам ничего не нужно делать, деньги автоматически перейдут с выбранного расчётного счёта на депозитный счёт в конце дня",
    "this-could-be-done-only-in-the-office": "Сделать это можно только в офисе банка",
    "every-month-in-25": "Каждый месяц — 25 числа",
    "how-interest-is-being-paid": "Как выплачиваются проценты?",
    "which-account-should-be-paid": "На какой счет начислять проценты?",
    "deposit-account": "Депозитный счет",
    "rs-account": "Расчетный счет",
    "for-the-leftover-amount-you-will-get-money": "На сумму остатка каждый день будем начислять проценты",
    "the-leftover-we-will-take-in-the-eod": "Остаток расчетного счета переведем на депозит в конце банковского дня",
    "for-all-time": "За все время",
    "to-the-account": "На расчетный счет •",
    "from": "с",
    "to": "по",
    "here-you-will-have-interests": "Здесь будут начисления процентов",
    "inquiry-to-open-deposit": "Заявление на открытие депозита",
    "faq": "Частые вопросы",
    "deposit_faq_first": "Снять деньги с депозита досрочно — можно. Для этого обратитесь в отделение банка, напишите заявление и получите деньги.",
    "deposit_faq_second": "Скоро вы сможете делать это без личного обращения.",
    "how-to-withdraw-money-from-deposit": "Как снять деньги с депозита?",
    "deposit_faq_third": "Внести деньги на депозит — можно, но проценты на сумму платежа начисляться не будут.",
    "deposit_faq_fourth": "Чтобы увеличить сумму депозита, обратитесь в отделение банка и напишите заявление на изменение условий депозита.",
    "deposit_faq_fifth": "Скоро вы сможете делать это без личного обращения.",
    "how-to-close-deposit": "Как закрыть депозит?",
    "you-can-close-deposit-but-it-will-affect-interest": "Закрыть депозит досрочно — можно, но при этом изменится процентная ставка.",
    "steps-to-close-deposit": "Чтобы закрыть депозит, обратитесь в отделение банка и напишите заявление на закрытие депозита.",
    "deposit": "Депозит",
    "is-open-untill": "открыт до",
    "term_dep": "Срочный",
    "deposit_rules": "Правила депозита",
    "account-for-deposit": "Расчетный счет для депозита",
    "uzs-account": "Сумовой счёт ",
    "for-24-month": "на 24 месяца",
    "deposit-till-17%": "до 17%",
    "for-12-month": "на 12 месяцев",
    "I-agree-with": "Я соглашаюсь с",
    "dep-rules": "правилами оформления",
    "rules-of-dep": "депозита",
    "select-placeholder": "Выбрать",
    "you-should-have-more-than-thousand": "На счету должно быть минимум 1 000 сум - пополните счет или выберите другой",
    "not-enough-money": "Недостаточно средств — пополните счёт или выберите другой",
    "deposit-is-created": "Депозит успешно создан",
    "10_days": "10 дней",
    "1_month": "1 месяц",
    "2_months": "2 месяца",
    "3_months": "3+ месяцев",
    "One": "один",
    "Two": "два",
    "Three": "три",
    "Four": "четыре",
    "Five": "пять",
    "Six": "шесть",
    "Seven": "семь",
    "Eight": "восемь",
    "Nine": "девять",
    "Ten": "десять",
    "Eleven": "одиннадцать",
    "Twelve": "двенадцать",
    "Thirteen": "тринадцать",
    "Fourteen": "четырнадцать",
    "Fifteen": "пятнадцать",
    "Sixteen": "шестнадцать",
    "Seventeen": "семнадцать",
    "Eighteen": "восемнадцать",
    "Nineteen": "девятнадцать",
    "Twenty": "двадцать",
    "Thirty": "тридцать",
    "Fourty": "сорок",
    "Fifty": "пятьдесят",
    "Sixty": "шестьдесят",
    "Seventy": "семьдесят",
    "Eighty": "восемьдесят",
    "Ninety": "девяносто",
    "one-hundred": "сто",
    "two-hundred": "двести",
    "three-hundred": "триста",
    "four-hundred": "четыреста",
    "five-hundred": "пятьсот",
    "six-hundred": "шестьсот",
    "seven-hundred": "семьсот",
    "eight-hundred": "восемьсот",
    "nine-hundred": "девятьсот",
    "Sum": "сум",
    "Suma": "сума",
    "Sumov": "сумов",
    "US-dollar": "доллар США",
    "US-dollara": "доллара США",
    "US-dollars": "долларов США",
    "thousand": "тысяча",
    "thousands": "тысячи",
    "thousand2": "тысяч",
    "one1": "одна",
    "two2": "две",
    "million": "миллион",
    "million2": "миллиона",
    "millions": "миллионов",
    "billion": "миллиард",
    "billion2": "миллиарда",
    "billions": "миллиардов",
    "trillion": "триллион",
    "trillion2": "триллиона",
    "trillions": "триллионов",
    "tiyin": "тийин",
    "tiyin2": "тийина",
    "tiyins": "тийинов",
    "created": "Cоздан",
    "carried-out": "проведен",
    "Dear-clients": "Уважаемые клиенты",
    "Dear-clients-message": {
      "part1": "Просим вас не отправлять одновременно одну и ту же ведомость через старый и новый \"Банк клиент\", чтобы избежать двойного зачисления. Отправляйте ведомости по очереди и избегайте их дублирования. После отправки платежа на транзитный зарплатный счет 23106, отправьте ведомость в течение одного дня, чтобы зачисление произошло вовремя. Просим также отправлять ведомости на зачисление заработной платы до закрытия банковского дня.",
      "part2": "С уважением,\nКоманда Uzum Business - Kapitalbank",
      "part3": "В настоящее время в мобильном приложении и интернет-банке временно недоступны платежи в казначейство и в бюджет  через систему быстрых платежей 24/7 (ANOR). Мы уже работаем над решением этой проблемы, чтобы как можно скорее восстановить полноценную работу сервисов.",
      "part4": "Приносим извинения за возможные неудобства. Спасибо за ваше понимание и терпение!  "
    },
    "Missed-payment-date": "Пропущенная дата оплаты",
    "File-deleted": "Файл удалён",
    "Russian-federation": "Российская Федерация",
    "Months": "месяцев",
    "Month": "месяца",
    "24-months": "24 месяц",
    "Successfully-deleted": "Успешно удалено",
    "This-functionality-is-being-improved-and-will-soon-be-available-in-a-new-version": "Данный функционал дорабатывается и скоро будет доступен в новой версии. При необходимости, Вы можете совершить необходимую операцию в старой версии открыв следующую ссылку",
    "in-the-browser": "в браузере {browser}",
    "sec": "сек",
    "Are-you-sure-you-want-to-do-this?": "Вы точно хотите это сделать?",
    "Insufficient-funds": "Недостаточно средств",
    "Send-error": "Ошибка отправки",
    "Withdrawn": "Отозван",
    "Partially-withdrawn": "Отозван частично",
    "Revocation-error": "Ошибка отзыва",
    "Moved-to-K2": "Перенесен в К2",
    "Without-execution-due-to-failure-to-appear": "Без исполнения в связи с неяв. кл.",
    "Returned-due-to-clients-no-show": "Возвращен по неявке клиента",
    "Partially-rejected-and-paid": "Частично отклонен и оплачен",
    "Partially-rejectedand-in-K2": "Частично отклонен и в К2",
    "The-deadline-for-transfer-for-consideration-has-expired": "Истек срок передачи на рассмотрен",
    "Completed2": "Выполнен",
    "Refusal": "Отказ",
    "Partially-completed2": "Выполнен частично",
    "Without-execution-due-to-failure-to-appear2": "Без выполнения в связи с неяв. кл",
    "Move-to-the-next-step": "Переходите на следующий шаг",
    "403 Forbidden": "403 - доступ запрещен",
    "Some of the doors are always close to you": "К сожалению, у вас нет разрешения на доступ к этой странице",
    "Go back": "Вернутся назад",
    "To-main": "Вернутся на главную",
    "404 Not Found": "404 - страница не найдена",
    "You know life is always ridiculous": "Страница, которую вы запрашиваете, не существует. Возможно она устарела, была удалена, или был введен неверный адрес в адресной строке.",
    "Internal Error": "500 - ошибка сервера",
    "Internal error may prove that you need hiring more developers": "На сервере произошла непредвиденная ошибка. Пожалуйста, подождите, она вскоре будет исправлена.",
    "Open-until-date": "Открыт до {date}",
    "Month2": "месяц",
    "Month-plural": "{n} месяцев | {n} месяц | {n} месяца | {n} месяцев",
    "type-of-turnover": "Тип оборота",
    "additional-params-sort": "Дополнительные параметры сортировки",
    "Amount-of-credit": "Сумма кредита",
    "Credit-account": "Счёт кредитования",
    "virtual-signature-created": "Виртуальная подпись выпущена.",
    "something-went-wrong": "Что-то пошло не так. Пожалуйста, повторите позже.",
    "hint": "Подсказка",
    "Start-making-money-on-the-Uzum-Market": "Начните зарабатывать на Uzum Market!",
    "Open-a-store-right-now": "Откройте магазин прямо сейчас",
    "Open": "Открыть",
    "revenues": "Доходы",
    "for-period": "За период",
    "overall-fixex-payments": "Всего фиксированных выплат в сумах",
    "after-inquiries-there-will-be-a-sum": "После оформления первых заявок, здесь появится предварительная сумма к выплате.",
    "successfull-inquiries": "Успешных заявок",
    "fixed-payment": "Фиксированная выплата",
    "Delete-all": "Удалить всё",
    "we-could-not-create": "Мы не смогли создать заявку сейчас. Попробуйте позже",
    "otp-signin-footer-text": "{phone} или войдите с помощью {epass} или с {virtual}",
    "csr-key-genitive": "Эцп ключа",
    "signin-virtual-signature": "Вход с помощью виртуального ЭЦП ключа",
    "signin-virtual-signature-description": "Если у вас запущен Styx Token Manager, нажмите “Войти”.",
    "enter": "Войти",
    "or-enter-with": "или войдите с помощью",
    "or-with": "или с",
    "go-with-signature": "Продолжить с ключом ЭЦП",
    "go-with-virtual-signature": "Продолжить с подписью",
    "change-phone-guide": "Подтвердите смену номера ключом ЭЦП или виртуальной подписью. \r\n\r\nВставьте флешку с ключом в компьютер для того, чтобы продолжить с ключом ЭЦП.\r\n\r\nНажмите кнопку “Продолжить с подписью” и введите PIN-код, чтобы воспользоваться виртуальной подписи для изменения.",
    "company_name": "Наименование организации",
    "operation_date_bank": "Операционный день банка",
    "stats": "Статистика",
    "inn/pinfl": "ИНН или ПИНФЛ",
    "account_opened": "Счет открыт",
    "failedToFetchInquiries": "Не удалось загрузить заявки. Попробуйте позже",
    "business": "Бизнес",
    "open-the-account": "Открытие расчетного счёта",
    "your-manager": "Ваш менеджер",
    "Download-for-1C": "Скачать для 1C",
    "opened-products-and-their-payments": "По открытым продуктам и выплатам за них.",
    "search-branches": "Искать отделения банка",
    "refresh-phone-number": "Обновите номер телефона",
    "actualize-phone-number-by-branch": "Актуализируйте номер, чтобы продолжить пользоваться онлайн-банком.\n\nЭто можно сделать в филиале банка  — там наши сотрудники помогут обновить номер телефона.",
    "phone-number-change-not-allowed": "Изменение номера телефона через онлайн-банк недоступно.\n\nЭто можно сделать в филиале банка  — там наши сотрудники помогут обновить номер телефона.",
    "actualize-phone-number-by-signature": "Актуализируйте номер, чтобы продолжить пользоваться онлайн-банком.\n\nДля этого вставьте флешку с ключом в компьютер и нажмите “Подтвердить”.",
    "confirm-with-virtual-signature-genitive": "Виртуальной подписи",
    "could-not-find-virtual-signature": "Не смогли обнаружить виртуальную ЭЦП",
    "reload-styx-try-again-or-other-auth": "Перезапустите Styx Token Manager и попробуйте снова или используйте другой способ для авторизации.",
    "could-not-create-virtual-signature-try-later": "Не удалось создать виртуальную подпись, попробуйте позже",
    "address-or-name-bank": "Адрес или наименование банка",
    "try-change-search-request": "Попробуйте изменить поисковый запрос",
    "bank-branches": "Отделения банка",
    "this-device": "Это устройство",
    "Download-the-statement": "Скачать выписку",
    "We-will-call-and-tell-you-about-the-products": "Мы позвоним и расскажем  о продуктах",
    "Leave-your-contact-information-so-that-the-manager-can-contact-you": "Оставьте свои контактные данные, чтобы менеджер мог с Вами связаться",
    "counterparty-banner-title": "В разделе Сервисы появилась проверка контрагентов",
    "counterparty-banner-subtitle": "Теперь можно принимать решение \nо сотрудничестве с партнёром на основе данных о нём.",
    "All-services": "Все сервисы",
    "All-services-and-facilities": "Все сервисы и услуги",
    "Checking-counterparties": "Проверка контрагентов",
    "counterparty-card-subtitle": "Принимайте решения о сотрудничестве\nс партнёром на основе данных о нём.",
    "Send-contacts": "Отправить контакты",
    "counterparty-search-title": "Найдите компанию для проверки",
    "counterparty-search-subtitle": "Помогаем получить информацию о предприятиях и организациях, анализировать  судебные дела и использовать данные о госзакупках",
    "first_name": "Имя",
    "second_name": "Фамилия",
    "patronymic": "Отчество",
    "Thank-you": "Спасибо",
    "Found-count-results": "Нашли 0 результатов | Нашли {n} результат | Нашли {n} результата | Нашли {n} результатов",
    "counterparty-search-placeholder": "Название компании, ФИО руководителя или ИНН",
    "The-manager-will-call-you-back-within-5-minutes-and-answer-your-questions": "Менеджер перезвонит в течение 5 минут и ответит на ваши вопросы.",
    "Application-sent": "Заявка отправлена",
    "Registered-date": "Зарегистрирован {date}",
    "Phone-number-or-login": "Номер телефона или логин",
    "Or": "Или",
    "Login-to-Uzum-Business": "Войти в Uzum Business",
    "check-counterparty-rating-modal-title": "Это рейтинг устойчивости предпринимателя",
    "check-counterparty-rating-modal-content": "Рейтинг формируется на электронной платформе Налогового комитета, основанный на соблюдении предпринимателем требований законодательства. \n{br}{br}\n«ААА», «АА» и «А» – Высокий{br}\n«ВВВ», «ВВ» и «В» – Средний{br}\n«ССС», «СС» и «С» – Удовлетворительный{br}\n«D» – Низкий\n{br}{br}\nДанные для рейтинга поступают через межведомственный обмен. Порядок ведения определяется Кабинетом Министров.",
    "check-counterparty-sme-popover-title": "Принадлежность к субъектам малого предпринимательства",
    "Information-updated": "Информация обновлена",
    "check-counterparty-header-bottom-info": "Информация предоставляется ООО “МойБухгалтер” на основании {link} от {date}",
    "Become-a-client": "Стать клиентом",
    "The-first-6-months-are-free": "Первые 6 месяцев бесплатно.",
    "Consultation": "Консультация",
    "We-will-call-you-and-tell-you-about-the-product": "Позвоним и расскажем о продукте.",
    "View-all": "Посмотреть все",
    "Become-a-bank-client": "Станьте клиентом банка",
    "DownloadTemplate": "Скачать шаблон",
    "Company-name-or-individual-entrepreneur": "Название компании или ИП",
    "We-are-considering-the-application": "Рассматриваем заявку",
    "We-check-the-information-and-prepare-everything-for-opening-an-account": "Проверяем информацию и готовим всё к открытию счёта.",
    "Enter-the-number-in-the-correct-format": "Введите номер в корректном формате",
    "IIN-contains-9-digits-and-PINFL-14": "ИНН содержит 9 цифр, а ПИНФЛ — 14.",
    "please-check-status-zp-vedemost-inside": "Пожалуйста перейдите внутрь ведомости  и проверьте статусы карт сотрудников",
    "Registered": "Зарегистрирован",
    "Not-registered": "Не зарегистрирован",
    "The-company-was-liquidated": "Компания ликвидирована",
    "Operating-company": "Действующая компания",
    "Inactive-company": "Неактивная компания",
    "Active-entrepreneur": "Действующий предприниматель",
    "Activities-are-temporarily-suspended": "Деятельность временно приостановлена",
    "Liquidated": "Ликвидирован",
    "The-certificate-has-expired": "Истёк срок сертификата",
    "there-will-be-payment-and": "В день выплат будет начислена фиксированная выплата, а также ",
    "banks-revenue": "30% от прибыли банка",
    "Activity": "Деятельность",
    "Tax-regime": "Налоговый режим",
    "Kind-of-activity": "Вид деятельности",
    "Additional-activities": "Дополнительные виды деятельности",
    "Date-of-liquidation": "Дата ликвидации",
    "Suspension-date": "Дата приостановки",
    "VAT-payer": "Плательщик НДС",
    "VAT-registration-date": "Дата регистрации плательщика НДС",
    "Flat-tax": "Фиксированный налог",
    "Tax-based-on-declaration": "Налог на основе декларации",
    "Turnover-tax": "Налог по обороту",
    "VAT": "НДС",
    "Fixed-turnover-tax": "Налог по фиксированному обороту",
    "check-counterparty-header-top-info-text": "Данная услуга предоставляется исключительно ООО «Assistant Business Market». АКБ «Капиталбанк» не отвечает за достоверность сведений полученных посредством «Системы проверки контрагентов» и за последствия использования размещенной на ней информации,  а также за возможную потерю или порчу данных, а также другие последствия любого характера, которые могут произойти.",
    "Tax-debt": "Налоговая задолженность",
    "No-data": "Нет данных",
    "To-search-results": "К результатам поиска",
    "You-already-have-an-account": "У вас уже есть аккаунт",
    "Use-your-username-and-password-to-login-to-Uzum-Business": "Используйте свой логин и пароль для входа в Uzum Business",
    "Scan-the-QR-code-to-download-the-Uzum-Business-app": "Отсканируйте QR-код для загрузки{br}приложения Uzum Business",
    "Checked-the-application": "Проверили заявку",
    "Your-company-has-been-liquidated": "Ваша компания ликвидирована",
    "We-will-not-be-able-to-open-an-account": "Не cможем открыть счёт. Если у вас есть действующий бизнес, то свяжитесь с поддержкой.",
    "If-you-have-an-existing-business-then-contact-support": "Если у вас есть действующий бизнес, то свяжитесь с поддержкой.",
    "Hotline": "Горячая линия",
    "Chat-in-telegram": "Чат в телеграм",
    "It-is-not-possible-to-open-an-account-remotely": "Открыть счёт дистанционно не получится",
    "Contact-technical-support-and-we-will-help-you-figure-it-out": "Обратитесь в техническую поддержку — поможем разобраться.",
    "Founder": "Учредитель",
    "Opening-an-account": "Открытие счёта ",
    "Legal-address": "Юридический адрес",
    "Company-address": "Адрес компании",
    "Please-provide-your-actual-address": "Укажите фактический адреc",
    "If-the-address-is-determined-incorrectly-please-indicate-the-correct-one": "Если адрес определился неверно, то укажите правильный",
    "Actual-address": "Фактический адрес",
    "Revenue-in-soums-for-the-year": "Выручка в сумах за год",
    "Indicate-the-approximate-revenue-you-expect": "Укажите примерную выручку, которую вы ожидаете",
    "Service-tariff": "Тариф на обслуживание",
    "Choose-a-plan-that-suits-your-business": "Выберите тариф, который подходит вашему бизнесу.",
    "Select-tariff": "Выбрать тариф",
    "One-of-the-founders-is-not-a-resident-of-the-Republic-of-Uzbekistan": "Один из учредителей не является резидентом РУз",
    "Add-access-to-an-employee": "Добавить доступ сотруднику",
    "Remove-employee": "Удалить сотрудника",
    "Also-takes-part-in": "Принимает участие ещё в {name}",
    "company-count-plural": "нету | {n} компании | {n} компаниях | {n} компаниях",
    "Participates-in-only-one-company": "Принимает участие только в 1 компании",
    "Meeting-point": "Место встречи",
    "How-to-meet-with-a-bank-representative": "Как встретиться с представителем банка",
    "Come-to-me": "Приезжайте ко мне",
    "I-will-come-to-the-bank-branch": "Приеду в отделение банка",
    "Find-out-where-exactly-to-look-for-you": "Уточните, где именно вас искать",
    "Apartment-office": "Квартира/Офис",
    "Entrance": "Подъезд",
    "Floor": "Этаж",
    "Intercom": "Домофон",
    "The-meeting-will-take-no-more-than-15-minutes-when-is-convenient-for-you": "Встреча пройдет не более 15 минут, когда вам удобно?",
    "Bank-Branch": "Отделение банка",
    "In-this-department-you-will-be-able-to-resolve-all-emerging-issues": "В этом отделении сможете решать все возникающие вопросы.",
    "Completely-satisfied": "Полностью удовлетворен",
    "Partially-satisfied": "Удовлетворен частично",
    "Meeting-date-and-time": "Дата и время встречи",
    "The-meeting-will-take-no-more-than-15-minutes": "Встреча пройдет не более 15 минут",
    "There-are-count-documents-available-on-this-case": "Доступно {count} документа по этому делу",
    "Case-category": "Категория дела",
    "Court-name": "Название суда",
    "Plaintiff": "Истец",
    "Defendant": "Ответчик",
    "Basics": "Основное",
    "Government-procurement": "Госзакупки",
    "Court-cases": "Судебные дела",
    "Connections": "Связи",
    "Customer": "Заказчик",
    "Provider": "Поставщик",
    "amount-contract-for-sum": "{amount} контракт на {sum} UZS",
    "Registration-number": "Регистрационный номер",
    "Registration-date": "Дата регистрации",
    "Registration-Center": "Центр регистрации",
    "Cases-involving-a-company": "Дел с участием компании",
    "By-founder": "По учредителю",
    "By-manager": "По руководителю",
    "By-individual-entrepreneur": "По ИП",
    "Founders": "Учредители",
    "OPF": "ОПФ",
    "SOOGU": "СООГУ",
    "Statistics-codes": "Коды статистики",
    "About-registration": "О регистрации",
    "Counterparty": "Контрагент",
    "Category": "Категория",
    "SME": "МСБ",
    "Certificate-number": "Номер сертификата",
    "Start-of-activity": "Начало деятельности",
    "End-of-activity": "Конец деятельности",
    "could-not-upload-try-again": "Не удалось загрузить файл, повторите попытку.",
    "email-not-required": "Электронная почта - необязательно",
    "your-inquiry-is-created": "Заявка на открытие счета создана",
    "manager-will-contact-you-shortly": "В ближайшее время менеджер банка свяжется с клиентом — следите за статусом.",
    "LLC": "OOO",
    "IE": "ИП",
    "Transfers-to-other-banks": "Переводы в другие банки",
    "Payment-fees": "Комиссия за платежи",
    "SWIFT-transfers": "SWIFT переводы",
    "Cash-withdrawal-at-the-cash-desk": "Снятие наличных в кассе",
    "Acquiring": "Эквайринг",
    "Transfers-via-Kapitalbank": "Переводы по Капиталбанк",
    "Supervisor": "Руководитель",
    "search-counterparty-drawer-footer-text": "Данная услуга предоставляется исключительно ООО «Assistant Business Market». АКБ «Капиталбанк» не отвечает за достоверность сведений полученных посредством «Системы проверки контрагентов» и за последствия использования размещенной на ней информации,  а также за возможную потерю или порчу данных, а также другие последствия любого характера, которые могут произойти.",
    "Payment-approved": "Платёж утверждён",
    "Payment-signed": "Платёж подписан",
    "to-account": "на счёт",
    "swift-transfer": "SWIFT - перевод",
    "After-sending-to-bank-payment-will-be-displayed-in-history-section": "После отправки в банк платёж\r\nотобразится в разделе История.",
    "Go-to-history": "Перейти в историю",
    "no-payments-for-sign": "У вас пока нет платежей на подписание",
    "create-new-payment-he-will-be-here-for-sign": "Создайте новый платёж, он отобразится здесь для подписания",
    "if-no-virtual-signature": "Если у Вас нет виртуальной подписи",
    "payments-on-sign": "Платежи на подписании",
    "sign-documents-by-signature": "Подписывайте документы виртуальной подписью или ключом ЭЦП.",
    "do-you-really-want-delete-this-document": "Вы действительно хотите удалить этот документ?",
    "signing": "На подписание",
    "counterparty-empty-founders": "Нет сведений об участии в других компаниях в качестве учредителя или руководителя.",
    "Create-Bank24-report": "Создать отчёт Bank24",
    "counterparty-deal-subtitle": "№ {lotDisplayNo} от {dealDate}",
    "current-payrolls": "Текущие ведомости",
    "payrolls-bank24": "Ведомости из Bank24",
    "Statement-of-documents-posted-for-the-period": "Ведомость проведённых документов за период (xls)",
    "Balance-turnover-statement": "Сальдово-оборотная ведомость (txt)",
    "Statement-of-personal-accounts": "Выписка лицевых счетов (txt)",
    "Statement-of-personal-accounts-extended": "Выписка лицевых счетов расширенная (txt)",
    "Reports-history-of-cards-and-terminals": "Отчёты (История карт и терминалов)",
    "Type-of-statement": "Вид выписки",
    "Terminal-and-card-account": "Терминал и счёт карты",
    "Terminal-UPI": "Терминал (UPI)",
    "Terminal-HUMO": "Терминал (ХУМО)",
    "At-approving-number": "На утверждении {n}",
    "Approved-at": "Утверждено {date} в {time}",
    "Signed-at": "Подписано {date} в {time}",
    "date-idn-or-number": "Дата, ИДН или номер",
    "send-on-account": "На какой счёт отправить",
    "currency-rate-and-sum": "Курс валюты и сумма",
    "currency-rate": "Курс валюты",
    "contract-info": "Информация о контракте",
    "upload-template-we-will-create-docs-automatically": "После заполнения шаблона на компьютере перетащите файл  в окно загрузки. Мы создадим документ(ы) автоматически.",
    "Statements-ibk": {
      "account-statement": "Выписка по счёту (старый образец)",
      "payment-documents": "Платёжные документы (старый образец)",
      "account-statement-for-period": "Выписка по счёту за период (старый образец)",
      "statement-of-documents-processed": "Ведомость проведённых документов за период (старый образец)",
      "certificate-of-account-operation": "Справка о работе счёта (старый образец)",
      "saldo": "Сальдово-оборотная ведомость (старый образец)",
      "statement-of-personal-accounts": "Выписка лицевых счетов (старый образец)",
      "statement-of-personal-accounts-extended": "Выписка лицевых счетов расширенная (старый образец)",
      "terminal": "Терминал и счёт карты (старый образец)",
      "terminal-upi": "Терминал UPI (старый образец)",
      "terminal-humo": "Терминал ХУМО (старый образец)",
      "terminal-report": "Отчёт по терминалам (старый образец)"
    },
    "created-singular": "Создана",
    "pinfl": "ПИНФЛ",
    "currency-amount-and-sum": "Курс валюты и сумма",
    "Not-enough-money-balance-is": "Недостаточно средств. Баланс счета:",
    "x-no-more-y-mb": "{0} не более {1} MB",
    "countries-receiver-bank": "Страны банка получателя перевода",
    "search-by-contry": "Поиск по странам",
    "New-Bank24-report": "Новый отчёт Bank24",
    "These-are-old-reports-transferred-without-changes-from-the-old-personal-account": "Это старые отчёты, перенесённые без изменений из старого личного кабинета.",
    "Loan-repayment-account-if-any": "Счёт для погашения кредита (если есть)",
    "written-off": "Спишется",
    "Sort-type": "Тип сортировки",
    "You-can-select-multiple-terminals": "Можно выбрать несколько терминалов.",
    "Data-will-be-updated-after-sending-to-bank": "Данные будут уточнены после отправки в банк.",
    "Swift-bic-invalid": "Должен содержать 8 или 11 символов",
    "amount-and-source": "Сумма и источник ",
    "will-enroll": "Зачислится",
    "Account-or-card-number": "Номер счёта/карты",
    "more-count": "ещё {n}",
    "conversion-course-will-be-set-while-proceed": "Курс обмена будет установлен во время проведения платежа.",
    "create-document-by-template-failed": "Не удалось создать документ по шаблону",
    "get-sms-code": "Получить код в СМС",
    "cant-get-sms": "Не можете получить СМС?",
    "get-email-code": "Получить код на e-mail",
    "enter-code-from-email": "Введите код из e-mail",
    "we-sent-code-on-email": "Мы отправили код на почту",
    "or": "или",
    "change-phone-guide-no-virtual": "Подтвердите смену номера ключом ЭЦП. \n\nВставьте флешку с ключом в компьютер для того, чтобы продолжить с ключом ЭЦП..",
    "Add-an-employee": "Добавить сотрудника",
    "provided_access_for_partner": "Открыт доступ к аккаунту партнёра",
    "provided_access_details": "Перейдите в аккаунт партнёра, чтобы приглашать клиентов и зарабатывать с банком",
    "provided_access_checkout_to_profile": "Перейти в профиль партнёра",
    "receiver-card-number": "Номер карты получателя",
    "cardholder-name": "Имя владельца",
    "treasury-account-or-name": "Счёт или наименование казначейства",
    "treasury-account-selecting": "Выбор счёта казначейства",
    "payer-info": "Данные плательщика",
    "who-sends": "Кто отправляет",
    "payer-name": "Наименование плательщика",
    "payer-number": "Номер налогоплательщика",
    "payer-address": "Адрес плательщика",
    "sender-bank-name": "Наименование банка отправителя",
    "sender-bank-code": "Код банка отправителя",
    "operation-info": "Информация об операции",
    "imported-documents-amount": "Сумма загруженных документов",
    "plural-payments": "{n} платежей | {n} платёж | {n} платежа | {n} платежей",
    "who-pays-commission": "Кто оплачивает комиссию?",
    "additional-payment-details": "Дополнительные детали платежа",
    "intermediary-bank-if-any": "Банк посредник, если есть",
    "by-swift-code": "По SWIFT-коду",
    "manually": "Вручную",
    "for-period-from-to": "За период от {0} до {1}",
    "from-date": "За {0}",
    "sign-with-signature-key": "Подписание с помощью ЭЦП ключа",
    "total-sum-payments": "Общая сумма платежей",
    "total-amount-payments": "Количество платежей",
    "select-intermediary-bank": "Выбор банка посредника",
    "intermediary-bank": "Банк посредника",
    "bank-name-swift-code-or-address": "Наименование банка, SWIFT-код или адрес",
    "swift-code": "SWIFT-код",
    "intermediary-bank-country": "Страна банка посредника",
    "select-intermediary-bank-country": "Выбор страны банка посредника перевода",
    "search-by-number-name-or-code": "Поиск по номеру, наименованию или коду",
    "bic-code": "БИК код",
    "intermediary-name": "Наименование посредника",
    "receiver-bank-account": "Счёт в банке получателя",
    "receiver-bank-country": "Страна банка получателя перевода",
    "select-receiver-bank-country": "Выбор страны банка получателя перевода",
    "signed-payments-amount": "Количество подписанных платежей",
    "not-signed-payments-amount": "Количество не подписанных платежей",
    "payments": "Платежи",
    "signed": "подписаны",
    "some-payments-not-signed-try-again": "Некоторые платежи остались без подписи. Попробуйте подписать ещё раз.",
    "approved": "утверждены",
    "approved-payments-amount": "Количество утверждённых платежей",
    "not-approved-payments-amount": "Количество не утверждённых платежей",
    "receiver-bank-name-and-address": "Наименование и адрес банка получателя",
    "approve-with-signature-key": "Утверждение с помощью ЭЦП ключа",
    "select-all-payments": "Выбрать все платежи",
    "select-all-payments-on-current-page": "Выбрать все платежи на текущей странице",
    "cancel-select": "Отменить выбор",
    "currency-operation-code": "Код валютной операции",
    "swift-sha": "SHA – Получатель и плательщик",
    "swift-our": "OUR — Плательщик",
    "Almost-ready-lets-complete-the-check": "Почти готово, завершаем проверку",
    "receiver-name-and-address": "Наименование и адрес получателя",
    "correspondent-account": "Корреспондентский счёт",
    "receiver-kpp": "КПП получателя",
    "Show-other-rates": "Показать остальные тарифы",
    "Hide-other-rates": "Скрыть остальные тарифы",
    "not-signed": "Не подписаны",
    "not-approved": "Не утверждены",
    "all-payments-not-signed-try-again": "Все платежи остались без подписи. Попробуйте подписать ещё раз.",
    "your-signature-expired": "Ваш ключ ЭЦП устарел",
    "go-ask-bank": "Обратитесь в банк.",
    "ask-bank": "Обратиться в банк",
    "enter-zero-for-receiver-outside-russia": "Введите значение 0 для получателя вне РФ",
    "counterparty-deals-not-found": "Нет сведений об участии в госзакупках в качестве {name}.",
    "by-customer": "заказчика",
    "by-provider": "поставщика",
    "If-you-need-to-reschedule-meeting-please-contact-support-by-phone-or-telegram": "Если нужно перенести встречу, то обратитесь в службу поддержки по телефону или в телеграм",
    "What-you-need-to-have-with-you": "Что нужно иметь при себе",
    "Dont-forget-to-prepare-the-documents-from-the-list-so-that-the-meeting-goes-successfully-and-as-quickly-as-possible": "Не забудьте подготовить документы из списка, чтобы встреча прошла успешно и максимально быстро.",
    "Everything-worked-out-see-you-soon": "Всё получилось, скоро увидимся",
    "On-the-appointed-day-we-will-call-and-remind-you-about-the-meeting": "В назначенный день позвоним и напомним о встрече.",
    "party-id": "Идентификатор стороны",
    "We-could-not-get-through-to-you": "Не смогли до вас дозвониться",
    "Contact-bank-employees-by-phone-or-telegram-to-continue-opening-an-account": "Свяжитесь с сотрудниками банка по телефону или в телеграм, чтобы продолжить открытие счёта.",
    "An-additional-meeting-was-scheduled": "Назначили дополнительную встречу",
    "Edit-access": "Изменить доступ",
    "Add-access": "Добавить доступ",
    "symbols": "символов | символ | символа | символов",
    "Access-to-payments": "Доступ к платежам",
    "Select-terminal": "Выберите терминал",
    "Contract-offer": "Договор оферты",
    "Select-date-and-time": "Выбрать дату и время",
    "Select-a-meeting-option": "Выберите вариант встречи",
    "Select-this-date-and-time": "Выбрать эту дату и время",
    "First-half-of-the-day": "Первая половина дня 10:00 - 13:00",
    "Second-half-of-the-day": "Вторая половина дня 13:00 - 16:00",
    "Select-a-meeting-date": "Выберите дату встречи",
    "Select-a-meeting-time": "Выберите время встречи",
    "Company": "Компания",
    "Meeting": "Встреча",
    "Select-the-expected-revenue-for-the-year": "Выберите преполагаемую выручка за год",
    "Select-a-tariff": "Выберите тариф",
    "Service-rates": "Тарифы на обслуживание",
    "Status-code": "Статус код",
    "The-meeting-was-a-success": "Встреча прошла успешно",
    "We-prepare-documents-and-prepare-the-account-for-opening-this-takes-no-more-than-one-working-day": "Мы оформляем документы и готовим счёт к открытию — это занимает \nне более 1 рабочего дня. ",
    "sum-currency": "Сум",
    "number-of": "{start} из {end}",
    "next": "Дальше",
    "could-not-get-manager": "К вам не прикреплен менеджер",
    "recipient-bank-copied-successfully": "Банк получателя успешно скопирован",
    "failed-to-copy-recipient-bank": "Не удалось скопировать банк получателя",
    "symbols-count": "{n} символов | {n} символ | {n} символа | {n} символов",
    "munis-modal-description": "В соответствии с требованием Государственного таможенного комитета Республики Узбекистан с 13.08.2024 года все платежи в пользу таможенных органов необходимо проводить с использованием системы МУНИС с указанием кода ГТК в соответствии со справочником. Проведение платежей МУНИС доступно с 9:00 до 16:30.",
    "customs-code-directory": "Справочник кодов ГТК: ",
    "City": "Город",
    "Street-and-house": "Улица и дом",
    "If-you-have-a-chief-accountant-and-he-is-on-the-company-staff-then-add-access-to-him": "Если у вас есть главный бухгалтер, и он оформлен в штат компании, то добавьте ему доступ.",
    "my-money": "Мои деньги",
    "today": "Сегодня",
    "finance-info": "Финансовые потоки",
    "expenditure": "Расход",
    "cards": "Карты",
    "invite_to_partnership": "Приглашайте клиентов и зарабатывайте до 400 000 сум за каждого.",
    "become_bank_partner": "Станьте партнёром банка",
    "partnership-reward": "Вознаграждение до 400 000 сум за каждого клиента, который откроет счёт в банке",
    "partnership-interest": "30% от доходности банка за расчётно-кассовое обслуживание",
    "partnership-online": "Оформление онлайн или при личной встрече в удобном для вас месте",
    "partnership-account": "Доступ в личный кабинет для работы с клиентами и подсчёта прибыли",
    "partnership-go-to-site": "Подробнее на сайте",
    "after_inquiry_we_will_answer": "После оформления заявки c вами свяжется менеджер банка и ответит на все оставшиеся вопросы.",
    "name-and-family-name": "Имя и Фамилия",
    "organization_tin": "ИНН организации",
    "partnership_inquiry": "Заявка на партнерство",
    "partnership-inquiry-sent": "Заявка отправлена",
    "couldnt-create-inquiry-try-again-later": "Не удалось создать заявку, попробуйте позже",
    "account-name": "Наименование счёта",
    "exchange-rate-for": "Курс валют на"
  },
  "uz": {
    "workWithDocumentsTitle": "Hujjatlar bilan ishlash",
    "tax": "Soliq",
    "print": "Chop etish",
    "importDocuments": "Hujjatlarni import qilish",
    "override": "Bekor qilmoq",
    "createDocumentByTemplate": "Shablon yordamida hujjat yaratmoq",
    "numberId": "ID raqami",
    "documentDate": "Hujjat sanasi",
    "templateName": "Shablon nomi",
    "signDK": "D/K belgisi",
    "createDocumentCopy": "Hujjatning nusxasini yarating",
    "debit": "Debet",
    "credit": "Kredit",
    "other": "Boshqa",
    "senderInn": "Yuboruvchining STIR",
    "senderName": "Yuboruvchining ismi",
    "receiverName": "Qabul qiluvchining ismi",
    "unpostedBalance": "Joylanmagan balans",
    "accountBalance": "Hisob balansi",
    "selectCard": "Karta raqami",
    "replenishmentTypeCard": "Kartaga",
    "treasuryAccount": "G‘aznachilik hisobi {0}",
    "findTreasuryAccount": "G'azna hisobini toping",
    "budgetReceiverAccount": "Budjet oluvchi hisobi {0}",
    "inn": "STIR",
    "senderInnOrPinfl": "Yuboruvchining STIR yoki JShShIR",
    "receiverInnOrPinfl": "Qabul qiluvchi bankining STIR",
    "findBudgetReceiverAccount": "Byudjet oluvchining hisobini toping",
    "sum": "Miqdori",
    "paymentDestinationCode": "To'lov maqsadi kodi",
    "sumInWords": "Kuirsivdagi suma",
    "printDocument": "Hujjat raqami{n}",
    "copiesNumber": "Nusxalar soni:",
    "printCopies": "nusxalari yo'q | {n} nusxa | {n} nusxa | {n} nusxa",
    "budgetReceiversSearch": "Byudjet oluvchilarni qidiring",
    "enterAccountOrInnOrName": "Hisobni, STIRni yoki byudjet oluvchining ismini kiriting",
    "treasuryReceiversSearch": "G'aznachilik hisoblarini qidiring",
    "treasurySearchLabel": "Hisob, niqob, noyob raqam yoki xazina nomini kiriting",
    "documentNumberHint": "Hujjat raqami avtomatik ravishda yaratiladi. Siz uni o'zgartirishingiz mumkin.",
    "login": "Login ",
    "password": "Parol",
    "forgot-password": "Parolni unutdingizmi?",
    "sign-in": "Tizimga kirish",
    "feedback-form": "Fikr-mulohaza shakli",
    "main-page": "Bosh sahifa",
    "work-with-documents-online-24": "Hujjatlar bilan 24/7 onlayn ishlash",
    "documents": "Hujjatlar",
    "additionally": "Qo'shimcha",
    "reports": "Hisobotlar",
    "letters": "Xatlar",
    "settings": "Sozlamalar",
    "logout": "Chiqish",
    "fromDocumentDate": "Hujjat tuzilgan kundan boshlab",
    "documentType": "Hujjat turi",
    "documentNumber": "Hujjat raqami",
    "byDocumentDate": "Hujjat sanasi bo'yicha",
    "destinationCode": "Belgilangan manzil kodi",
    "filterTemplates": "Shablonlar filtri",
    "success": "Muvaffaqiyatli!",
    "search": "Qidirmoq",
    "clear": "Toza",
    "cancel": "Bekor qilish",
    "find": "Toping",
    "delete": "Oʻchirish",
    "save": "Saqlash",
    "saveAndCreateAnother": "Saqlash va yangisini yaratish",
    "update": "Yangilash",
    "areYouSure": "Ishonchingiz komilmi?",
    "validation": {
      "required": "Maydon boʻsh boʻlishi mumkin emas",
      "min": "Belgilarning minimal soni: {length}!",
      "max": "Maydonda {length} belgidan oshmasligi kerak!",
      "inputFieldRowsCountMax": "Maydonda {length} satrdan oshmasligi kerak",
      "inputFieldRowLengthMax": "{satr} qatori {length} dan oshmasligi kerak",
      "receiverAccountEqualToSenderAccount": "validation.receiverAccountEqualToSenderAccount",
      "receiverAccountNumberInvalid": "MFO yoki hisob noto'g'ri kiritilgan",
      "receiverAccountNumberInvalidLength": "validation.receiverAccountNumberInvalidLength",
      "budgetAccountNumberInvalid": "Hisob raqami 27 ta raqamdan iborat bo'lishi kerak",
      "treasuryAccountNumberInvalid": "Hisob raqami 25 ta raqamdan iborat bo'lishi kerak",
      "innInvalid": "Noto'g'ri STIR",
      "swiftInnInvalid": "STIR 10 yoki 12 belgidan iborat bo'lishi mumkin",
      "pinflInvalid": "Noto'g'ri JShShIR",
      "cardNumberLength": "Karta raqami 16 ta raqamdan iborat bo'lishi kerak",
      "cardNumberInvalid": "Karta raqami noto'g'ri kiritilgan",
      "enterLogin": "Loginni kiriting",
      "enterPassword": "parolni kiriting",
      "invalidLogin": "Noto'g'ri login",
      "invalidPassword": "Noto'g'ri parol",
      "enterOldPassword": "Eski parolni kiriting",
      "enterNewPassword": "Yangi parolni kiriting",
      "repeatEnterNewPassword": "Yangi parolingizni qayta kiriting",
      "confirmPassword": "parollar mos kelmayapti",
      "invalidOtpCode": "Noto'g'ri SMS kodi",
      "firstName": "Ismingizni kiriting",
      "phone": "Telefon raqamingizni kiriting",
      "email": "Elektron pochtani kiriting",
      "invalidEmail": "Noto'g'ri elektron pochta",
      "idnInvalid": "IDN 27 ta raqamdan iborat bo'lishi kerak",
      "inputFieldInvalid": "Maydonda yaroqsiz belgilar mavjud",
      "amountZero": "Miqdor 0 boʻlishi mumkin emas",
      "maximum": "Maksimal belgilar chegarasidan oshib ketdi",
      "invalidFormat": "Noto'gri shakl",
      "numbersOnly": "Maydonda faqat raqamlar bo'lishi kerak",
      "innOrPinflInvalid": "validation.innOrPinflInvalid",
      "minLength": "validation.minLength",
      "receiverAccountMainInvalid": "Maydonda kompaniyaning asosiy hisobi bo'lishi kerak",
      "paymentPurpose": "Toʻlov maqsadi 3 dan 450 tagacha belgidan iborat boʻlishi kerak"
    },
    "branch": "Filial",
    "account": "Hisob",
    "accounts": "Hisoblar",
    "name": "Nomi",
    "dayStartBalance": "Kun boshida muvozanat",
    "dayEndBalance": "Kun oxiridagi muvozanat",
    "volumeDebit": "Aylanma debeti",
    "volumeCredit": "Aylanma kredit",
    "state": "Holat",
    "statementPrevious": "Oldingi kun uchun bayonot",
    "statementCurrent": "Joriy kun uchun ko'chirma",
    "statementPeriod": "Davr uchun bayonot",
    "lastOperationDate": "Oxirgi tranzaksiya sanasi",
    "openingDate": "Ochilish sanasi",
    "closingDate": "Yopilish sanasi",
    "filter": "Filtr",
    "accountOrName": "Hisob yoki ism",
    "accountInformation": "Hisob ma'lumotlari",
    "payrolls": "Ish haqi deklaratsiyasi",
    "payroll": "Ish haqi deklaratsiyasi",
    "details": "Tafsilotlar",
    "sendToBank": "Bankka yuboring",
    "employeesList": "Xodimlar ro'yxati",
    "orderNumber": "Tartib raqam",
    "orderCardNumber": "Kartaning seriya raqami",
    "paymentPurpose": "Tolovnoma tayinlash",
    "year": "Yil",
    "month": "Oy",
    "loadDate": "Yuklab olingan sana",
    "file": "Fayl",
    "fileType": "Fayl turi",
    "payrollsFilter": "Hisobotlarni ish haqi bo'yicha filtrlang",
    "for": "Orqada",
    "description": "Tavsif",
    "mainInformation": "Asosiy ma'lumotlar",
    "unloadTemplateFile": "Shablon faylini yuklang",
    "uploadFile": "Faylni yuklash",
    "unloadFileForPrint": "Chop etish uchun varaqni yuklang",
    "statement": "Vedomost",
    "encoding": "Kodlash",
    "user": "Foydalanuvchi",
    "totalSumPayroll": "Hisobotning umumiy miqdori",
    "employeeAccount": "Xodimlar hisobi",
    "employeeName": "Xodimning to'liq ismi",
    "embossedName": "Naqshli ism",
    "swiftAbbr": "SWIFT",
    "resetFilter": "Filtrni tiklash",
    "add": "Qo'shish",
    "attachFiles": "Fayllarni biriktiring",
    "saveAsTemplate": "Shablon sifatida saqlash",
    "paymentDocument": "To'lov hujjati",
    "messageType": "Xabar turi (MT)",
    "senderBank": "Yuboruvchining banki",
    "swift": {
      "senderBicCode": "Yuboruvchining BIC kodi (52A)",
      "senderBank": "Yuboruvchi banki (52A)",
      "receiverBank57A": "Qabul qiluvchining banki (57A)",
      "intermediaryBank56A": "Vositachi bank&nbsp;(56A)",
      "intermediaryBankCountry": "Vositachi bank mamlakati&nbsp;({field})",
      "receiverBankCountry": "Qabul qiluvchi bank mamlakati&nbsp;({field})",
      "valueDate": "Qiymat sanasi&nbsp;(32A)",
      "amount": "Oʻtkazma summasi&nbsp;(32A)",
      "currency": "O'tkazma valyutasi&nbsp;(32A)",
      "sender50A": "Yuboruvchining ismi va manzili (50A)",
      "senderAccount": "Yuboruvchining hisobi (50A)",
      "intermediaryBank": "Vositachi bank&nbsp;(56)",
      "receiverBank": "Qabul qiluvchining banki(57)",
      "intermediaryBankName": "Vositachi bank nomi&nbsp;(56A)",
      "receiverAccount": "Qabul qiluvchining hisobi&nbsp;(59K)",
      "receiver59A": "Qabul qiluvchining ismi va manzili (59A)",
      "remittanceInformation": "Toʻlov tafsilotlari&nbsp;(70)",
      "chargePayer": "Xarajat tafsilotlari&nbsp;(71A)",
      "beneficiaryCorrespondentBank": "Benefisiar bankining korrespondent banki (56A)",
      "bicCodeField": "BIC kodi&nbsp;({maydon})",
      "receiverBankName": "Qabul qiluvchi bankning nomi (57A)",
      "bankDataField": "{field} - Bank tafsilotlari",
      "bankDataNameAndAddressField": "{field} - Bank tafsilotlari - Ism va manzil",
      "nameAndAddressField": "Bank nomi va manzili&nbsp;({field})",
      "BEN": "BEN oluvchi",
      "OUR": "Bizning jo'natuvchimiz",
      "SHA": "SHA ikkalasi",
      "operation": {
        "type1": "1 - Import",
        "type2": "2 - Kredit kafolatlangan",
        "type3": "3 - Kafolatsiz kredit",
        "type4": "4 - Repatriatsiya",
        "type5": "5 - Boshqalar",
        "type6": "6 - Eksport",
        "type7": "7 - Aniqlik kiritilgunga qadar hisobda"
      },
      "prepaymentForGoods": "10100 - Tovar uchun oldindan to'lovi",
      "paymentForGoods": "10200 - Tovar uchun to'lov",
      "prepaymentForServices": "20100 - Xizmatlar uchun oldindan to'lov",
      "paymentForServices": "20200 - Xizmatlar uchun to'lov",
      "other": "99090 - Boshqalar",
      "currencyOperationCode": "Valyuta tranzaksiya kodi&nbsp;(70)",
      "bicNumber": "Raqam (BIC)",
      "correspondentAccountNumber": "Korrespondent hisob&nbsp;(57D)",
      "receiverKpp": "Qabul qiluvchining nazorat punkti"
    },
    "receiverBank": "Oluvchining banki",
    "senderCountry": "Yuboruvchi mamlakat",
    "receiverCountry": "Qabul qiluvchi mamlakat",
    "comment": "Izoh",
    "swiftFilter": "SWIFT filtri",
    "newSwiftTransfer": "Yangi SWIFT transferi",
    "operationType": "Tranzaktsiya turi",
    "senderAccount": "Yuboruvchining hisobi",
    "receiverAccount": "Qabul qiluvchining hisobi",
    "receiver": "Qabul qiluvchi",
    "senderToReceiverInformation": "Qo'shimcha to'lov tafsilotlari (72B)",
    "idn": "IDN {n}",
    "paymentTerms": "To'lov shartlari",
    "transactionData": "Operatsiya tafsilotlari",
    "template": "Namuna",
    "templates": "Shablonlar",
    "templateComment": "Shablon haqida fikr bildiring",
    "sender": "Yuboruvchi",
    "bankCorrespondent": "Bank - muxbir",
    "correspondentBank": "Korrespondent bank (maydon:&nbsp;56D)",
    "months": {
      "january": "Yanvar",
      "february": "Fevral",
      "march": "Mart",
      "april": "Aprel",
      "may": "May",
      "june": "Iyun",
      "july": "Iyul",
      "august": "Avgust",
      "september": "Sentyabr",
      "october": "Oktyabr",
      "november": "Noyabr",
      "december": "Dekabr"
    },
    "detailedInformation": "batafsil ma'lumot",
    "parameters": "Variantlar",
    "extractOnTheDate": "Sana bo'yicha bayonot",
    "accountMask": "Hisob maskasi",
    "sorting": "Tartiblash",
    "MFO": "MFO",
    "startingDate": "Boshlanish sanasi",
    "endDate": "Yakuniy sana",
    "turnoverDebit": "Aylanma debeti",
    "turnoverCredit": "Tovar aylanmasi krediti",
    "edit": "Tahrirlash",
    "Upload-the-report-to-t": "Hisobotni t-ga yuklash",
    "Print-the-report": "Hisobotni chop etish",
    "Employee-filter": "Xodimlar uchun filtr",
    "documentsInBank": "Bankdagi hujjatlar",
    "Document_templates": "Hujjat shablonlar",
    "Do-you-want-to-leave": "Haqiqatan ham ketishni xohlaysizmi? Sizda saqlanmagan o'zgarishlar bor!",
    "document": "Hujjat #{0}",
    "documentHistory": "Hujjat harakati tarixi",
    "Modal-to-protect-the-entered-data": "Xodimning kartasining seriya raqami",
    "income": "income",
    "soato": "SOATO",
    "uniqueAccountNumber": "Noyob hisob raqami",
    "treasuryName": "G'aznachilik nomi",
    "Search-for-templates": "Shablonlarni qidiring",
    "templateDetails": "Shablon tafsilotlari",
    "newTemplate": "Yangi shablon",
    "numberAndContractDate": "Shartnoma raqami va sanasi",
    "idnContract": "Shartnoma IDN",
    "number": "Raqam",
    "date": "Sana",
    "failedToSign": "Imzolanmadi",
    "failedToDelete": "Oʻchirib boʻlmadi",
    "selectAccountToCreatePaymentOrder": "To'lov topshirig'ini yaratish uchun hisobni tanlang",
    "salaryPayrollSentFromAnotherBank": "Ish haqi uchun buyurtma boshqa bankdan yuborilgan",
    "transitAccountForSalary": "Ish haqi uchun tranzit hisobi",
    "clientAccount": "Mijoz hisobi",
    "paymentOrderNumber": "Buyurtma raqami",
    "transitAccountBalance": "Tranzit hisob balansi",
    "payrollAmount": "Hisobot miqdori",
    "paymentPurposeNote": "*Izoh: to'lovni amalga oshirishda shartnoma raqami, ish haqi muddati, to'lov nomi, ko'chirma raqami ko'rsatilishi kerak. Aks holda, shaxsiy kompyuteringizga kredit qayta ishlanmaydi.",
    "sendPayroll": "Bayonot yuborish",
    "sendPayrollToBank": "Hisobotni bankka yuboring",
    "Support-service-24-7": "Qo'llab-quvvatlash xizmati 24/7",
    "Login": {
      "title": "Hisobingizga kiring",
      "desc": "Bank filialida berilgan login va parolni kiriting."
    },
    "All-documents": "Barcha hujjatlar",
    "All-accounts": "Barcha hisoblar",
    "auth-form": {
      "title": "Tizimga kirish"
    },
    "Amount": "so'm",
    "Show-all": "hammasini ko'rsatish",
    "newDocument": "Yangi hujjat",
    "General-information": "Umumiy ma'lumot",
    "List-of-banks": "Banklar ro'yxati",
    "Payment-destination-codes": "To'lov maqsadi kodlari",
    "References": "Kataloglar",
    "Code": "Kod",
    "Code-or-name": "Kod yoki ism",
    "Sending-a-payment-via-the-Online-24-7-service": "24/7 Onlayn xizmati orqali to'lovni jo'natish",
    "The-commission-for-online-payment-24-7-may-vary": "24/7 onlayn to'lovlar uchun to'lovlar farq qilishi mumkin.",
    "exchangeRates": "Valyuta kurslari",
    "currentDate": "Joriy sana",
    "businessDay": "Operatsiya kuni",
    "businessDayState": "Operatsion holati. kun",
    "contacts": "Kontaktlar",
    "Password-change": "Parolni o'zgartirish",
    "Change-password": "Parolni o'zgartirish",
    "Old-password": "Eski parol",
    "New-password": "Yangi parol",
    "Repeat-password": "Parolni qayta kiriting",
    "The-password-has-been-changed": "Parol o'zgartirildi",
    "Enter-the-code-from-the-SMS": "SMS-dan kodni kiriting",
    "We-sent-an-SMS-with-a-code-to-the-number": "Biz raqamga kod bilan SMS yubordik",
    "The-code-from-the-SMS": "SMS dan kod",
    "You-can-repeat-the-sending-via": "orqali qayta yuborishingiz mumkin",
    "Plural-after-second": "{n} soniya | {n} soniya | {n} soniya | {n} soniya",
    "Repeat": "Takrorlang",
    "accountStatement": "Hisob qaydnomasi",
    "byDocumentNumber": "Hujjat raqami bo'yicha",
    "byAccounts": "Hisob-kitoblarga ko'ra",
    "byDebit": "Debet aylanmasi bo'yicha",
    "byCredit": "Kredit aylanmasi bo'yicha",
    "htmlReport": "HTMLda yuklash",
    "rtfReport": "RTF da yuklash",
    "pdfReport": "PDF da yuklash",
    "wordReport": "Word da yuklash",
    "excelReport": "Excel da yuklash",
    "accountStatementForPeriod": "Davr uchun hisobdan ko'chirma",
    "all": "Hammasi",
    "debitCreditTurnover": "Aylanish debeti/Aylanish krediti",
    "partiallySigned": "Qisman imzolangan",
    "helpModal": {
      "title": "Agar biror narsa ishlamasa yoki uning qanday ishlashi aniq bo'lmasa, biz bilan bog'laning:",
      "recomendedBrowsersText": "Shaxsiy hisobingizda ishlash uchun quyidagi brauzerlardan birini ishlatishni tavsiya qilamiz:"
    },
    "Employees-card-number": "Xodimning karta raqami",
    "onboardingWelcomeModal": {
      "title": "Ishonchingiz va “Kapitalbank” va “Uzum Business” yuridik shaxslari uchun yangi shaxsiy kabinetga kirganingiz uchun tashakkur.",
      "recomendedBrowsersText": "Yangi shaxsiy hisob har qanday zamonaviy brauzerda ishlatilishi mumkin:",
      "ieSupport": "Internet Explorer endi qo'llab-quvvatlanmaydi.",
      "advantages": "Yangi shaxsiy hisob yanada funktsional va foydalanish uchun qulay bo'ladi. Barcha funktsiyalar bir xil joylarda qoladi.",
      "changes": "O'zgarishlar \"Hujjatlar bilan 24/7 ishlash\" bo'limiga ta'sir qildi. Endi kunning istalgan vaqtida pul o'tkazmasini jo'natish uchun oddiy to'lov hujjatini yaratish va uni yaratishda 24/7 to'lovlarni jo'natish xizmatidan foydalanishni ko'rsatish kifoya."
    },
    "contactTechnicalSupport": "Agar sizda biron bir savol bo'lsa, texnik yordamga murojaat qilishingiz mumkin:",
    "onboardingHelpButtonText": "Yordam kerak!",
    "To-the-account": "Hisobda",
    "approve": "Tasdiqlash",
    "check": "Tekshirish",
    "mfo": "MFO",
    "currencySum": "Valyutadagi miqdor",
    "totalSum": "Umumiy qiymat",
    "currency": "Valyuta",
    "contractNumber": "Shartnoma raqami",
    "operDate": "Opera sanasi",
    "byPhone": "Telefon orqali",
    "byTelegram": "Telegramda",
    "itIsClear": "Tushunarli",
    "chrome": "Chrome",
    "opera": "Opera",
    "firefox": "Mozilla Firefox",
    "yandexBrowser": "Yandex brauzeri",
    "edge": "Edge",
    "singleContactCenterNumber": "Yagona aloqa markazi raqami:",
    "technicalSupportAndAdditionalInformation": "Texnik yordam va qo'shimcha ma'lumotlar:",
    "userSupportDepartment": "Foydalanuvchilarni qo'llab-quvvatlash bo'limi",
    "workingHours": "Ish vaqti:",
    "resendCode": "Kodni yana yuboring",
    "confirmLogin": "Kirishni tasdiqlang",
    "confirmWithSmsCode": "SMS kodi",
    "confirmWithDigitalSignature": "EDS kaliti",
    "confirmSecurityNote": "Xavfsizlik maqsadida hisobingizni tasdiqlashingiz kerak. Tasdiqlash kodi SMS orqali {n} raqamiga yuboriladi.",
    "confirmWithDigitalSignatureNote": "Agar siz ushbu raqamga kirish imkoniga ega bo'lmasangiz, EDS kaliti yordamida muqobil tasdiqlashni tanlashingiz mumkin.",
    "confirmLoginByDigitalSignature": "Kirishingizni raqamli imzo kalitingiz bilan tasdiqlang",
    "insertDigitalSignatureToken": "Elektron raqamli imzo kalitini kiriting va \"Finish\" tugmasini bosing",
    "done": "Tayyor",
    "createDocumentFromTemplate": "Shablon yordamida hujjat yarating",
    "send": "Yuborish",
    "Contracts": "Shartnomalar",
    "Name-of-the-contract-holder": "Shartnoma egasining nomi",
    "IDN": "IDN",
    "Name-of-the-foreign-partner": "Chet ellik hamkorning nomi",
    "Country-of-the-foreign-partner": "Xorijiy hamkor mamlakati",
    "Contract-number": "Shartnoma raqami",
    "Date-of-conclusion-of-the-contract": "Shartnoma sanasi",
    "Sign-of-contract-activity": "Shartnoma faoliyati belgisi",
    "MFI-code-of-the-authorized-bank/number-of-the-service-center": "Vakolatli bank/servis markazi raqamining MFO kodi",
    "accountNumber": "Hisob raqami",
    "accountStatementExtended": "Shaxsiy hisoblar bo'yicha kengaytirilgan ko'chirma",
    "txtReport": "TXT da yuklash",
    "reportFailed": "Hisobot olinmadi",
    "Temporary-blocked-modal": {
      "title": "Hisobingizni vaqtincha blokladik",
      "subtitle": "Hisobingizni blokdan chiqarish uchun bankning qo'llab-quvvatlash xizmatiga murojaat qiling.",
      "call-to": "Qo'ng'iroq qiling",
      "call-to-text": "Qo'ng'iroq qiling va operatorga kod so'zini ayting. Bank xodimi kirishni masofadan tiklaydi.",
      "write-to": "Yozing",
      "write-to-text": "Bankka yozing va qo'llab-quvvatlash jamoasiga kod so'zini ayting. Bank xodimi kirishni masofadan tiklaydi.",
      "write-to-telegram": "Telegramga yozing"
    },
    "Contract-details": "Shartnoma tafsilotlari",
    "Additional-agreements": "Qo'shimcha shartnomalar",
    "Declarations": "Deklaratsiyalar",
    "Payment": "To'lov",
    "Specifications": "Texnik xususiyatlari",
    "Close": "Yopish",
    "Signature-token-not-found": "EDS kaliti topilmadi",
    "No-signature-token-message": "Raqamli imzo kaliti kompyuterda topilmadi. Raqamli imzo kalitini kompyuterga joylashtiring va qayta urinib ko'ring.",
    "Contact-support-service": "Savollaringiz bo'lsa, qo'llab-quvvatlash xizmatiga murojaat qilishingiz mumkin:",
    "Contract-date": "Shartnoma sanasi",
    "Subject-of-the-contract": "Shartnoma predmeti",
    "Contract-currency": "Shartnoma valyutasi {n}",
    "Contract-amount-in-contract-currency": "Shartnoma summasi shartnoma valyutasida {n}",
    "Serial-number-of-the-addendum": "Qo'shimcha seriya raqami kelishuvlar",
    "Document-status": "Hujjat holati",
    "Contract-currency-rate": "Shartnoma valyuta kursi",
    "Name-of-the-importer-recipient": "Import qiluvchi (qabul qiluvchi) nomi",
    "Customs-regime": "Bojxona rejimi",
    "Contract-currency-code": "Shartnoma valyuta kodi",
    "Total-invoice-value": "Hisob-fakturaning umumiy qiymati",
    "Code-of-the-customs-post": "Bojxona pochta indeksi",
    "Registration-number-of-the-declaration": "Deklaratsiyaning ro'yxatga olish raqami",
    "Date-of-registration-of-the-declaration": "Deklaratsiyani ro'yxatdan o'tkazish sanasi",
    "Country-code-of-departure": "Ketish mamlakat kodi",
    "Code-of-the-nature-of-the-transaction": "Tranzaksiya tabiat kodi",
    "Date-of-shipment-receipt-of-the-goods": "Tovarlarni jo'natish (qabul qilish) sanasi",
    "Name-of-the-exporter-sender": "Eksport qiluvchi (jo'natuvchi) nomi",
    "Contract-Specification-number": "Shartnoma spetsifikatsiya raqami",
    "Attribute-import-export": "Atribut {attribute}",
    "Serial-number-of-the-specification-in-the-EEISVO": "EEISVOdagi spetsifikatsiyaning seriya raqami",
    "Date-and-time-of-data-entry-into-the-EEISVO": "EEISVO ga ma'lumotlarni kiritish sanasi va vaqti",
    "Specification-date": "Spetsifikatsiya sanasi",
    "Specification-amount": "Spetsifikatsiya miqdori",
    "Specification-currency": "Spetsifikatsiya valyuta kodi",
    "Attribute-goods-services": "Atribut {attribute}",
    "Payment-date": "To'lov sanasi",
    "Payment-currency": "To'lov valyutasi",
    "Source-of-currency-funds": "Valyuta mablag'lari manbai",
    "Receipt-amount": "To'lov miqdori",
    "The-amount-of-receipt-for-the-goods-in-the-currency-of-the-contract": "Tovarlar uchun toʻlov miqdori shartnoma valyutasida {n}",
    "The-amount-of-receipt-for-services-works-in-the-currency-of-the-contract": "Shartnoma valyutasida xizmatlar (ishlar) uchun to'lov miqdori {n}",
    "Full-name-of-the-bank-user-who-entered-edited-the-document": "Hujjatni kiritgan (tahrirlagan) bank foydalanuvchisining to'liq ismi",
    "Payment-number-in-ABS": "ABSda to'lov raqami",
    "The-serial-number-of-the-document-in-the-EEISVO": "EEISVOdagi hujjatning seriya raqami",
    "Setting-notifications-for-Telegram": "Telegram uchun bildirishnomalarni sozlash",
    "Additional-Information": {
      "title": "Qo'shimcha ma'lumot",
      "content": "Ushbu ma'lumotlar parolni tiklash, elektron pochta va SMS yuborish uchun ishlatilishi mumkin."
    },
    "First-name": "Ism",
    "Phone": "Telefon",
    "Email": "E-mail",
    "File-name": "Fayl nomi",
    "Imported-documents": "Import qilingan hujjatlar",
    "No-phone-message": "Ro'yxatdan o'tish paytida hisobingiz uchun telefon raqami ko'rsatilmagan yoki noto'g'ri ko'rsatilgan. Siz avtorizatsiyani EDS kaliti bilan tasdiqlashingiz yoki texnik yordamga murojaat qilishingiz mumkin:",
    "Support-service-contacts": "Telefon orqali - {phone}\nTelegramda - {telegram}",
    "Uploaded-files": "Yuklangan fayllar",
    "Generated-documents-according-to-the-file": "Hujjatlar faylga muvofiq shakllantirildi",
    "Payment-documents": "To'lov hujjatlari",
    "System": "Tizim",
    "User-login": "Foydalanuvchi login",
    "User-name": "Foydalanuvchi nomi",
    "IP-address": "IP manzili",
    "Action": "Harakat",
    "Payment-history-modal": {
      "title": "Hujjat bo'yicha harakatlar tarixi №.",
      "unloading-date": "Yuklash sanasi:",
      "unloading-author": "Yuklangan:"
    },
    "Certificate-not-actual-message": "EDS kalitingiz faol emas. \nYangi raqamli imzo kalitini berish uchun siz bank filialiga murojaat qilishingiz kerak.",
    "Certificate-not-belong-to-user-message": "\nKompyuterga kiritilgan raqamli imzo kaliti hisobingizga mos kelmaydi.\nKompyuteringizga to'g'ri raqamli imzo kalitini kiriting va qayta urinib ko'ring.",
    "filters": "Filtrlar",
    "Enter-the-name-or-code-of-the-payment-destination": "To'lov maqsadi nomini yoki kodini kiriting",
    "Back": "Orqaga",
    "Dear-user": "Hurmatli foydalanuvchi!",
    "No-confirm-types-message": "Muvaffaqiyatli avtorizatsiya qilish uchun loginni SMS yoki raqamli imzo orqali tasdiqlashingiz kerak.\n Hisobingizda telefon raqami yoki elektron raqamli imzo sertifikati biriktirilmaganligi sababli siz bankka murojaat qilishingiz kerak.",
    "Bank-news": "Bank yangiliklari",
    "User-manual": {
      "title": "EDS kaliti bilan Internet-banking dasturi bilan ishlash uchun kompyuterni sozlash",
      "tab-new-users": "Yangi foydalanuvchilar uchun",
      "tab-old-users": "pc.bank24.uz foydalanuvchilari uchun"
    },
    "FAQ": {
      "title": "Ko'p so'raladigan savollar",
      "login": "Parol va login savollari uchun",
      "deleted": "Agar to'lov topshirig'i ijro uchun qabul qilinmasa \"O'chirildi\"",
      "rejected": "Agar to'lov topshirig'i ijro uchun qabul qilinmasa \"Rad\"."
    },
    "Contacts-tab": {
      "title": "Kontaktlar",
      "tech-support-phone": "24/7 qo'llab-quvvatlash telefon raqami",
      "tech-support-telegram": "Telegram qo'llab-quvvatlash xizmati 24/7"
    },
    "Swift-details": "SWIFT transferi tafsilotlari",
    "Error": "Xato",
    "message type": "Xabar turi (MT)",
    "Save-changes": "O'zgarishlarni saqlang",
    "cntrTtype": "Shartnoma turi",
    "bankId": "Vakolatli bankning MFO kodi",
    "okpo": "OKPO (pudratchi) kodi",
    "okonx": "OKONH kodi (pudratchi)",
    "contractorUzParentCompany": "Shartnoma egasining yuqori tashkilotining nomi",
    "contractorUzName": "Shartnoma egasining nomi",
    "rgnCode": "Shartnoma egasi hududi",
    "contractorUzAddress": "Shartnoma egasining manzili",
    "cntrSubject": "Shartnoma predmeti",
    "currCode1": "Shartnoma valyutasi 1",
    "currCode2": "Shartnoma valyutasi 2",
    "amount1": "Valyutadagi shartnoma summasi 1",
    "amount2": "Valyutadagi shartnoma summasi 2",
    "accCurrCode1": "Hisob-kitob valyutasi 1",
    "accCurrCode2": "Hisob-kitob valyutasi 2",
    "contractorForName": "Chet ellik hamkorning nomi",
    "contractorForCountryCode": "Xorijiy hamkor mamlakati",
    "contractorForAddress": "Chet ellik sherikning yuridik manzili",
    "bankForAttributes": "Chet ellik hamkorning bank rekvizitlari",
    "productsName": "Tovarlarning umumiy nomi",
    "docNo": "Shartnoma raqami",
    "docDate": "Shartnoma sanasi",
    "execType": "Shartnoma muddati",
    "execDate": "Shartnomani bajarish sanasi",
    "cntrStatus": "Shartnoma faoliyati belgisi",
    "lockDate": "Shartnomani bekor qilish (o'chirish, blokirovka qilish, bajarish) sanasi",
    "cstDate": "Shartnomani bojxona organlarida ro'yxatdan o'tkazish sanasi",
    "contractorUzInn": "Shartnoma egasining STIR",
    "receivableTotal1": "Shartnoma valyutasida debitorlik qarzlari miqdori 1",
    "receivableOverdue1": "Shartnoma valyutasida muddati o'tgan debitorlik qarzlarining summasi 1",
    "receivableTotal2": "Shartnoma valyutasida debitorlik qarzlari miqdori 2",
    "receivableOverdue2": "Shartnoma valyutasida muddati o'tgan debitorlik qarzlarining summasi 2",
    "payableTotal1": "Shartnoma valyutasida kreditorlik qarzlari summasi 1",
    "payableOverdue1": "Shartnoma valyutasida muddati o'tgan kreditorlik qarzlari summasi 1",
    "payableTotal2": "Shartnoma valyutasida kreditorlik qarzlari summasi 2",
    "payableOverdue2": "Shartnoma valyutasida muddati o'tgan kreditorlik qarzlari summasi 2",
    "insTime": "EEISVO ga ma'lumotlarni kiritish sanasi va vaqti",
    "noContractData": "Ushbu shartnoma uchun hech qanday ma'lumot topilmadi",
    "status": "Holat",
    "Failed-to-check": "Tekshirib bo‘lmadi",
    "Failed-to-approve": "Tasdiqlab bo‘lmadi",
    "Failed-to-override": "Bekor qilish amalga oshmadi",
    "Download-file": "Faylni yuklash",
    "Download-failed": "Fayl qabul qilinmadi",
    "Contract-number-additional": "Qo'shimcha shartnoma raqami",
    "Contract-date-additional": "Qo'shimcha kelishuv sanasi",
    "Subject-of-the-contract-additional": "Qo'shimcha shartnoma mavzusi",
    "contractorUzPhone": "Shartnoma egasining telefon raqami",
    "bankForName": "Chet ellik hamkor bankining nomi",
    "bankForCountryCode": "Xorijiy hamkor bank mamlakati",
    "bankForAddress": "Chet ellik hamkor bankining yuridik manzili",
    "Instruction": "Ko'rsatmalar",
    "Swift-user-instruction": "SWIFT o'tkazmasini yaratish bo'yicha foydalanuvchi ko'rsatmalari",
    "Currency-exchange": {
      "confirm-modal": {
        "content-text": "Haqiqatan ham ilovangizni oʻchirib tashlamoqchimisiz?"
      }
    },
    "Currency-conversion": {
      "page-title": "Yangi talabnoma",
      "details-page-title": "Konversiya talabnomasi tafsilotlari",
      "form": {
        "currency-from": "Hisobdan chiqarilishi kerak bo'lgan valyuta",
        "currency-to": "To'ldirish uchun valyuta",
        "account-from": "Hisobni o'chirish",
        "account-to": "Hisobni to'ldirish",
        "account-from-balance": "Debet hisobvarag'idagi qoldiq",
        "account-to-balance": "Hisob balansini to'ldirish",
        "required-amount-currency": "To'ldirish uchun valyutada talab qilinadigan miqdor",
        "purpose": "Maqsad",
        "approved-amount": "Tasdiqlangan miqdor"
      }
    },
    "UZS amount": "So‘m miqdorida",
    "Back-to-list": "Roʻyxatga qaytish",
    "Sign-and-send": "Imzolang va yuboring",
    "Change": "O'zgartirish",
    "Code-for-support": "Qo'llab-quvvatlash kodi",
    "Creation-date": "yaratilgan sana",
    "Payment-details": "To'lov tafsilotlari",
    "Organization-name": "Tashkilot nomi",
    "Bank-branch": "MFO banki",
    "Requirement-details": "Talab tafsilotlari",
    "Payment-type": "To'lov turi",
    "Nonpayment-code": "To'lovsiz kod",
    "Destination-code": "Belgilangan manzil kodi",
    "removed payment requirement records": "Toʻlov soʻrovi muvaffaqiyatli oʻchirildi",
    "did not remove payment requirement records": "Toʻlov soʻrovini oʻchirib boʻlmadi",
    "Exchange-rate": "Valyuta kursi",
    "Rejection-reason": "Rad etish sababi",
    "Reject-reason": "Rad etish sababi",
    "Extend-reason": "Uzatilish sababi",
    "Partial-payment-reason": "Qisman to'lovning sababi",
    "Payment-request": "To'lov talabi",
    "Payment-requests": "To'lov talablari",
    "Payment-request-number": "Toʻlov soʻrovi raqami.{n}",
    "Payment-destination-code-for-unpaid-documents": "To'lanmagan hujjatlar uchun maqsad kodi",
    "Failed-to-remove": "Oʻchirib boʻlmadi",
    "Are-you-sure-to-remove-payment-requirement": "Toʻlov soʻrovini oʻchirib tashlamoqchimisiz?",
    "Are-you-sure-to-remove-payment-requirements": "Toʻlov soʻrovlarini oʻchirib tashlamoqchimisiz?",
    "Payment-requirement-removing-warning": "Toʻlov soʻrovi oʻchirilgandan soʻng uni qayta tiklay olmaysiz.",
    "Payment-requirements-removing-warning": "Toʻlov soʻrovlari oʻchirilgandan soʻng ularni qayta tiklay olmaysiz.",
    "no payment reqs for sign": "Imzolashda to'lov talab qilinmaydi",
    "payment reqs for sign will appear here": "Imzolash uchun to'lov talablari ushbu sahifada paydo bo'ladi.",
    "create payment req": "To'lov so'rovini yarating",
    "Create": "Yaratmoq",
    "InnOrPinfl": "STIR yoki JShShIR",
    "PaymentCodeForUnpaidDoc": "To'lanmagan hujjatlar uchun to'lovning maqsadi",
    "Amount-can-not-be-equal": "Miqdor {amount} ga teng boʻlishi mumkin emas",
    "Successfully-checked-number": "Muvaffaqiyatli tasdiqlandi: {n}",
    "Failed-to-check-number": "Tekshirib bo‘lmadi: {n}",
    "Successfully-approved-number": "Muvaffaqiyatli tasdiqlandi: {n}",
    "Failed-to-approve-number": "Tasdiqlab bo‘lmadi: {n}",
    "Only allowed chars": "Maydonda {n} ta belgi boʻlishi kerak",
    "Failed-to-sign-number": "Imzolab bo‘lmadi: {n}",
    "Successfully-signed-number": "Muvaffaqiyatli imzolandi: {n}",
    "Document-sending-date": "Hujjat yuborilgan sana",
    "No-payment-reqs-in-card-index-second": "2-karta indeksida to'lov talablari yo'q",
    "No-payment-reqs-in-card-index-second-will-appear": "2-karta indeksidan to'lov so'rovlari ushbu sahifada paydo bo'ladi.",
    "Test-text": "TEST MATNI",
    "No-payment-reqs-in-card-index-first": "1-karta indeksida to'lov talablari yo'q",
    "No-payment-reqs-in-card-index-first-will-appear": "1-karta faylidan toʻlov soʻrovlari ushbu sahifada paydo boʻladi.",
    "Please-confirm": "Iltimos, tasdiqlang",
    "card-index-first": "Karta indeks 1",
    "card-index-second": "Karta indeks 2",
    "waiting-for-approval": "Harakat kutilmoqda",
    "Pay": "To'lash",
    "Partial-pay": "Qisman to'lash",
    "Reject": "Rad etish",
    "Reason": "Sabab",
    "Requirement-amount-value": "So‘rov miqdori: {amount} {currency}",
    "Extend": "Uzatmoq",
    "Partial-pay-success": "Qisman toʻlov muvaffaqiyatli amalga oshirildi.",
    "download-template": "Shablonni yuklab oling",
    "check-template": "Ma'lumotlarni tekshiring",
    "upload-template": "Tugallangan shablonni yuklab oling",
    "documents-import": "Hujjatlarni import qilish",
    "Insert-token-to-do-this-action": "Hujjat bilan ushbu amalni bajarish uchun raqamli imzo kalitini joylashtiring va sahifani yangilang",
    "choose-file-or-drag": "Faylni tanlang yoki bu yerga sudrab tashlang",
    "xlxs-no-more-20mb": "XLSX 20 MB dan oshmaydi",
    "file size should be less than 20mb": "Fayl hajmi 20 MB dan oshmasligi kerak.",
    "choose file to proceed": "Faylni tanlang",
    "Specify-payment-requirement-reject-reason": "To'lov talabini to'lashdan bosh tortish sababini ko'rsating.",
    "Requirement-reject-success": "So‘rov muvaffaqiyatli rad etildi.",
    "Pay-success": "Toʻlov muvaffaqiyatli boʻldi.",
    "Pay-failed": "Toʻlov amalga oshmadi.",
    "create-payment-claim": "To'lov so'rovini yarating",
    "closed-payment-requirements": "Yopiq",
    "Document-create-date": "Hujjat yaratilgan sana",
    "currency-conversion-title": "Sotib olish/sotish uchun ariza. valyutalar",
    "try-again-later": "Xato! keyinroq urinib ko'ring",
    "get-back-to-import": "Import sahifasiga qaytish",
    "remove-error-and-go": "Yuklanmaganlarni o'chiring va davom eting",
    "cancel-import": "Yuklashni bekor qilish",
    "download-template-for-upload": "Yuklab olish uchun shablonni yuklab oling",
    "template-will-be-automatically-downloaded": "Hujjat shablonlari avtomatik ravishda kompyuteringizga yuklab olinadi. Bu mutlaqo xavfsiz. Shablonni Excel yoki boshqa elektron jadval muharririda ochishingiz mumkin.",
    "continue": "Davom eting",
    "upload-ready-template": "Tugallangan shablonni yuklab oling",
    "upload-template-we-will-create-docs": "Shablonni kompyuteringizda to'ldirgandan so'ng, faylni yuklash oynasiga torting.",
    "unfortunately-we-couldnt-create-from-your-file": "Kechirasiz, faylingizdan toʻlovlarni yuklay olmadik.",
    "probably-you-sent-incorrect-file": "Siz toʻliq boʻlmagan faylni yuborgan boʻlishingiz mumkin. Iltimos, boshqa fayl yuklashga harakat qiling.",
    "check-files": "Yuklab olingan to'lovlar to'g'ri ekanligini tekshiring",
    "if-you-found-mistakes": "Agar xato topsangiz, yaratilgandan so'ng hujjatlar bilan ishlash bo'limida to'lovni tahrirlashingiz mumkin.",
    "documents-uploaded": "Yuklangan hujjatlar",
    "payment-requirements": "To'lovlarni",
    "payment-req": "To'lov",
    "are-you-sure-you-want-to-cancel-upload": "Haqiqatan ham hujjatni yuklab olishni bekor qilmoqchimisiz?",
    "not-able-to-restore": "Ma'lumotlarni qayta tiklash imkonsiz bo'ladi. Keyingi safar hujjat yana import qilinishi kerak.",
    "couldnot-upload-properly": "Afsuski, ba'zi hujjatlarni to'g'ri yuklab bo'lmadi",
    "partly-incorrect": "Ba'zi maydonlar etishmayotgan yoki noto'g'ri ma'lumotlar kiritilgan. Muvaffaqiyatli yuklab olingan toʻlovlarni yaratishni davom ettirishingiz yoki importga qaytishingiz mumkin.",
    "couldnot-store": "Yuklab bo‘lmadi",
    "show-mistakes": "Xatolarni ko'rsatish",
    "paymentNumber": "Hujjat raqami",
    "senderAccountNumber": "To'lovchining hisobi",
    "receiverBranch": "MFO banki",
    "receiverAccountNumber": "Qabul qiluvchining hisobi",
    "receiverInn": "Qabul qiluvchining STIR",
    "amount": "Miqdor",
    "paymentPurposeCode": "To'lov maqsadi kodi",
    "No-closed-payment-reqs-for-sign": "Imzolashda yopiq toʻlov talablari yoʻq",
    "No-closed-payment-reqs-in-card-index-first": "1-karta indeksida yopiq to‘lov so‘rovlari mavjud emas",
    "No-closed-payment-reqs-in-card-index-second": "2-karta indeksida yopiq to‘lov so‘rovlari mavjud emas",
    "No-closed-payment-reqs": "Hech qanday yopiq to'lov talablari yo'q",
    "Payment-reqs-will-appear-here": "Yopiq to'lov so'rovlari ushbu sahifada paydo bo'ladi",
    "Extend-payment-date": "To'lov muddatini uzaytirish",
    "Extend-payment-date-description": "To'lov muddati uzaytiriladi.",
    "amount_left": "Qolgan narsa - to'lash",
    "Chars max length": "Belgilarning maksimal soni {n} ta belgidan oshmasligi kerak",
    "Step-number": "{total} dan {current}-qadam",
    "Confirm-number": "Raqamni tasdiqlang",
    "Confirm-number-change-with-token-key-text": "Raqam o'zgarishini raqamli imzo kaliti bilan tasdiqlang.   Fleshli drayverni kalit bilan kompyuterga joylashtiring va \"Raqamni tekshirish\" tugmasini bosing.",
    "card-index-first-details-title": "Karta fayli 1 - №{ n }",
    "card-index-second-details-title": "Karta fayli 2 - №{ n }",
    "Date-of-review": "Ko'rib chiqish sanasi",
    "Date-of-expected-payment": "Kutilayotgan to'lov sanasi",
    "Token-key-not-found": "Biz kompyuterda raqamli imzo kalitini topa olmadik",
    "Insert-token-key-and-try-again": "Kalitni kompyuteringizga joylashtiring va qaytadan urinib ko'ring.",
    "Insert-correct-token-key-and-try-again": "Kompyuterga to'g'ri raqamli imzo kalitini kiriting va qayta urinib ko'ring.",
    "Token-key-not-match": "Elektron raqamli imzo kaliti hisob qaydnomasiga mos kelmaydi",
    "Enter-new-phone-number": "Yangi raqam kiriting. Tasdiqlash kodi bilan SMS yuboramiz.",
    "Send-code": "Kodni yuboring",
    "Phone-number": "Telefon raqami",
    "Find-department-address": "Filial manzilini toping",
    "Actualize-phone-number": "Telefon raqamingizni yangilang",
    "Contact-bank-to-change-phone-number": "Davom etish uchun bankingizga murojaat qiling.\n Bank xodimi onlayn-banking tizimiga kirish uchun telefon raqamingizni o'zgartirishga yordam beradi.",
    "Operating-day-is-closed-for-actions-with-payment": "Bankning ish kuni 1-karta indeksida to‘lov so‘rovlari bo‘yicha harakatlar uchun yopiq hisoblanadi",
    "Available-for-actions-with": "{time} bilan amallar uchun mavjud",
    "Network-error": "Tarmoq xatosi",
    "back-to-work-with-documents": "Hujjatlar bilan ishlash uchun",
    "No-results-found-for-your-search": "Sizning qidiruvingiz uchun hech qanday natija topilmadi",
    "Try-searching-using-a-different-parameter": "Boshqa parametr yordamida qidirishga harakat qiling",
    "There-are-no-closed-payment-requests-in-card-index-1": "1-karta indeksida yopiq to‘lov so‘rovlari mavjud emas",
    "There-are-no-closed-payment-requests-in-card-index-2": "2-karta indeksida yopiq to‘lov so‘rovlari mavjud emas",
    "create_payments": "To'lovlarni yarating",
    "could-not-import-because-of-lack-of-rights": "Afsuski, kirish huquqlari cheklanganligi sababli ba'zi hujjatlarni yaratib bo'lmadi",
    "next-payments-have-limitations": "Quyidagi satrlarda to'lovlarni yaratish bo'yicha cheklovlar mavjud:",
    "please-make-sure-to-check-before-import": "Iltimos, ushbu hisoblar bo'yicha to'lovlarni yaratish va/yoki tranzaksiyalarga kirish huquqiga ega ekanligingizga ishonch hosil qiling.",
    "Partially-completed": "Qisman bajarilgan",
    "Left-to-pay": "Qolgan narsa - to'lash",
    "please-make-sure-you-have-access-for-account": "Iltimos, ushbu hisoblar bo'yicha to'lovlarni yaratish va/yoki tranzaksiyalarga kirish huquqiga ega ekanligingizga ishonch hosil qiling.",
    "Try-changing-the-filter-values": "Filtr qiymatlarini o'zgartirib ko'ring",
    "No-active-payment-requests": "Faol toʻlov talablari yoʻq",
    "Active-payment-requests-will-appear-on-this-page": "Ushbu sahifada faol to'lov so'rovlari paydo bo'ladi",
    "file size format is not xlxs": "Noto'g'ri fayl formati. Format XLSX bo'lishi kerak",
    "New-requirements": "Yangi talablar",
    "You-have-new-payment-requirements-in-Card-Index-1": "1-karta indeksida sizda yangi toʻlov talablari mavjud.",
    "Please-confirm-that-you-have-read-the-requirements": "Iltimos, talablarni o'qib chiqqaningizni tasdiqlang.",
    "To-perform-this-action-familiarize-yourself-with-new-payment-requirements": "Ushbu amalni bajarish uchun siz avval 1-karta indeksidagi yangi to'lov talablari bilan tanishishingiz kerak",
    "There-are-no-payment-requests-in-this-status": "Bu holatda hech qanday toʻlov talablari yoʻq",
    "Payment-requests-in-status-will-appear-on-this-page": "Agar mavjud boʻlsa, ushbu sahifada { status } toʻlov soʻrovlari paydo boʻladi",
    "In-status": "holatida",
    "Application-for-conversion-(SKV-SKV)": "Konvertatsiya qilish uchun ariza (SKV-SKV)",
    "Payment-requests-and-card-index": "To'lov talablari va kartotekalar",
    "deposits-for-business": "Biznes uchun depozitlar",
    "let-money-work-and-increase-your-budger": "Pulingiz ishlasin: biz sizga mablag'ingizni ko'paytirishga yordam beramiz.",
    "open-until": "{n} gacha ochiq",
    "open-new-deposit": "Yangi depozit oching",
    "promo": "Promo",
    "open_deposit": "Depozit oching",
    "back_to_deposits": "Depozitlarga",
    "overnight": "Har kuni qiziqish",
    "term_deposit": "Muddatli depozit",
    "turnover_money": "Pul butunlay muomalada qoladi",
    "seven_percent_deposit": "Yiliga 7% - har kuni hisoblanadi",
    "from_one_to_month": "1 oydan 24 oygacha",
    "till_twenty_one_percent": "Yiliga 17% gacha",
    "untouchable_deposit_24_month": "24 oyga olinmaydigan depozit",
    "seven_percent_every_month": "Yiliga 7% - oylik",
    "deposit_term": "Depozit \"Muddatli\"",
    "choose-conditions": "Shartlarni tanlang",
    "sign-the-application": "Ilovaga imzo cheking",
    "overnight-deposit": "“Har kuni foiz” depoziti",
    "payments-history": "To'lov tarixi",
    "deposit-information": "Depozit ma'lumotlari",
    "Applications-for-conversion-SKV-SKV": "Konvertatsiya qilish uchun ilovalar (SKV-SKV)",
    "Applications-for-the-purchase-of-foreign-currencies": "Chet el sotib olish uchun arizalar valyutalar",
    "Accounts-and-cards": "Hisoblar va kartalar",
    "Loading-onto-plastic-cards-salary": "Plastik kartalarga yuklash (ish haqi)",
    "Salary-statements": "Ish haqi deklaratsiyasi",
    "List-of-employees": "Xodimlar ro'yxati",
    "List-of-currencies": "Valyutalar ro'yxati",
    "Exchange-rates": "Valyuta kursi",
    "Expanded-issuance-of-personal-accounts": "Shaxsiy hisobvaraqlarni chiqarish kengaytirildi",
    "Plastic-cards-info": "Plastik kartalar haqida ma'lumot",
    "Document-id": "Hujjat identifikatori",
    "Application-amount": "Ariza miqdori",
    "Write-off-account-balance": "Hisobdan chiqarilgan qoldiq",
    "Replenishment-account-balance": "Hisob balansini to'ldirish",
    "Date-time": "O'tkazish sanasi",
    "Enter-code-or-name": "Kod yoki ismni kiriting",
    "Setting-up-notifications-in-telegram": "Telegramda bildirishnomalarni sozlash",
    "Adding-a-new-number": "Yangi raqam qo'shilmoqda",
    "The-user-will-automatically-have-access-to-the-telegram-bot": "Foydalanuvchi avtomatik ravishda Telegram botiga kirish huquqiga ega bo‘ladi.",
    "Special-account-in-foreign-currency": "Xorijiy valyutadagi maxsus hisob",
    "Foreign-currency-account": "Chet el valyutasidagi hisob",
    "Account-for-reservation": "Rezervasyon uchun hisob",
    "Client-name": "Mijoz nomi",
    "Address": "Manzil",
    "Director": "Direktor",
    "Chief-accountant": "Bosh hisobchi",
    "Fax": "Faks",
    "Input-information": "Ma'lumot kiritish",
    "Application-for-the-purchase-of-foreign-currency": "Chet el valyutasini sotib olish uchun ariza",
    "Currency-name": "Valyuta nomi",
    "Maximum-rate": "Maksimal stavka",
    "Minimum-rate": "Minimal stavka",
    "Total-amount": "Umumiy hisob",
    "Interest-rate-in%": "Foiz stavkasi (%)",
    "Commission-amount": "Komissiya miqdori",
    "Purpose-of-use": "Foydalanish maqsadi",
    "Brief-description-of-the-goal": "Maqsadning qisqacha tavsifi",
    "Country-to-which-currency-funds-are-sent": "Valyuta mablag'lari yuboriladigan mamlakat",
    "Attach-copies-of-documents": "Hujjatlarning nusxalarini ilova qiling",
    "Find-a-contract": "Shartnoma toping",
    "Select-a-file": "Faylni tanlang",
    "Attached-files": "Biriktirilgan fayllar",
    "Download": "Yuklab olish",
    "Are-you-sure-you-want-to-delete-the-file": "Haqiqatan ham faylni oʻchirib tashlamoqchimisiz?",
    "Search-for-a-contract": "Shartnomani qidiring",
    "Enter-IDN-contract-number-or-contract-date": "Shartnoma egasining ismini, identifikatsiya raqamini, shartnoma raqamini yoki sanasini kiriting",
    "Forgot-your-username-or-password": "Logingiz yoki parolingizni unutdingizmi?",
    "Forgot-your-username-or-password-content-text": "Siz bankka qo'ng'iroq qilishingiz va operatorga kod so'zini aytishingiz kerak. Bank xodimi dasturga masofadan kirishni tiklaydi.",
    "How-to-log-into-Internet-Banking": "Internet-banking tizimiga qanday kirish mumkin?",
    "Install-software-files-download-link": "3 ta dasturiy ta'minot faylini o'rnating. Yuklab olish havolasi",
    "Download-file-n": "{n} faylini yuklab oling",
    "If-an-error-message-appears-during-installation-click-skip": "O'rnatish vaqtida xato xabari paydo bo'lsa, \"O'tkazib yuborish\" tugmasini bosing.",
    "Step": "{n}-qadam",
    "Follow-the-link-below-and-log-in-using-your-username-and-password": "Quyidagi havolaga o'ting va foydalanuvchi nomi va parolingizdan foydalanib tizimga kiring.",
    "Instruction-step-3-content-text": "Foydalanuvchi nomingiz va parolingizni kiritganingizdan so'ng sizdan SMS-dan bir martalik kod yordamida loginingizni tasdiqlashingizni so'raymiz. Agar telefon raqami eskirgan bo'lsa, uni raqamli imzo kaliti yordamida yoki bank bilan bog'lanish orqali tiklashingiz mumkin.",
    "Change-phone-number": "Telefon raqamini o'zgartiring",
    "No-access-to-number": "Raqamga kirish imkoni yo'q",
    "With-total-for-each-day-of-discharge": "Bo'shatishning har bir kuni uchun jami bilan",
    "Corporate-cards": "Korporativ kartalar",
    "Statement-of-documents-processed-for-the-period": "Davr uchun ko'rib chiqilgan hujjatlar to'g'risidagi bayonot",
    "The-rate-cannot-be-equal": "Narx { rate } ga teng boʻlishi mumkin emas",
    "The-amount-in-currency-cannot-be-equal-to": "Miqdor { rate } ga teng boʻlishi mumkin emas",
    "There-is-no-client-account-in-this-currency-and-it-must-be-opened-at-the-bank": "Bu valyutada mijoz hisobi mavjud emas va u bankda ochilishi kerak",
    "main-page-sidebar-title": "Bosh sahifa",
    "deposit_overnight": "“Har kuni foiz” depoziti",
    "Balance-and-turnover-statement": "Balanslar varaqasi",
    "products": "Mahsulotlar",
    "statement-project": "ish haqi loyihasi",
    "workers": "Xodimlar",
    "swift-transfers": "Swift - tarjimalar",
    "exchange-currency": "Valyuta ayirboshlash",
    "buy-sell": "Sotib olish/Sotish",
    "kartoteka-payment-requirement": "Karta indeksi va unga qo'yiladigan talablar",
    "ved": "ved",
    "others": "Boshqa",
    "Select-all-cards": "Barcha kartalarni tanlang",
    "Personal-account-statement": "Shaxsiy hisoblarni chiqarish",
    "Account-statement-reference": "Hisobni boshqarish bo'yicha yordam",
    "Report-on-terminals": "Terminal hisoboti",
    "Terminal": "Terminal",
    "You-do-not-have-corporate-cards": "Sizda korporativ kartalar yo'q",
    "Please-note-that-the-entered-amount-is-greater-than-the-contract-amount": "Kiritilgan summa shartnoma summasidan kattaroq ekanligini unutmang.",
    "need-to-fill-the-sum": "Siz miqdorni to'ldirishingiz kerak",
    "deposit_sum": "Depozit miqdori",
    "choose-time-and-interest-of-deposit": "Depozit muddati va stavkasini tanlang",
    "what-account-to-use-in-order-to-fill": "Qaysi hisobdan to'ldirish kerak?",
    "how-much": "Necha",
    "automatically-from-bank-account": "Bank tomonidan arizani ko'rib chiqqandan so'ng, joriy hisobdan avtomatik ravishda",
    "deposit-fulfillment": "Depozitni to'ldirish",
    "interest-rate": "Stavka foizi",
    "every-month-on-date": "Har oy - { day } sanalar",
    "interest-payment": "Foizlarni to'lash",
    "i-agree-to-open-deposit": "Men depozit qoidalariga roziman",
    "everymonth-payments": "oylik to'lovlar",
    "deposit_type_and_term": "Depozit turi va muddati",
    "deposit_percent": "Stavka foizi",
    "accept-agreement": "Roziligingizni tasdiqlang",
    "Expanded-issuance": "Kengaytirilgan ekstrakt",
    "processed-reports-period": "Davr uchun bayonot",
    "valueDate": "Qiymat sanasi",
    "orderingCustomer": "Yuboruvchining ismi va manzili (50A)",
    "fieldType56": "Vositachi bank (56)",
    "fieldType57": "Qabul qiluvchining banki (57)",
    "receiver59A": "Qabul qiluvchining ismi va manzili (59A)",
    "remittanceInformation": "Toʻlov tafsilotlari (70)",
    "chargePayer": "Xarajat tafsilotlari (71A)",
    "intermediaryBank": "Vositachi bank (56)",
    "intermediaryBankCountry": "Vositachi bank mamlakati (56A)",
    "beneficiaryCorrespondentBank": "Vositachi bank nomi (56A)",
    "nameAndAddress56": "Bank nomi va manzili (56D)",
    "partyIdentifier56": "BIC kodi (56D)",
    "receiverBankBic": "Qabul qiluvchining banki (57A)",
    "receiverBankName": "Benefisiar bank nomi (57A)",
    "receiverBankCountry": "Qabul qiluvchi bank mamlakati (57A)",
    "partyIdentifier57": "BIC kodi (57D)",
    "nameAndAddress57": "Bank nomi va manzili (57D)",
    "Please-note-that-the-contract-amount-is-large": "E'tibor bering, shartnoma miqdori katta",
    "The-file-exceeds-the-maximum-size": "Fayl maksimal hajmdan oshib ketdi { size }",
    "KB": "KB",
    "MB": "MB",
    "GB": "GB",
    "TB": "TB",
    "The-selected-period-should-not-exceed": "Davr {n} dan oshmasligi kerak",
    "days": "{n} kunga | {n} kun | {n} kunda | {n} kunga",
    "The-dates-of-the-period-are-entered-incorrectly": "Davr sanalari noto'g'ri kiritilgan",
    "report-saldo-reference": "Aylanma balansi",
    "report-accounts": "Hisob ma'lumotlari",
    "deposits": "Depozitlar",
    "Personal-data": "Shaxsiy ma'lumotlar",
    "Notifications": "Bildirishnomalar",
    "Your-password": "Parolingiz",
    "My-documents": "Mening hujjatlarim",
    "My-documents-subtitle": "Bu erda siz ularning holati to'g'risidagi barcha hujjatlar va ma'lumotlarni topasiz.",
    "organization": "Tashkilot",
    "create-document": "Hujjat yaratish",
    "Current-password": "Joriy Parol",
    "Last-change": "Oxirgi tahrirlangan",
    "This-is-the-password-you-will-use-to-log-into-online-banking": "Bu siz onlayn-banking tizimiga kirish uchun foydalanadigan paroldir.",
    "New-again": "Yana yangi",
    "Selected": "Tanlangan",
    "Update-your-name-and-passport-details-in-the-office": "Ofisda ismingiz va pasport ma'lumotlarini yangilang",
    "We-can-change-your-data-only-if-you-contact-bank-branch-in-person": "Agar siz bank filialiga shaxsan murojaat qilsangizgina maʼlumotlaringizni oʻzgartirishimiz mumkin",
    "Download-the-application-for-changing-data": "Ma'lumotlarni o'zgartirish uchun dasturni yuklab oling",
    "All-clear": "Hammasi tushunarli",
    "Update-phone-number": "Telefon raqamini yangilang",
    "Confirm-the-number-change-with-the-digital-signature-key": "Raqam o'zgarishini raqamli imzo kaliti bilan tasdiqlang. Fleshli drayverni kalit bilan kompyuterga joylashtiring va \"Raqamni tekshirish\" tugmasini bosing.",
    "Update-email-address": "Elektron pochta manzilini yangilang",
    "Enter-a-new-email-address": "Yangi elektron pochta manzilini kiriting. Tasdiqlash kodi bilan elektron pochta xabarini yuboramiz.",
    "Email-address": "Elektron pochta",
    "Wrong-login-or-password": "noto'g'ri login yoki parol",
    "You-have-successfully-changed-your-password": "Siz parolingizni muvaffaqiyatli o'zgartirdingiz.",
    "This-password-can-only-be-used-to-log-into-this-personal-account": "Ushbu parol faqat ushbu shaxsiy hisob qaydnomasiga kirish uchun ishlatilishi mumkin.",
    "To-log-into-the-updated-persona-account": "Yangilangan shaxsiy hisob qaydnomasiga kirish uchun",
    "Link-to-new-personal-account": "yangi shaxsiy hisobingizga havola",
    "and-the-mobile-application-will-need-to-enter-your-old-password": "va mobil ilova eski parolingizni kiritishi kerak bo'ladi",
    "Passport": "Pasport",
    "New": "Yangi",
    "Pending": "Kutilmoqda",
    "Waiting-execution": "Kutilmoqda",
    "For-security-purposes-you-need-to-verify-your-account": "Xavfsizlik maqsadida hisobingizni tasdiqlashingiz kerak",
    "Such-a-number-exists": "Bunday raqam mavjud",
    "Minimum-length-characters": "Minimal uzunlik {n} belgi",
    "Characters": "belgilar",
    "To-increase-the-security-of-your-data-bla-bla-bla": "Ma'lumotlaringiz xavfsizligini oshirish uchun parolning murakkabligi yuqori bo'lishi kerak va parolni boshqalar bilan baham ko'rmasligingiz kerak, agar bu sodir bo'lsa, darhol parolingizni o'zgartiring.",
    "Virtual-signatures": "Virtual imzolar",
    "Virtual-digital-signatures": "Virtual raqamli imzolar",
    "Change-pin-code": "pin kodini o'zgartirish",
    "Active": "Faol",
    "Expired": "Muddati tugagan",
    "Issue-a-new-signature": "Yangi imzo qo'ying",
    "You-do-not-have-a-virtual-digital-signature-yet": "Sizda hali virtual raqamli imzo yo'q. Yangi imzoni chiqarish uchun quyidagi tugmani bosing.",
    "You-need-to-download-and-install-the-software-on-your-personal-computer": "Dasturiy ta'minotni shaxsiy kompyuteringizga yuklab olishingiz va o'rnatishingiz kerak. O'rnatish tugagandan so'ng, \"Yangi raqamli imzo berish\" tugmasini bosing.",
    "Change-signing-pin-code": "Imzolash pin kodini o'zgartiring",
    "It-will-need-to-be-entered-each-time-you-sign-documents": "Har safar hujjatlarni imzolaganingizda uni kiritishingiz kerak bo'ladi.",
    "Create-a-new-Pin-code": "Yangi PIN kod yarating",
    "Repeat-your-Pin-code": "Pin kodingizni takrorlang",
    "Pin-code-does-not-match-try-again": "PIN kod mos kelmadi. Qayta urinib ko'ring",
    "Pin-code-has-been-successfully-changed": "PIN kod muvaffaqiyatli almashtirildi",
    "Are-you-sure-you-want-to-remove-the-signature": "Imzoni olib tashlamoqchimisiz?",
    "Next-time-it-will-have-to-be-released-again": "Keyingi safar uni yana chiqarish kerak bo'ladi.",
    "Confirm": "Tasdiqlang",
    "Virtual-signature-released": "Virtual imzo chiqarildi",
    "Install-signature-release-software": "Imzo chiqarish uchun dasturiy ta'minotni o'rnating",
    "You-need-to-download-and-install-the-software-on-your-pc": "Dasturiy ta'minotni shaxsiy kompyuteringizga yuklab olishingiz va o'rnatishingiz kerak. O'rnatish tugallangach, qayta urinib ko'ring va \"Davom etish\" tugmasini bosing.",
    "Download-installation-software": "O'rnatish dasturini yuklab oling",
    "Software-not-found": "dasturiy ta'minotni topilmadi",
    "please-install-software-and-try-again": "Iltimos, dasturni kompyuteringizga o'rnating va qaytadan urinib ko'ring.",
    "You-already-have-an-active-digital-signature-on-this-computer": "Ushbu kompyuterda allaqachon faol raqamli imzoingiz bor",
    "You-can-use-it": "Siz undan foydalanishingiz mumkin",
    "Signature-limit-exceeded": "Imzo chegarasidan oshib ketdi",
    "You-can-have-a-maximum-of-3-signatures-on-3-different-devices": "3 xil qurilmada maksimal 3 ta imzoga ega boʻlishingiz mumkin. Yangi virtual imzo berish uchun joriy imzolardan birini oʻchiring.",
    "New-virtual-signatures": "Yangi \nvirtual imzolar",
    "Release-the-signature-on-your-work-pc-and-the-USB-key-will-no-longer-be-needed": "Ish kompyuteringizda imzoni qo'yib yuboring va USB kaliti endi kerak bo'lmaydi.",
    "Later": "Keyinchalik",
    "Release-signature": "Imzoni qoldiring",
    "Date-and-time": "sana va vaqt",
    "Repeat-the-payment": "Takroriy to'lov",
    "Create-a-template": "Shablon yarating",
    "Email-address-changed": "Elektron pochta manzili o'zgartirildi",
    "Change-email": "Elektron pochta manzilini o'zgartiring",
    "From-the-template": "Shablondan",
    "There-are-no-documents-in-the-bank": "Bankda hujjatlar yo'q",
    "Sign-and-send-the-documents-for-processing-to-the-bank": "Hujjatlarni imzolang va yuboring bankka qayta ishlash uchun",
    "There-are-no-documents-for-approval": "Tasdiqlash uchun hujjatlar yo'q",
    "Create-a-new-one-or-wait-for-the-approval-documents-to-appear": "Yangisini yarating yoki tasdiqlash uchun hujjatlar paydo bo'lishini kuting",
    "There-are-no-documents-to-sign": "Imzolash uchun hujjatlar yo'q",
    "Create-a-new-one-or-wait-for-the-documents-to-be-signed": "Yangisini yarating yoki hujjatlar imzolanishini kuting",
    "No-future-payments": "Kelajakda to'lovlar yo'q",
    "Schedule-the-payment-for-a-future-date-and-it-will-appear-here": "Toʻlovni kelajakdagi sanaga rejalashtiring va u shu yerda koʻrinadi",
    "Phone-number-changed": "Telefon raqami o'zgartirildi",
    "There-are-no-matches": "Topilmadi",
    "Try-to-change-the-query": "Soʻrovingizni oʻzgartirib koʻring",
    "Until": "oldin",
    "Search-my-documents-placeholder": "Qabul qiluvchining hisobi, to'lov miqdori yoki hujjat raqami bo'yicha qidiring",
    "ID-card": "O‘zbekiston Respublikasining shaxsiy guvohnomasi",
    "Passport-uz": "O‘zbekiston Respublikasi fuqarosining pasporti",
    "Military-id": "Harbiy guvohnoma",
    "Service-id": "Xizmat identifikatori",
    "International-passport": "Chet el fuqarosining pasporti",
    "Residence-permit": "Rezident karta",
    "Biometric-passport": "O‘zbekiston Respublikasi fuqarosining biometrik pasporti",
    "Birth-certificate": "Tug'ilganlik haqidagi guvohnoma",
    "Drivers-license": "Yangi milliy haydovchilik guvohnomasi",
    "Reset-the-request": "Qayta tiklash so'rovi",
    "At-the-signing": "Imzolashda",
    "Signed": "Imzolangan",
    "Rejected": "Rad etilgan",
    "On-performance": "Ishlash bo'yicha",
    "You-entered-the-wrong-password": "Siz noto'g'ri parol kiritdingiz",
    "Signed-by": "Imzolash",
    "Incorrect-code": "Noto'g'ri kod",
    "Enter-the-code-from-the-email": "Xatdan kodni kiriting",
    "We-sent-an-email-with-a-code-to-the-address": "Biz manzilga kodli xat yubordik",
    "Awaiting-approval": "Tasdiqni kutish",
    "Awaiting-signing": "Imzolanish kutilmoqda",
    "Future-payments": "Kelajakdagi to'lovlar",
    "Documents-in-the-bank": "Bankdagi hujjatlar",
    "Connection-error": "Ulanish xatosi",
    "Internal-Server-Error": "Serverdagi ichki xatolik",
    "Something-went-wrong-on-our-end": "Keyinroq qayta urinib ko‘ring yoki qo‘llab-quvvatlash xizmatiga murojaat qiling",
    "Apply": "Tasdiqlash",
    "Reset": "Tozalash",
    "Download-document": "Hujjatni yuklash",
    "Create-signing-pin-code": "Imzolash uchun Pin-kodni yarating ",
    "Completed": "Bajarildi",
    "Open-details": "Tafsilotlarni ochish",
    "The-bank-will-consider-your-proposed-rate-when-considering-your-application": "Bank arizani ko'rib chiqishda siz taklif qilgan kursni ko'rib chiqadi",
    "Account-from": "Pul yechiladigan hisob",
    "Payment-number": "To'lov №{n}",
    "Action-history": "Harakatlar tarixi",
    "Pinfl": "JShShIR",
    "inquiries": "Arizalar",
    "my-inquiries": "Mening arizalarim",
    "about-client": "Mijoz haqida",
    "Loading-data-error": "Maʼlumotlarni yuklashda xatolik yuz berdi",
    "Receiver-card": "Qabul qiluvchining kartasi",
    "Transit-account": "Tranzit hisobi",
    "full_name": "Familiya Ismi Otasining Ismi",
    "inn_or_pinfl": "Tashkilotning STIR yoki JShShIR",
    "product": "Mahsulot",
    "enter_number": "Raqamni kiriting",
    "email": "Elektron pochta",
    "additional": "Qo'shimcha",
    "manager_comments": "Menejer uchun izoh",
    "add-comment": "Izoh qo'shish",
    "send-inquiry": "Arizani yuborish",
    "new-inquiry": "Yangi ariza",
    "incorrect-email": "Elektron pochtani to'g'ri formatda kiriting",
    "manager-will-contact": "Bank menejeri qisqa vaqt ichida mijoz bilan bog'lanadi - holatni kuzatib boring.",
    "account-open": "Hisob raqamni ochish",
    "Sign": "Imzolash",
    "Logout": "Chiqish",
    "Sorting-by-date": "Sana bo'yicha saralash",
    "From-new-payments-to-old-ones": "Yangi to'lovlardan eskilariga",
    "From-old-payments-to-new-ones": "Eski to'lovlardan yangilariga",
    "sum-purpose": "Miqdori va maqsadi",
    "payment-amount": "To'lov miqdorini kiriting",
    "account-tin-name": "Hisobni, STIRni yoki byudjet oluvchining ismini kiriting",
    "who-send": "Kimga yuborish",
    "corporate-card": "Korporativ kartaga",
    "to-individual": "Jismoniy shaxsga",
    "min-max-letters": "{0}/{1} belgilar",
    "from-account-send": "Qaysi hisobdan yuborish",
    "template-info": "Shablon haqida ma'lumot",
    "template-name": "Shablon nomi",
    "select-bank": "Qabul qiluvchining bankini tanlash",
    "bank-name-mfo": "Bank yoki MFO nomi",
    "name-template": "Shabloningizni nomlang",
    "create-payment": "To'lov yaratish",
    "update-template": "Shablonni yangilash",
    "save-template": "Shablonni saqlash",
    "template-created": "Shablon yaratildi",
    "make-payment-by-template": "Yaratilgan shablon bo'yicha to'lovni amalga oshiring.",
    "template-updated": "Shablon yangilandi",
    "selecting-payment-purpose": "To'lov maqsadi kodini tanlash",
    "purpose-name-code": "Maqsad nomi yoki kodi",
    "type": "Turi",
    "sender-branch": "Qabul qiluvchining banki {0}",
    "receiver-branch": "Yuboruvchining banki {0}",
    "create-templates-and-chill": "Shablonlarni yarating va jarayonlaringizni soddalashtiring.",
    "create-template": "Shablon yarating",
    "find-by-account-amount-documents": "Qabul qiluvchining hisobi, to'lov miqdori yoki hujjat raqami bo'yicha qidiring",
    "no-templates": "Shablonlar yo'q",
    "no-templates-create": "Bu yerda hali shablonlar yo'q. Jarayonlarni yanada samarali va qulay qilish uchun o'z shablonlaringizni yarating",
    "template-deleted": "Shablon o'chirildi",
    "create-new-template-and-chill": "Yangi shablon yarating va jarayonlaringizni soddalashtiring.",
    "cant-delete-template": "Shablonni o'chirib bo'lmadi",
    "try-delete-template-later": "Uni keyinroq o'chirishga harakat qiling.",
    "document-details": "Hujjat tafsilotlari",
    "no-active-inq": "Sizda faol arizalar yo'q",
    "invite-and-watch": "Mijozlarni taklif qilishni boshlang va bu yerda arizalar holatini kuzatib boring",
    "Client-name-or-INN": "Mijozning ismi yoki STIR",
    "Created": "Yaratilgan",
    "In-process": "Ish jarayonida",
    "Client-refusal": "Mijozning rad etishi",
    "Finished": "Yakullangan",
    "statements": {
      "title": "Ko'chirmalar",
      "desc": "Qo'shimcha qiyinchiliklarsiz ko'chirmalarni yarating va ko'rib chiqing",
      "tab1": "Yangi ko'chirma",
      "tab2": "Ilgari yaratilgan",
      "item1": {
        "title": "Hisob bo'yicha ko'chirma",
        "desc": "Excel formatida"
      },
      "item2": {
        "title": "Kengaytirilgan hisobvaraq",
        "desc": "TXT formatida"
      },
      "item3": {
        "title": "Hisob ishlashi to'g'risidagi ma'lumotnoma",
        "desc": "Excel va TXT"
      },
      "item4": {
        "title": "Shaxsiy hisoblardan ko'chirma",
        "desc": "TXT formatida"
      },
      "item5": {
        "title": "Aylanma-saldo vedomosti ",
        "desc": "TXT formatida"
      },
      "item6": {
        "title": "Davr uchun vedomost",
        "desc": "Bir nechta hisoblar bo'yicha o'tkazilgan hujjatlar"
      },
      "item7": {
        "title": "To'lov hujjatlari",
        "desc": "Barcha hujjatlar bilan PDF"
      },
      "item8": {
        "title": "Korporativ kartalar",
        "desc": "Excel formatida"
      },
      "item9": {
        "title": "Terminal hisoboti",
        "desc": "Terminalda o'tkazilgan tranzaksiyalar"
      }
    },
    "Inquiry-number": "Ariza №",
    "client": "Mijoz",
    "phone": "Telefon",
    "Full-name": "Familiya Ismi Otasining Ismi",
    "The-current-account-has-been-opened": "Hisob raqam ochilgan",
    "Payments-creation": "To'lovlarni yaratish",
    "Opened": "Ochiq",
    "Sum-payments": "So'mlik",
    "Currency-payments": "Valyutalik",
    "To-any-Uzbekistan-bank": "O'zbekistonning har qanday bankiga",
    "To-card": "Kartaga",
    "To-budget": "Byudjetga",
    "By-requisites": "Rekvizitlar bo'yicha",
    "To-treasury": "G'aznachilikka",
    "Government-services": "Davlat xizmatlari",
    "Payment-via-MUNIS": "MUNIS orqali to'lov",
    "Swift-transfer": "SWIFT - o'tkazma",
    "All-over-the-world": "Dunyo bo`yicha",
    "Buy-currency": "Valyuta sotib olish",
    "Sell-currency": "Valyuta sotish",
    "To-currency-account": "Valyuta hisobiga",
    "From-currency-account": "Valyuta hisobidan",
    "currencyConversion": "Valyuta konvertatsiyasi",
    "Discharge-information": "Ko'chirma haqida ma'lumot",
    "Sorting-and-turnover-type": "Saralash va aylanma turi",
    "Loan-contract": "Kredit shartnomasi",
    "Loan-repayment-account": "Kreditni so'ndirish uchun hisob",
    "In-the-presence-of": "agar mavjud bo'lsa",
    "payment-history": "To'lovlar tarixi",
    "here-find-history": "Bu yerda siz o'tkazilgan barcha hujjatlar va ularning holati to'g'risidagi ma'lumotlarni topasiz.",
    "search-payment": "To'lovlar bo'yicha qidirish",
    "in-out": "Kiruvchi/Chiquvchi",
    "incoming": "Kiruvchi",
    "outcoming": "Chiquvchi",
    "date-range": "Sana yoki davr",
    "buy": "buy",
    "sell": "sell",
    "out-sum": "Yechiladigan pul miqdori",
    "in-sum": "Tushadigan pul miqdori",
    "short-payrolls": "Ish haqi vedomosti",
    "convertation": "Konvertatsiya",
    "in-currency": "Tushadigan valyuta",
    "in-account": "Pul tushadigan hisob",
    "out-currency": "Yechiladigan pul valyutasi",
    "gov-services": "Davlat xizmatlari",
    "try-change-request": "So'rovingizni o'zgartirishga harakat qiling",
    "no-payments": "O'tkazilgan to'lovlar yo'q",
    "create-payment-and-store-here": "Yangi to'lovni yarating va u shu yerda saqlanadi",
    "history": "Tarix",
    "confirmWithOnlineDigitalSignature": "Virtual imzo orqali",
    "enter-virtual-signature-pin": "Virtual ERI Pin-kodini kiriting",
    "forgot-pin-code": "PIN-kodni unutdingizmi?",
    "found-n-results": "{n} ta natija topildi. | {n} ta natija topildi. | {n} ta natija topildi. | {n} ta natija topildi.",
    "Period": "Davr",
    "Select-a-service": "Xizmatni tanlang",
    "failed-to-submit-modal": "Xatolik yuz berdi, keyiroq urinib ko'ring",
    "submit-review": "Fikr-mulohaza yuborish",
    "tell-us-why-you-evaluated-that-way": "Ayting-chi, nega bunday baho berdingiz? (bu ixtiyoriy)",
    "very-bad": "Juda yomon",
    "nice": "Ajoyib",
    "we-see-that-you-recently-have-opened-the-account": "Siz yaqinda bizning bankimizda hisob ochganingizni ko'ramiz.",
    "tell-us-how-it-was": "Hammasi qanday o'tganini ayting - biz bundan ham yaxshiroq bo'lishni xohlaymiz.",
    "evaluate-the-service": "Xizmat sifatini baholang",
    "rate-us-before-submitting": "Iltimos, baholash maydonini to'ldiring",
    "review-is-submitted": "Fikr-mulohaza yuborildi",
    "thank-you-for-making-uzum-better": "Uzum Business'ni yanada yaxshiroq qilishga yordam bergyotganingiz uchun raxmat :)",
    "services": "Xizmatlar",
    "wrong-login-or-password": "Login yoki parol noto‘g‘ri ko'rsatilgan",
    "input-placeholder": "Kiritish",
    "create-payment-order-for-transit-salary-account": "Tranzit ish haqi hisobi uchun to'lov topshirig'ini yaratish",
    "gov-service": "Davlat xizmati",
    "add-payroll": "Vedomost qo'shish",
    "user-not-activated": "Foydalanuvchi faol emas",
    "": "Davlat xizmati",
    "import-document": "Hujjatni import qilish",
    "create-from-template": "Shablondan yaratish",
    "payment-to-account": "Hisob raqamiga to'lov",
    "payment-to-card": "Kartaga to'lov",
    "payment-to-budget": "Byudjetga to'lov",
    "payment-to-treasury": "G'aznachilikka to'lov",
    "Create-statement": "Ko'chirmani yarating",
    "Create-statement-desc": "Siz ushbu sahifada ilgari yaratilgan barcha ko'chirmalaringizni topishingiz mumkin",
    "Restore-statements": "Ko'chirmalarni tiklash",
    "Create-a-new-statement": "Yangi ko'chirmani yarating",
    "All-reports-have-been-deleted": "Barcha ko'chirmalar o'chirildi",
    "Are-you-sure-you-want-to-clean-everything-up": "Haqiqatan ham hamma narsani o'chirmoqchimisiz?",
    "The-report-is-being-generated-and-will-be-uploaded-soon": "Hisobot yaratilmoqda va tez orada yuklanadi",
    "Report-succes-notify-title": "Ko'chirma yaratildi",
    "Report-succes-notify-desc": "Siz uni istalgan vaqtda \"Ilgari yaratilgan\" bo'limida topishingiz mumkin.",
    "purpose": "Maqsad",
    "document-info": "Hujjat haqida ma'lumot",
    "phone-for-call": "Aloqa uchun",
    "copy-currency-contract": "Valyuta shartnomasining nusxalari",
    "document-name": "Hujjat nomi",
    "variant": "Nusxa",
    "no-document": "Hujjat yo'q",
    "request-for-currency-operation": "Valyuta amaliyotlari uchun ariza",
    "currency-conversion-info-text": "Sizning arizangiz bo'yicha ijobiy qaror qabul qilish uchun siz konvertatsiya bo'yicha blok-hisobiga mablag'larni zaxiralashingiz kerak.",
    "currency-conversion-info-text-sub": "Mablag'larni zaxiralash tavsiya etiladi.",
    "left-sum-block-account": "Blok-hisobidagi qoldiq",
    "sum-request-variant": "Ariza miqdori ekvivalentda",
    "send-from-my-documents-page": "\"Mening hujjatlarim\" menyu bo'limidan hujjatni bankka yuboring.",
    "are-you-sure-you-to-continue": "\nHaqiqatan ham davom etishni xohlaysizmi?",
    "yes": "Ha",
    "no": "Yo'q",
    "cancel-download": "Yuklab olishni bekor qilish",
    "Poisk-documentov": "Hujjatlarni qidirish",
    "Try-to-choose-other-dates": "Boshqa sanalarni tanlashga harakat qiling",
    "Try-to-choose-other-filters": "Boshqa filtrlarni tanlashga harakat qiling",
    "Creating-an-extract": "Ko'chirma shakllantirmoqdamiz",
    "Error-during-formation": "Shakllantirishda xatolik",
    "An-error-has-occurred-please-try-again-later": "Xato yuz berdi, keyinroq qayta urinib ko'ring",
    "invalid-date": "Notog'ri sana",
    "Reset-dates": "Sanalarni tiklash",
    "Reset-filters": "Filtrlarni tiklash",
    "Requisites": "Rekvizitlar",
    "Service1": "Xizmat",
    "Service2": "Servis",
    "Region": "Viloyat",
    "Area": "Tuman",
    "Branch": "Bo'lim",
    "Create-statement2": "Ko'chirmani yaratish",
    "payment-monthly": "oylik to'lovlar",
    "deposit-terms-agreement": "Men depozit qoidalariga roziman",
    "extended-successfully": "Muvaffaqiyatli uzaytirildi",
    "extended-unsuccessfully": "Uzaytirish bo‘lmadi",
    "Select-a-card": "Kartani tanlang",
    "date-from": "Sana",
    "date-to": "gacha",
    "agreed": "Tanishib chiqdim",
    "turn-on-tg-bot": "Botga kirish imkoniyatini yoqing",
    "available-for-tg-users": "Bot faqat Telegram foydalanuvchilari uchun mavjud bo'ladi",
    "add-phone-number": "Yangi raqam qo'shish",
    "are-you-sure-delete-number": "Haqiqatan ham raqamni oʻchirib tashlamoqchimisiz?",
    "nex-time-should-add-again": "Keyingi safar uni yana qo'shish kerak bo'ladi",
    "number-added-successfully": "Raqam muvaffaqiyatli qo'shildi",
    "number-deleted-successfully": "Raqam muvaffaqiyatli o'chirildi",
    "for-safety-confirm-account": "Xavfsizlik uchun siz akkauntingizni tasdiqlashingiz kerak",
    "enter-corresponded-account-user": "Qabul qiluvchi bankning korrespondent hisobini kiriting",
    "show-client-name-account-name-payment-name": "Mijozning ismini, hisob raqamini, to'lov maqsadini ko'rsating",
    "open-deposit-title": "Yillik {rate}% ga {term} oyga ochilgan — har oyning 25-kunida foizlar to‘lanadi.",
    "Terminal-number": "Terminal raqami",
    "forbidden-page": "Forbidden 403 ushbu marshrutga kirish imkoniga ega emas\n",
    "back-to-main": "Bosh sahifaga qaytish",
    "password-change-hint-text": "Ma'lumotlaringiz xavfsizligini yaxshilash uchun parolning murakkabligi yuqori bo'lishi kerak, shuningdek parolni boshqa shaxslarga berish mumkin emas, agar bu sodir bo'lsa, darhol parolingizni o'zgartiring",
    "password-hint-text-start": "Parolni o'zgartirganingizda, u ushbu shaxsiy kabinet va mobil ilovaga kirish uchun ishlatilishi mumkin. Eski shaxsiy kabinetingizga kirish uchun",
    "password-hint-text-end": "eski paroldan foydalanish kerak bo'ladi.",
    "language": "Til",
    "key-is-not-for-account": "Kalit akkauntingizga mos kelmaydi",
    "put-the-proper-key-and-try-again": "To'g'ri kalitni kiriting va qayta urinib ko'ring.",
    "we-could-not-find-virtual-key": "ERI kaliti topilmadi",
    "put-the-key-and-try-again": "Kalitni kiriting va qayta urinib ko'ring.",
    "your-key-is-out-of-date": "Sizning ERI kalitingiz eskirgan",
    "try-with-virtual-signature-or-contact-us": "Virtual imzo bilan davom eting yoki yoping va qo'llab-quvvatlash xizmatiga murojaat qiling.",
    "send-via-anor": "Anor orqali yuborish 24/7 ",
    "payment-can-be-sent-at-any-time": "To'lovni istalgan vaqtda yuborish mumkin",
    "even-after-end-of-banking-day": "Bankda ish kuni tugaganidan keyin ham",
    "search-documents": "Hujjatlarni qidirish",
    "Destination-codes": "To'lov kodlari",
    "Select-account": "Hisobni tanlang",
    "Munis-subtitle": "Hujjatlarni imzolang yoki to'lov tarixini ko'ring.",
    "Supplier": "Yetkazib beruvchi",
    "receiverNameNew": "Qabul qiluvchining hisob nomi",
    "Debit-turnover": "Debet aylanmasi",
    "Credit-turnover": "Kredit aylanmasi",
    "Munis": "Munis",
    "Personal-account": "Shaxsiy hisob",
    "Payment-created": "Toʻlov yaratildi",
    "Commission": "Komissiya",
    "waiting-documents": "Hujjatlar kutilmoqda",
    "Select-service": "Xizmatni tanlang",
    "Select-region": "Hududni tanlang",
    "Select-area": "Hududni tanlang",
    "Select-branch": "Filialni tanlang",
    "Enter-amount": "Miqdorni kiriting",
    "Sum-account": "so'm hisob",
    "failed-to-load-inquiry-details": "Ilova tafsilotlarini yuklashda xatolik yuz berdi. Keyinroq qayta urinib ko‘ring",
    "partner": "Hamkor",
    "inquiries-created": "Yaratilgan jami ilovalar",
    "inquiries-in-progress": "Ilovalar amalga oshirilmoqda",
    "inquiries-declined": "Ilovalar rad etildi",
    "inquiries-succeed": "Muvaffaqiyatli ilovalar",
    "You-can-use-it-or-delete-to-create": "Siz uni ishlatishingiz yoki yangisini yaratish uchun mavjudini o'chirishingiz mumkin.",
    "incorrect-pin-try-again": "PIN-kod noto'g'ri kiritilgan. Qayta urinib ko'ring.",
    "if-no-styx-on-the-device": "Agar sizda hali ushbu qurilmada raqamli imzo bo'lmasa",
    "enter-sms-to-commit": "Tasdiqlash uchun SMS-dan kodni kiriting",
    "enter-sms-to-delete": "Imzoni o'chirish uchun SMS kodini kiriting",
    "styx-removed": "Virtual imzo o'chirildi.",
    "how-use-styx": "Raqamli imzodan qanday foydalanish kerak?",
    "download-and-install-by-button": "Quyidagi tugmani bosish orqali dasturiy ta'minotni shaxsiy kompyuteringizga yuklab oling va o'rnating.",
    "download-mac": "Mac-da yuklab oling",
    "download-windows": "Windowsda yuklab oling",
    "new-styx-by-button-and-pin": "Yangi imzoni chiqarish uchun quyidagi tugmani bosing. Biz sizga PIN-kod o'rnatishingizni taklif qilamiz.",
    "understandable": "Hammasi aniq",
    "styx-guide": "Virtual raqamli imzodan foydalanish uchun virtual imzo sozlamalarida kerakli dasturni yuklab oling.\n\nQurilmangizda virtual raqamli imzodan foydalanish uchun u unga ulangan bo'lishi kerak. \n\nBitta qurilma bitta virtual raqamli imzoga ulanishi mumkin. Hisobingizda siz 3 xil qurilma uchun 3 tagacha virtual raqamli imzolarni chiqarishingiz mumkin.\n\nAgar siz PIN kodingizni unutgan bo'lsangiz yoki virtual raqamli imzoning amal qilish muddati tugagan bo'lsa, uni kompyuteringizdan o'chiring va yangi PIN kod yaratib, yangisini chiqaring.",
    "how-does-it-work": "U qanday ishlaydi?",
    "deposit-pay": "Biz oy davomida to'plangan foizlarni jamlaymiz va uni oyiga bir marta joriy hisob raqamiga to'laymiz.",
    "deposit-at-night": "Kechqurun pul o'tkaziladi",
    "deposit-money-will-go-in-the-day-end": "Joriy hisobvaraqdagi pul kun oxirida depozit hisobvarag'iga o'tkaziladi.",
    "deposit-money-works-at-night": "Kechasi pul ishlaydi",
    "deposit-money-are-located-in-account": "Pul depozit hisobvarag'ida va foizlarni oladi.",
    "deposit-in-the-morning": "Ertalab pul hisobdan chiqariladi",
    "deposit-money-back-in-the-morning": "Depozit hisobvarag‘idagi mablag‘lar soat 9:00 da joriy hisob raqamiga qaytariladi.",
    "choose-account": "Hisobni tanlang",
    "what-will-be-interest": "Foiz stavkasi qanday bo'ladi?",
    "what-will-be-term": "Qanday muddatga?",
    "how-to-increase-deposit": "Depozitni qanday to'ldirish kerak?",
    "can-i-withdraw-before": "Erta yopish mumkinmi?",
    "how-much-to-deposit": "Qancha depozit qilish kerak?",
    "only-for-24-month": "Faqat 24 oyga",
    "progressive-deposit-rate": "Ushbu depozit progressiv foiz stavkasiga ega - uch oy davomida 1 foizga oshadi.\n",
    "deposit-is-taken-from-account": "Depozit tanlangan joriy hisobdan arizangizni ko'rib chiqqandan so'ng darhol to'ldiriladi",
    "you-can-do-it-only-in-the-bank-office": "Bu faqat bankning ofisida amalga oshirilishi mumkin, foizlarni hisoblash muddatga bog'liq bo'ladi",
    "everyday-for-the-leftover-amopunt": "Har kuni, tanlangan hisob balansi miqdori uchun",
    "how-interest-is-paid": "Foizlar qanday hisoblanadi?",
    "what-account": "Qaysi hisobdan to'ldirishim kerak?",
    "only-for-12-month": "12 oy davomida",
    "sever-percent-interest": "Yiliga 7%",
    "you-dont-need-to-do-anything-bank-will-do": "Hech narsa qilishingiz shart emas, kun oxirida pul tanlangan hisob hisobidan avtomatik ravishda depozit hisobvarag'iga o'tkaziladi.",
    "this-could-be-done-only-in-the-office": "Buni faqat bank ofisida qilish mumkin",
    "every-month-in-25": "Har oy - 25",
    "how-interest-is-being-paid": "Foizlar qanday to'lanadi?",
    "which-account-should-be-paid": "Qaysi hisobvaraqqa foizlarni hisoblash kerak?",
    "deposit-account": "Depozit hisobvarag'i",
    "rs-account": "Joriy hisob",
    "for-the-leftover-amount-you-will-get-money": "Biz har kuni balansga foizlarni hisoblaymiz",
    "the-leftover-we-will-take-in-the-eod": "Joriy hisobvaraqning qoldig'i bank kunining oxirida depozitga o'tkaziladi",
    "for-all-time": "Hamma vaqt uchun",
    "to-the-account": "Joriy hisobga •",
    "from": "Bilan",
    "to": "tomonidan",
    "here-you-will-have-interests": "Bu yerda foizlarni hisoblash bo'ladi",
    "inquiry-to-open-deposit": "Depozit ochish uchun ariza",
    "faq": "Ko'p so'raladigan savollar",
    "deposit_faq_first": "Depozitdan pulni muddatidan oldin yechib olish mumkin. Buning uchun bank filialiga boring, ariza yozing va pulni oling.",
    "deposit_faq_second": "Tez orada siz buni shaxsan tashrif buyurmasdan qilishingiz mumkin bo'ladi.",
    "how-to-withdraw-money-from-deposit": "Depozitdan pulni qanday olish mumkin?",
    "deposit_faq_third": "Siz depozitga pul qo'yishingiz mumkin, ammo to'lov summasiga foizlar hisoblanmaydi.",
    "deposit_faq_fourth": "Depozit miqdorini oshirish uchun bank filialiga murojaat qiling va depozit shartlarini o'zgartirish uchun ariza yozing.",
    "deposit_faq_fifth": "Tez orada siz buni shaxsan tashrif buyurmasdan qilishingiz mumkin bo'ladi.",
    "how-to-close-deposit": "Depozitni qanday yopish kerak?",
    "you-can-close-deposit-but-it-will-affect-interest": "Depozitni muddatidan oldin yopish mumkin, lekin foiz stavkasi o'zgaradi.",
    "steps-to-close-deposit": "Depozitni yopish uchun bank filialiga murojaat qiling va ariza yozing.",
    "deposit": "Depozit",
    "is-open-untill": "qadar ochiq",
    "term_dep": "Muddatli",
    "deposit_rules": "Depozit qoidalari",
    "account-for-deposit": "Depozit uchun joriy hisob",
    "uzs-account": "uzs-account",
    "for-24-month": "24 oy davomida",
    "deposit-till-17%": "17% gacha",
    "for-12-month": "12 oy davomida",
    "I-agree-with": "Men roziman",
    "dep-rules": "ro'yxatga olish qoidalari",
    "rules-of-dep": "depozit",
    "select-placeholder": "Tanlang",
    "you-should-have-more-than-thousand": "Hisobda kamida 1000 so‘m bo‘lishi kerak – hisobingizni to‘ldiring yoki boshqasini tanlang",
    "not-enough-money": "Pul yetarli emas - hisobingizni to'ldiring yoki boshqasini tanlang",
    "deposit-is-created": "Depozit muvaffaqiyatli yaratildi",
    "10_days": "10 kun",
    "1_month": "1 oy",
    "2_months": "2 oy",
    "3_months": "3+ oy",
    "One": "bir",
    "Two": "ikki",
    "Three": "uch",
    "Four": "to'rt",
    "Five": "besh",
    "Six": "olti",
    "Seven": "yetti",
    "Eight": "sakkiz",
    "Nine": "to'qqiz",
    "Ten": "o'n",
    "Eleven": "o'n bir",
    "Twelve": "o'n ikki",
    "Thirteen": "o'n uch",
    "Fourteen": "o'n to'rt",
    "Fifteen": "o'n besh",
    "Sixteen": "o'n olti",
    "Seventeen": "o'n etti",
    "Eighteen": "o'n sakkiz",
    "Nineteen": "o'n to'qqiz",
    "Twenty": "yigirma",
    "Thirty": "o'ttiz",
    "Fourty": "qirq",
    "Fifty": "ellik",
    "Sixty": "oltmish",
    "Seventy": "yetmish",
    "Eighty": "sakson",
    "Ninety": "to'qson",
    "one-hundred": "yuz",
    "two-hundred": "ikki yuz",
    "three-hundred": "uch yuz",
    "four-hundred": "to'rt yuz",
    "five-hundred": "besh yuz",
    "six-hundred": "olti yuz",
    "seven-hundred": "etti yuz",
    "eight-hundred": "sakkiz yuz",
    "nine-hundred": "to'qqiz yuz",
    "Sum": "so'm",
    "Suma": "so'm",
    "Sumov": "so'm",
    "US-dollar": "AQSH dollari",
    "US-dollara": "AQSH dollari",
    "US-dollars": "AQSH dollari",
    "thousand": "ming",
    "thousands": "ming",
    "thousand2": "ming",
    "one1": "bitta",
    "two2": "ikki",
    "million": "million",
    "million2": "million",
    "millions": "million",
    "billion": "milliard",
    "billion2": "milliard",
    "billions": "milliard",
    "trillion": "trillion",
    "trillion2": "trillion",
    "trillions": "trillion",
    "tiyin": "tiyin",
    "tiyin2": "tiyin",
    "tiyins": "tiyin",
    "created": "Yaratilgan",
    "carried-out": "amalga oshirildi",
    "Dear-clients": "Hurmatli mijozlar",
    "Dear-clients-message": {
      "part1": "Ikki marta ro'yxatdan o'tishni oldini olish uchun eski va yangi \"bank mijozi\" orqali bir vaqtning o'zida bir xil bayonotni yubormasligingizni so'raymiz. O'z navbatida varaqalarni yuboring va ularni takrorlashdan saqlaning. To'lovni 23106 tranzit ish haqi hisobvarag'iga yuborganingizdan so'ng, ro'yxatdan o'tish o'z vaqtida amalga oshirilishi uchun bir kun ichida varaqani yuboring. Shuningdek, bank kuni yopilishidan oldin ish haqini hisobga olish uchun varaqalar yuborishingizni so'raymiz.",
      "part2": "Hurmat bilan, Uzum Business - Kapitalbank jamoasi",
      "part3": "Hozirgi paytda mobil ilova va internet-bankingda tezkor to‘lovlar 24/7 (ANOR) tizimi orqali xazina va byudjetga to‘lovlar vaqtincha mavjud emas. Biz bu muammoni hal qilish ustida ishlayapmiz va xizmatlarning to‘liq ishlashini tez orada tiklash uchun barcha choralarni ko‘ryapmiz.",
      "part4": "Yetkazilgan noqulayliklar uchun uzr so‘raymiz. Tushunishingiz va sabr-toqatingiz uchun rahmat!"
    },
    "Missed-payment-date": "O'tkazib yuborilgan to'lov sanasi",
    "File-deleted": "Fayl oʻchirildi",
    "Russian-federation": "Rossiya Federatsiyasi",
    "Months": "oy",
    "Month": "oy",
    "24-months": "24 oy",
    "Successfully-deleted": "Muvaffaqiyatli oʻchirildi",
    "This-functionality-is-being-improved-and-will-soon-be-available-in-a-new-version": "Bu funksiya takomillashtirilmoqda va tez orada yangi versiyada taqdim etiladi. Agar kerak bo'lsa, quyidagi havolani ochib, eski versiyada kerakli operatsiyani bajarishingiz mumkin",
    "in-the-browser": "brauzerda {browser}",
    "sec": "sek",
    "Are-you-sure-you-want-to-do-this?": "Haqiqatan ham buni qilmoqchimisiz?",
    "Insufficient-funds": "Mablag'lar yetarli emas",
    "Send-error": "Yuborish xatosi",
    "Withdrawn": "Olib tashlangan",
    "Partially-withdrawn": "Qisman olib tashlangan",
    "Revocation-error": "Bekor qilish xatosi",
    "Moved-to-K2": "K2 ga ko'chirildi",
    "Without-execution-due-to-failure-to-appear": "Ko'rinmaslik tufayli ijro etilmasdan. sinf",
    "Returned-due-to-clients-no-show": "Mijoz ko'rsatilmaganligi sababli qaytarildi",
    "Partially-rejected-and-paid": "Qisman rad etilgan va to'langan",
    "Partially-rejectedand-in-K2": "K2 da qisman rad etilgan",
    "The-deadline-for-transfer-for-consideration-has-expired": "Ko'rib chiqish uchun topshirish muddati tugadi",
    "Completed2": "Bajarildi",
    "Refusal": "Rad etish",
    "Partially-completed2": "Qisman yakunlandi",
    "Without-execution-due-to-failure-to-appear2": "Ko'rinmaslik tufayli ijro etilmasdan. cl",
    "Move-to-the-next-step": "Keyingi bosqichga o'ting",
    "403 Forbidden": "403 - kirish taqiqlangan",
    "Some of the doors are always close to you": "Kechirasiz, sizda bu sahifaga kirish uchun ruxsat yo'q",
    "Go back": "Orqaga qaytish",
    "To-main": "Bosh sahifaga qaytish",
    "404 Not Found": "404 - sahifa topilmadi",
    "You know life is always ridiculous": "Siz soʻragan sahifa mavjud emas. U eskirgan, o'chirilgan yoki manzil satriga noto'g'ri manzil kiritilgan bo'lishi mumkin.",
    "Internal Error": "500 - server xatosi",
    "Internal error may prove that you need hiring more developers": "Serverda kutilmagan xatolik yuz berdi. Iltimos, kuting, tez orada tuzatiladi.",
    "Open-until-date": "{date} gacha ochiq",
    "Month2": "oy",
    "Month-plural": "{n} oy | {n} oy | {n} oy | {n} oy",
    "type-of-turnover": "Aylanma turi",
    "additional-params-sort": "Qo'shimcha saralash imkoniyatlari",
    "Amount-of-credit": "Kredit miqdori",
    "Credit-account": "Kredit hisobi",
    "virtual-signature-created": "virtual-signature-created",
    "something-went-wrong": "something-went-wrong",
    "hint": "hint",
    "Start-making-money-on-the-Uzum-Market": "Uzum market da pul ishlashni boshlang!",
    "Open-a-store-right-now": "Hoziroq do'kon oching",
    "Open": "Ochish",
    "revenues": "Daromad",
    "for-period": "Davr uchun",
    "overall-fixex-payments": "Jami belgilangan toʻlovlar soʻmda",
    "after-inquiries-there-will-be-a-sum": "Birinchi arizalarni to'ldirgandan so'ng, bu erda to'lanadigan dastlabki miqdor paydo bo'ladi.",
    "successfull-inquiries": "Muvaffaqiyatli ilovalar",
    "fixed-payment": "Ruxsat etilgan to'lov",
    "Delete-all": "Hammasini õchirish",
    "we-could-not-create": "Ayni paytda so‘rov yarata olmadik. Keyinroq qayta urinib ko‘ring",
    "otp-signin-footer-text": "{phone} yoki {epass} yoki {virtual} bilan kiring",
    "csr-key-genitive": "Kalitning EDS",
    "signin-virtual-signature": "Virtual raqamli imzo kaliti yordamida tizimga kiring",
    "signin-virtual-signature-description": "Agar siz Styx Token Manager ishlayotgan bo'lsangiz, \"Kirish\" tugmasini bosing.",
    "enter": "Tizimga kirish",
    "or-enter-with": "yoki yordamida tizimga kiring",
    "or-with": "yoki bilan",
    "go-with-signature": "ESP kaliti bilan davom eting",
    "go-with-virtual-signature": "Imzo bilan davom eting",
    "change-phone-guide": "Raqam o'zgarishini elektron raqamli imzo kaliti yoki virtual imzo bilan tasdiqlang. \r\n\r\nRaqamli imzo kalitidan foydalanishni davom ettirish uchun flesh-diskni kalit bilan kompyuterga joylashtiring.\r\n\r\n\"Imzo bilan davom etish\" tugmasini bosing va o'zgartirish uchun virtual imzodan foydalanish uchun PIN kodni kiriting.",
    "company_name": "company_name",
    "operation_date_bank": "Bank ish kuni",
    "stats": "Statistika",
    "inn/pinfl": "TIN yoki PINFL",
    "account_opened": "Ochilgan",
    "failedToFetchInquiries": "Ilovalarni yuklab bo‘lmadi. Keyinroq qayta urinib ko‘ring",
    "business": "Biznes",
    "open-the-account": "Joriy hisob ochish",
    "your-manager": "Sizning menejeringiz",
    "Download-for-1C": "1C uchun yuklab olish",
    "opened-products-and-their-payments": "Ochiq mahsulotlar va ular uchun to'lovlar to'g'risida.",
    "search-branches": "Bank filiallarini qidiring",
    "refresh-phone-number": "Telefon raqamingizni yangilang",
    "actualize-phone-number-by-branch": "Onlayn bank xizmatidan foydalanishni davom ettirish uchun raqamingizni yangilang.\n\nBuni bizning xodimlarimiz telefon raqamingizni yangilashda yordam beradigan bank filialida amalga oshirish mumkin.",
    "phone-number-change-not-allowed": "Onlayn banking orqali telefon raqamingizni o'zgartirib bo'lmaydi.\n\nBuni bizning xodimlarimiz telefon raqamingizni yangilashda yordam beradigan bank filialida amalga oshirish mumkin.",
    "actualize-phone-number-by-signature": "Onlayn bank xizmatidan foydalanishni davom ettirish uchun raqamingizni yangilang.\n\nBuning uchun flesh-diskni kalit bilan kompyuterga joylashtiring va \"Tasdiqlash\" tugmasini bosing.",
    "confirm-with-virtual-signature-genitive": "Virtual imzo",
    "could-not-find-virtual-signature": "Virtual raqamli imzo aniqlanmadi",
    "reload-styx-try-again-or-other-auth": "Styx Token Manager-ni qayta ishga tushiring va qaytadan urinib ko'ring yoki boshqa autentifikatsiya usulidan foydalaning.",
    "could-not-create-virtual-signature-try-later": "Virtual imzo yaratib bo‘lmadi, keyinroq qayta urinib ko‘ring",
    "address-or-name-bank": "Bank manzili yoki nomi",
    "try-change-search-request": "Qidiruv soʻrovingizni oʻzgartirib koʻring",
    "bank-branches": "Bank filiallari",
    "this-device": "Ushbu qurilma",
    "Download-the-statement": "Bayonotni yuklab oling",
    "We-will-call-and-tell-you-about-the-products": "Biz sizga qo'ng'iroq qilamiz va mahsulotlar haqida aytib beramiz",
    "Leave-your-contact-information-so-that-the-manager-can-contact-you": "Menejer siz bilan bog'lanishi uchun kontakt ma'lumotlaringizni qoldiring",
    "counterparty-banner-title": "Xizmatlar bo'limida kontragentlarni tekshirish paydo bo'ldi",
    "counterparty-banner-subtitle": "Endi siz qaror qabul qilishingiz mumkin \nu haqidagi ma'lumotlarga asoslangan sherik bilan hamkorlik haqida.",
    "All-services": "Barcha xizmatlar",
    "All-services-and-facilities": "Barcha xizmatlar",
    "Checking-counterparties": "Kontragentlarni tekshirish",
    "counterparty-card-subtitle": "Hamkorlik to'g'risida qaror qabul qiling\nu haqidagi ma'lumotlarga asoslangan sherigi bilan.",
    "Send-contacts": "Kontaktlarni yuboring",
    "counterparty-search-title": "Tekshirish uchun kompaniya toping",
    "counterparty-search-subtitle": "Biz korxona va tashkilotlar to'g'risida ma'lumot olishga, sud ishlarini tahlil qilishga va davlat xaridlari bo'yicha ma'lumotlardan foydalanishga yordam beramiz",
    "first_name": "Ism",
    "second_name": "Familiya",
    "patronymic": "Familiya",
    "Thank-you": "rahmat",
    "Found-count-results": "0 ta natija topildi | {n} natija topildi | {n} ta natija topildi | {n} ta natija topildi",
    "counterparty-search-placeholder": "Kompaniyaning nomi, menejerning to'liq ismi yoki soliq identifikatsiya raqami",
    "The-manager-will-call-you-back-within-5-minutes-and-answer-your-questions": "Menejer 5 daqiqa ichida sizga qo'ng'iroq qiladi va savollaringizga javob beradi.",
    "Application-sent": "Ariza yuborildi",
    "Registered-date": "Roʻyxatdan oʻtgan: {date}",
    "Phone-number-or-login": "Telefon raqami yoki login",
    "Or": "Yoki",
    "Login-to-Uzum-Business": "Uzum Business tizimiga kiring",
    "check-counterparty-rating-modal-title": "Bu tadbirkorning barqarorlik reytingi",
    "check-counterparty-rating-modal-content": "Рейтинг формируется на электронной платформе Налогового комитета, основанный на соблюдении предпринимателем требований законодательства. \n{br}{br}\n«ААА», «АА» и «А» – Высокий{br}\n«ВВВ», «ВВ» и «В» – Средний{br}\n«ССС», «СС» и «С» – Удовлетворительный{br}\n«D» – Низкий\n{br}{br}\nДанные для рейтинга поступают через межведомственный обмен. Порядок ведения определяется Кабинетом Министров.",
    "check-counterparty-sme-popover-title": "Kichik biznesga tegishli",
    "Information-updated": "Ma'lumotlar yangilandi",
    "check-counterparty-header-bottom-info": "Ma'lumotlar MyAccountant MChJ tomonidan {date} sanasidan boshlab {link} asosida taqdim etilgan",
    "Become-a-client": "Mijoz bo'ling",
    "The-first-6-months-are-free": "Birinchi 6 oy bepul.",
    "Consultation": "Maslahat",
    "We-will-call-you-and-tell-you-about-the-product": "Biz sizga qo'ng'iroq qilamiz va mahsulot haqida xabar beramiz.",
    "View-all": "Hammasini ko'rish",
    "Become-a-bank-client": "Bank mijoziga aylaning",
    "DownloadTemplate": "Shablonni yuklab olish",
    "Company-name-or-individual-entrepreneur": "Kompaniyaning nomi yoki yakka tartibdagi tadbirkor",
    "We-are-considering-the-application": "Biz arizani ko'rib chiqmoqdamiz",
    "We-check-the-information-and-prepare-everything-for-opening-an-account": "Biz ma'lumotlarni tekshiramiz va hisob ochish uchun hamma narsani tayyorlaymiz.",
    "Enter-the-number-in-the-correct-format": "Raqamni to'g'ri formatda kiriting",
    "IIN-contains-9-digits-and-PINFL-14": "TIN 9 ta raqamdan, PINFL esa 14 ta raqamdan iborat.",
    "please-check-status-zp-vedemost-inside": "Iltimos, bayonotga kiring va xodimlarning kartalari holatini tekshiring",
    "Registered": "Roʻyxatdan oʻtgan",
    "Not-registered": "Roʻyxatdan oʻtmagan",
    "The-company-was-liquidated": "Kompaniya tugatildi",
    "Operating-company": "Operatsion kompaniya",
    "Inactive-company": "Faol bo'lmagan kompaniya",
    "Active-entrepreneur": "Faol tadbirkor",
    "Activities-are-temporarily-suspended": "Faoliyatlar vaqtinchalik toʻxtatilgan",
    "Liquidated": "Tugatilgan",
    "The-certificate-has-expired": "Sertifikat muddati tugagan",
    "there-will-be-payment-and": "To'lov kunida belgilangan to'lov, shuningdek, hisoblab chiqiladi ",
    "banks-revenue": "Bank foydasining 30%",
    "Activity": "Faoliyat",
    "Tax-regime": "Soliq rejimi",
    "Kind-of-activity": "Faoliyat turi",
    "Additional-activities": "Qo'shimcha tadbirlar",
    "Date-of-liquidation": "Tugatish sanasi",
    "Suspension-date": "To'xtatib turish sanasi",
    "VAT-payer": "QQS to'lovchi",
    "VAT-registration-date": "QQSni ro'yxatdan o'tkazish sanasi",
    "Flat-tax": "Bir tekis soliq",
    "Tax-based-on-declaration": "Deklaratsiya asosida soliq",
    "Turnover-tax": "Aylanma soliq",
    "VAT": "QQS",
    "Fixed-turnover-tax": "Ruxsat etilgan aylanma soliq",
    "check-counterparty-header-top-info-text": "Ushbu xizmat faqat Assistant Business Market MChJ tomonidan taqdim etiladi. “Kapitalbank” ATB “Qarama-qarshi tomonlarni tekshirish tizimi” orqali olingan ma’lumotlarning to‘g‘riligi va unda joylashtirilgan ma’lumotlardan foydalanish oqibatlari, shuningdek ma’lumotlarning yo‘qolishi yoki shikastlanishi, shuningdek har qanday ma’lumotlarning boshqa oqibatlari uchun javobgar emas. yuzaga kelishi mumkin bo'lgan tabiat.",
    "Tax-debt": "Soliq qarzi",
    "No-data": "Maʼlumot yoʻq",
    "To-search-results": "Qidiruv natijalari uchun",
    "You-already-have-an-account": "Sizda allaqachon hisob bor",
    "Use-your-username-and-password-to-login-to-Uzum-Business": "Uzum Business tizimiga kirish uchun foydalanuvchi nomingiz va parolingizdan foydalaning",
    "Scan-the-QR-code-to-download-the-Uzum-Business-app": "{br}Uzum Business ilovasini yuklab olish uchun QR kodni skanerlang",
    "Checked-the-application": "Ilova tekshirildi",
    "Your-company-has-been-liquidated": "Sizning kompaniyangiz tugatildi",
    "We-will-not-be-able-to-open-an-account": "Biz hisob ocholmaymiz. Agar mavjud biznesingiz bo'lsa, qo'llab-quvvatlash xizmatiga murojaat qiling.",
    "If-you-have-an-existing-business-then-contact-support": "Agar mavjud biznesingiz bo'lsa, qo'llab-quvvatlash xizmatiga murojaat qiling.",
    "Hotline": "Ishonch telefoni",
    "Chat-in-telegram": "Telegramda suhbat",
    "It-is-not-possible-to-open-an-account-remotely": "Hisobni masofadan turib ochish mumkin emas",
    "Contact-technical-support-and-we-will-help-you-figure-it-out": "Texnik qo'llab-quvvatlash xizmatiga murojaat qiling va biz sizga buni aniqlashga yordam beramiz.",
    "Founder": "asoschisi",
    "Opening-an-account": "Hisob ochish ",
    "Legal-address": "Yuridik manzil",
    "Company-address": "Kompaniya manzili",
    "Please-provide-your-actual-address": "Iltimos, haqiqiy manzilingizni kiriting",
    "If-the-address-is-determined-incorrectly-please-indicate-the-correct-one": "Agar manzil noto'g'ri aniqlangan bo'lsa, to'g'ri manzilni ko'rsating.",
    "Actual-address": "Haqiqiy manzil",
    "Revenue-in-soums-for-the-year": "Yil bo'yicha so'mda tushum",
    "Indicate-the-approximate-revenue-you-expect": "Siz kutayotgan taxminiy daromadni ko'rsating",
    "Service-tariff": "Xizmat tarifi",
    "Choose-a-plan-that-suits-your-business": "Biznesingizga mos rejani tanlang.",
    "Select-tariff": "Tarifni tanlang",
    "One-of-the-founders-is-not-a-resident-of-the-Republic-of-Uzbekistan": "Muassislardan biri O‘zbekiston Respublikasi rezidenti emas",
    "Add-access-to-an-employee": "Xodimga kirish huquqini qo'shing",
    "Remove-employee": "Xodimni o'chirish",
    "Also-takes-part-in": "Shuningdek, {name} da ishtirok etadi",
    "company-count-plural": "yo'q | {n} kompaniyalar | {n} kompaniyalar | {n} kompaniya",
    "Participates-in-only-one-company": "Faqat 1 ta kompaniyada qatnashadi",
    "Meeting-point": "Joy",
    "How-to-meet-with-a-bank-representative": "Bank vakili bilan qanday uchrashish kerak",
    "Come-to-me": "Yonimga keling",
    "I-will-come-to-the-bank-branch": "Men bank filialiga kelaman",
    "Find-out-where-exactly-to-look-for-you": "Sizni aniq qayerda qidirish kerakligini bilib oling",
    "Apartment-office": "Kvartira/Ofis",
    "Entrance": "Kirish",
    "Floor": "Qavat",
    "Intercom": "Interkom",
    "The-meeting-will-take-no-more-than-15-minutes-when-is-convenient-for-you": "Uchrashuv 15 daqiqadan ko'proq vaqtni oladi, siz uchun qachon qulay?",
    "Bank-Branch": "Bank filiali",
    "In-this-department-you-will-be-able-to-resolve-all-emerging-issues": "Ushbu bo'limda siz barcha yuzaga keladigan muammolarni hal qila olasiz.",
    "Completely-satisfied": "To'liq qoniqdim",
    "Partially-satisfied": "Qisman qoniqdim",
    "Meeting-date-and-time": "Uchrashuv sanasi va vaqti",
    "The-meeting-will-take-no-more-than-15-minutes": "Uchrashuv 15 daqiqadan ortiq davom etmaydi",
    "There-are-count-documents-available-on-this-case": "Bu ish uchun {count} ta hujjat mavjud",
    "Case-category": "Ishlar toifasi",
    "Court-name": "Sud nomi",
    "Plaintiff": "Da'vogar",
    "Defendant": "Ayblanuvchi",
    "Basics": "Asoslar",
    "Government-procurement": "Davlat xaridlari",
    "Court-cases": "Sud ishlari",
    "Connections": "Ulanishlar",
    "Customer": "Mijoz",
    "Provider": "Yetkazib beruvchi",
    "amount-contract-for-sum": "so‘mlik {amount} shartnoma",
    "Registration-number": "Ro‘yxatdan o‘tish raqami",
    "Registration-date": "Ro'yxatdan o'tish sanasi",
    "Registration-Center": "Ro'yxatga olish markazi",
    "Cases-involving-a-company": "Kompaniya bilan bog'liq ishlar",
    "By-founder": "Muassis tomonidan",
    "By-manager": "Menejer tomonidan",
    "By-individual-entrepreneur": "Yakka tartibdagi tadbirkor tomonidan",
    "Founders": "Ta'sischilar",
    "OPF": "OPF",
    "SOOGU": "SOOGU",
    "Statistics-codes": "Statistik kodlar",
    "About-registration": "Ro'yxatdan o'tish haqida",
    "Counterparty": "Qarama-qarshi tomon",
    "Category": "Turkum",
    "SME": "KO'K",
    "Certificate-number": "Sertifikat raqami",
    "Start-of-activity": "Faoliyat boshlanishi",
    "End-of-activity": "Faoliyatning tugashi",
    "could-not-upload-try-again": "Fayl yuklanmadi, qayta urinib ko‘ring.",
    "email-not-required": "Elektron pochta - ixtiyoriy",
    "your-inquiry-is-created": "Hisob ochish uchun ariza yaratildi",
    "manager-will-contact-you-shortly": "Bank menejeri qisqa vaqt ichida mijoz bilan bog'lanadi - vaziyatni kuzatib boring.",
    "LLC": "OOO",
    "IE": "IP",
    "Transfers-to-other-banks": "Boshqa banklarga o'tkazmalar",
    "Payment-fees": "To'lovlar uchun komissiya",
    "SWIFT-transfers": "SWIFT o'tkazmalari",
    "Cash-withdrawal-at-the-cash-desk": "Kassada naqd pul olish",
    "Acquiring": "Sotib olish",
    "Transfers-via-Kapitalbank": "Kapitalbank orqali pul o'tkazmalari",
    "Supervisor": "Nazoratchi",
    "search-counterparty-drawer-footer-text": "Ushbu xizmat faqat Assistant Business Market MChJ tomonidan taqdim etiladi. “Kapitalbank” ATB “Qarama-qarshi tomonlarni tekshirish tizimi” orqali olingan ma’lumotlarning to‘g‘riligi va unda joylashtirilgan ma’lumotlardan foydalanish oqibatlari, shuningdek ma’lumotlarning yo‘qolishi yoki shikastlanishi, shuningdek har qanday ma’lumotlarning boshqa oqibatlari uchun javobgar emas. yuzaga kelishi mumkin bo'lgan tabiat.",
    "Payment-approved": "Toʻlov tasdiqlangan",
    "Payment-signed": "To'lov imzolandi",
    "to-account": "hisobiga",
    "swift-transfer": "SWIFT - uzatish",
    "After-sending-to-bank-payment-will-be-displayed-in-history-section": "To'lovni bankka yuborganingizdan so'ng\r\nTarix bo'limida paydo bo'ladi.",
    "Go-to-history": "Tarixga o'ting",
    "no-payments-for-sign": "Sizda hali imzolangan toʻlovlar yoʻq",
    "create-new-payment-he-will-be-here-for-sign": "Yangi toʻlov yarating, u imzo uchun shu yerda koʻrinadi",
    "if-no-virtual-signature": "Agar sizda virtual imzo bo'lmasa",
    "payments-on-sign": "To'lovlar imzolangandan keyin",
    "sign-documents-by-signature": "Hujjatlarni virtual imzo yoki raqamli imzo kaliti bilan imzolang.",
    "do-you-really-want-delete-this-document": "Haqiqatan ham bu hujjatni oʻchirib tashlamoqchimisiz?",
    "signing": "Imzolash uchun",
    "counterparty-empty-founders": "Boshqa kompaniyalarda ta'sischi yoki menejer sifatida ishtirok etish haqida ma'lumot yo'q.",
    "Create-Bank24-report": "Bank24 hisobotini yarating",
    "counterparty-deal-subtitle": "{dealDate} dan {lotDisplayNo} raqami",
    "current-payrolls": "Joriy bayonotlar",
    "payrolls-bank24": "Bank24 dan Vedomosti",
    "Statement-of-documents-posted-for-the-period": "Davr uchun to'ldirilgan hujjatlar to'g'risidagi bayonot (xls)",
    "Balance-turnover-statement": "Balans (txt)",
    "Statement-of-personal-accounts": "Shaxsiy hisoblardan ko'chirma (txt)",
    "Statement-of-personal-accounts-extended": "Shaxsiy hisoblar bo'yicha kengaytirilgan ko'chirma (txt)",
    "Reports-history-of-cards-and-terminals": "Hisobotlar (kartalar va terminallar tarixi)",
    "Type-of-statement": "Bayonot turi",
    "Terminal-and-card-account": "Terminal va karta hisobi",
    "Terminal-UPI": "Terminal (UPI)",
    "Terminal-HUMO": "Terminal (HUMO)",
    "At-approving-number": "{n} bayonotida",
    "Approved-at": "{date}, {time} da tasdiqlangan",
    "Signed-at": "{date}, {time} da tizimga kirgan",
    "date-idn-or-number": "Sana, identifikatsiya raqami yoki raqami",
    "send-on-account": "Qaysi hisobga yuborishim kerak?",
    "currency-rate-and-sum": "Valyuta kursi va miqdori",
    "currency-rate": "Valyuta kursi",
    "contract-info": "Shartnoma ma'lumotlari",
    "upload-template-we-will-create-docs-automatically": "Shablonni kompyuteringizda to'ldirgandan so'ng, faylni yuklash oynasiga torting. Hujjat(lar)ni avtomatik ravishda yaratamiz.",
    "Statements-ibk": {
      "account-statement": "Hisob qaydnomasi (eski namuna)",
      "payment-documents": "To'lov hujjatlari (eski namuna)",
      "account-statement-for-period": "Davr uchun hisobdan ko'chirma (eski namuna)",
      "statement-of-documents-processed": "Davr uchun to'ldirilgan hujjatlar to'g'risidagi bayonot (eski namuna)",
      "certificate-of-account-operation": "Hisobni yuritish to'g'risidagi guvohnoma (eski namuna)",
      "saldo": "Balans (eski namuna)",
      "statement-of-personal-accounts": "Shaxsiy hisoblarni chiqarish (eski namuna)",
      "statement-of-personal-accounts-extended": "Kengaytirilgan shaxsiy hisobdan ko'chirma (eski namuna)",
      "terminal": "Terminal va karta hisobi (eski namuna)",
      "terminal-upi": "UPI terminali (eski model)",
      "terminal-humo": "HUMO terminali (eski model)",
      "terminal-report": "Terminallar haqida hisobot (eski namuna)"
    },
    "created-singular": "Yaratilgan",
    "pinfl": "PINFL",
    "currency-amount-and-sum": "Valyuta kursi va miqdori",
    "Not-enough-money-balance-is": "Mablag'lar yetarli emas. Hisob balansi:",
    "x-no-more-y-mb": "{0} {1} MB dan oshmasligi kerak",
    "countries-receiver-bank": "Qabul qiluvchi bank davlatlari",
    "search-by-contry": "Mamlakat bo'yicha qidirish",
    "New-Bank24-report": "Yangi Bank24 hisoboti",
    "These-are-old-reports-transferred-without-changes-from-the-old-personal-account": "Bu eski shaxsiy hisobdan o'zgartirilmagan eski hisobotlar.",
    "Loan-repayment-account-if-any": "Kreditni to'lash uchun hisob (agar mavjud bo'lsa)",
    "written-off": "Hisobdan chiqariladi",
    "Sort-type": "Saralash turi",
    "You-can-select-multiple-terminals": "Siz bir nechta terminallarni tanlashingiz mumkin.",
    "Data-will-be-updated-after-sending-to-bank": "Ma'lumotlar bankka yuborilgandan so'ng yangilanadi.",
    "Swift-bic-invalid": "8 yoki 11 ta belgidan iborat boʻlishi kerak",
    "amount-and-source": "Miqdori va manbasi ",
    "will-enroll": "Roʻyxatdan oʻtgan",
    "Account-or-card-number": "Hisob/karta raqami",
    "more-count": "yana {n}",
    "conversion-course-will-be-set-while-proceed": "Valyuta kursi to'lov vaqtida belgilanadi.",
    "create-document-by-template-failed": "Shablon yordamida hujjat yaratib bo‘lmadi",
    "get-sms-code": "SMS orqali kodni oling",
    "cant-get-sms": "SMS qabul qila olmayapsizmi?",
    "get-email-code": "Kodni elektron pochta orqali oling",
    "enter-code-from-email": "Elektron pochtangizdan kodni kiriting",
    "we-sent-code-on-email": "Kodni elektron pochta orqali yubordik",
    "or": "yoki",
    "change-phone-guide-no-virtual": "Raqam o'zgarishini raqamli imzo kaliti bilan tasdiqlang. \n\nRaqamli imzo kalitidan foydalanishni davom ettirish uchun flesh-diskni kalit bilan kompyuterga joylashtiring.",
    "Add-an-employee": "Xodimni qo'shing",
    "provided_access_for_partner": "Hamkor hisobiga kirish ochiq",
    "provided_access_details": "Mijozlarni taklif qilish va bank bilan pul ishlash uchun hamkor hisobingizga kiring",
    "provided_access_checkout_to_profile": "Hamkor profiliga o'ting",
    "receiver-card-number": "Qabul qiluvchining karta raqami",
    "cardholder-name": "Egasining ismi",
    "treasury-account-or-name": "G'aznachilik hisobi yoki nomi",
    "treasury-account-selecting": "G'aznachilik hisobini tanlash",
    "payer-info": "To'lovchi tafsilotlari",
    "who-sends": "Kim yuboradi",
    "payer-name": "To'lovchining ismi",
    "payer-number": "Soliq to'lovchi raqami",
    "payer-address": "To'lovchining manzili",
    "sender-bank-name": "Yuboruvchining bank nomi",
    "sender-bank-code": "Yuboruvchining bank kodi",
    "operation-info": "Tranzaksiya ma'lumotlari",
    "imported-documents-amount": "Yuklab olingan hujjatlar miqdori",
    "plural-payments": "{n} toʻlovlar | {n} toʻlov | {n} toʻlov | {n} toʻlovlar",
    "who-pays-commission": "Komissiyani kim to'laydi?",
    "additional-payment-details": "Qo'shimcha to'lov tafsilotlari",
    "intermediary-bank-if-any": "Vositachi bank, agar mavjud bo'lsa",
    "by-swift-code": "SWIFT kodi orqali",
    "manually": "Qo'lda",
    "for-period-from-to": "{0} dan {1} gacha bo'lgan davr uchun",
    "from-date": "{0} uchun",
    "sign-with-signature-key": "EDS kaliti yordamida imzolash",
    "total-sum-payments": "To'lovlarning umumiy miqdori",
    "total-amount-payments": "To'lovlar soni",
    "select-intermediary-bank": "Vositachi bankni tanlash",
    "intermediary-bank": "Vositachi bank",
    "bank-name-swift-code-or-address": "Bank nomi, SWIFT kodi yoki manzili",
    "swift-code": "SWIFT kodi",
    "intermediary-bank-country": "Vositachi bank mamlakati",
    "select-intermediary-bank-country": "O'tkazma vositachi bankining mamlakatini tanlash",
    "search-by-number-name-or-code": "Raqam, ism yoki kod bo'yicha qidiring",
    "bic-code": "BIC kodi",
    "intermediary-name": "Vositachi nomi",
    "receiver-bank-account": "Qabul qiluvchining bank hisobi",
    "receiver-bank-country": "Pul o'tkazmasini oluvchining banki joylashgan mamlakat",
    "select-receiver-bank-country": "Pul oʻtkazmasini oluvchining bank mamlakatini tanlash",
    "signed-payments-amount": "Imzolangan to'lovlar soni",
    "not-signed-payments-amount": "Imzolanmagan to'lovlar soni",
    "payments": "To'lovlar",
    "signed": "imzolangan",
    "some-payments-not-signed-try-again": "Ba'zi to'lovlar imzosiz qoldi. Qayta imzolashga harakat qiling.",
    "approved": "tasdiqlangan",
    "approved-payments-amount": "Tasdiqlangan to'lovlar soni",
    "not-approved-payments-amount": "Tasdiqlanmagan to'lovlar soni",
    "receiver-bank-name-and-address": "Qabul qiluvchi bankning nomi va manzili",
    "approve-with-signature-key": "Kalitning raqamli imzosi yordamida tasdiqlash",
    "select-all-payments": "Barcha to'lovlarni tanlang",
    "select-all-payments-on-current-page": "Joriy sahifadagi barcha to'lovlarni tanlang",
    "cancel-select": "Tanlovni bekor qilish",
    "currency-operation-code": "Valyuta tranzaksiya kodi",
    "swift-sha": "SHA - oluvchi va to'lovchi",
    "swift-our": "BIZNING - To'lovchi",
    "Almost-ready-lets-complete-the-check": "Deyarli tayyor, tekshirishni tugatamiz",
    "receiver-name-and-address": "Qabul qiluvchining ismi va manzili",
    "correspondent-account": "Korrespondent hisob",
    "receiver-kpp": "Qabul qiluvchining nazorat punkti",
    "Show-other-rates": "Boshqa tariflarni ko'rsatish",
    "Hide-other-rates": "Boshqa tariflarni yashirish",
    "not-signed": "Obuna boʻlmagan",
    "not-approved": "Tasdiqlanmagan",
    "all-payments-not-signed-try-again": "Barcha to'lovlar imzosiz qoldi. Qayta imzolashga harakat qiling.",
    "your-signature-expired": "Raqamli imzo kalitingiz eskirgan",
    "go-ask-bank": "Bankingizga murojaat qiling.",
    "ask-bank": "Bank bilan bog'laning",
    "enter-zero-for-receiver-outside-russia": "Rossiya Federatsiyasidan tashqarida qabul qiluvchi uchun 0 qiymatini kiriting",
    "counterparty-deals-not-found": "{name} sifatida davlat xaridlarida ishtirok etish haqida ma'lumot yo'q.",
    "by-customer": "mijoz",
    "by-provider": "yetkazib beruvchi",
    "If-you-need-to-reschedule-meeting-please-contact-support-by-phone-or-telegram": "Uchrashuvni boshqa vaqtga ko'chirish kerak bo'lsa, telefon yoki telegram orqali qo'llab-quvvatlash xizmatiga murojaat qiling",
    "What-you-need-to-have-with-you": "Siz bilan nima bo'lishi kerak",
    "Dont-forget-to-prepare-the-documents-from-the-list-so-that-the-meeting-goes-successfully-and-as-quickly-as-possible": "Uchrashuv muvaffaqiyatli va imkon qadar tezroq bo'lishi uchun hujjatlarni ro'yxatdan tayyorlashni unutmang.",
    "Everything-worked-out-see-you-soon": "Hammasi yaxshi bo'ldi, tez orada ko'rishguncha",
    "On-the-appointed-day-we-will-call-and-remind-you-about-the-meeting": "Belgilangan kuni sizga qo'ng'iroq qilamiz va uchrashuvni eslatamiz.",
    "party-id": "Partiya identifikatori",
    "We-could-not-get-through-to-you": "Siz bilan bog‘lana olmadik",
    "Contact-bank-employees-by-phone-or-telegram-to-continue-opening-an-account": "Hisob ochishni davom ettirish uchun bank xodimlariga telefon yoki telegram orqali murojaat qiling.",
    "An-additional-meeting-was-scheduled": "Qo'shimcha yig'ilish rejalashtirilgan edi",
    "Edit-access": "Kirishni o'zgartirish",
    "Add-access": "Kirish qo'shish",
    "symbols": "belgilar | belgisi | belgisi | belgilar",
    "Access-to-payments": "To'lovlarga kirish",
    "Select-terminal": "Terminalni tanlang",
    "Contract-offer": "Taklif shartnomasi",
    "Select-date-and-time": "Sana va vaqtni tanlang",
    "Select-a-meeting-option": "Uchrashuv variantini tanlang",
    "Select-this-date-and-time": "Ushbu sana va vaqtni tanlang",
    "First-half-of-the-day": "Kunning birinchi yarmi 10:00 dan 13:00 gacha",
    "Second-half-of-the-day": "Peshindan keyin 13:00 dan 16:00 gacha",
    "Select-a-meeting-date": "Uchrashuv sanasini tanlang",
    "Select-a-meeting-time": "Uchrashuv vaqtini tanlang",
    "Company": "Kompaniya",
    "Meeting": "Uchrashuv",
    "Select-the-expected-revenue-for-the-year": "Yil uchun taxminiy daromadni tanlang",
    "Select-a-tariff": "Tarifni tanlang",
    "Service-rates": "Xizmat tariflari",
    "Status-code": "Status kodi",
    "The-meeting-was-a-success": "Uchrashuv muvaffaqiyatli o'tdi",
    "We-prepare-documents-and-prepare-the-account-for-opening-this-takes-no-more-than-one-working-day": "Biz hujjatlarni rasmiylashtiramiz va ochish uchun hisobni tayyorlaymiz - bu kerak \n1 ish kunidan oshmasligi kerak. ",
    "sum-currency": "so'm",
    "number-of": "{end} dan {start}",
    "next": "Keyinchalik",
    "could-not-get-manager": "Sizga tayinlangan menejer yo'q",
    "recipient-bank-copied-successfully": "Qabul qiluvchining banki muvaffaqiyatli nusxalandi",
    "failed-to-copy-recipient-bank": "Qabul qiluvchi bankdan nusxa olinmadi",
    "symbols-count": "symbols-count",
    "munis-modal-description": "O‘zbekiston Respublikasi Davlat bojxona qo‘mitasining talabiga binoan 2024-yil 13-avgustdan boshlab bojxona organlariga to‘lovlar MUNIS tizimi orqali amalga oshirilishi kerak, bunda bojxona kodini ma'lumotnoma bo‘yicha ko‘rsatish talab etiladi. MUNIS to‘lovlari 9:00 dan 16:30 gacha amalga oshirilishi mumkin.",
    "customs-code-directory": "Bojxona kodlari ma'lumotnomasi:",
    "City": "City",
    "Street-and-house": "Street-and-house",
    "If-you-have-a-chief-accountant-and-he-is-on-the-company-staff-then-add-access-to-him": "If-you-have-a-chief-accountant-and-he-is-on-the-company-staff-then-add-access-to-him",
    "my-money": "my-money",
    "today": "today",
    "finance-info": "finance-info",
    "expenditure": "expenditure",
    "cards": "cards",
    "invite_to_partnership": "invite_to_partnership",
    "become_bank_partner": "become_bank_partner",
    "partnership-reward": "partnership-reward",
    "partnership-interest": "partnership-interest",
    "partnership-online": "partnership-online",
    "partnership-account": "partnership-account",
    "partnership-go-to-site": "partnership-go-to-site",
    "after_inquiry_we_will_answer": "after_inquiry_we_will_answer",
    "name-and-family-name": "name-and-family-name",
    "organization_tin": "organization_tin",
    "partnership_inquiry": "partnership_inquiry",
    "partnership-inquiry-sent": "partnership-inquiry-sent",
    "couldnt-create-inquiry-try-again-later": "couldnt-create-inquiry-try-again-later",
    "account-name": "account-name",
    "exchange-rate-for": "exchange-rate-for"
  },
  "en": {
    "workWithDocumentsTitle": "Work with documents",
    "tax": "Tax",
    "print": "Print",
    "importDocuments": "Import documents",
    "override": "Cancel",
    "createDocumentByTemplate": "Create document from template",
    "numberId": "ID number",
    "documentDate": "Document date",
    "templateName": "Template name",
    "signDK": "D/K indicator",
    "createDocumentCopy": "Create a copy of the document",
    "debit": "Debit",
    "credit": "Credit",
    "other": "Other",
    "senderInn": "Sender's TIN ((Taxpayer Identification Number))",
    "senderName": "Sender's name",
    "receiverName": "Recipient's name",
    "unpostedBalance": "Unposted balance",
    "accountBalance": "Account balance",
    "selectCard": "Card number",
    "replenishmentTypeCard": "To card",
    "treasuryAccount": "Treasury account {0}",
    "findTreasuryAccount": "Find treasury account",
    "budgetReceiverAccount": "Budget Recipient's account",
    "inn": "TIN",
    "senderInnOrPinfl": "TIN or PINFL of the sender",
    "receiverInnOrPinfl": "TIN or PINFL of the recipient",
    "findBudgetReceiverAccount": "Find the budget recipient's account",
    "sum": "Amount",
    "paymentDestinationCode": "Payment purpose code",
    "sumInWords": "Amount in words",
    "printDocument": "Print document No.{n}",
    "copiesNumber": "Number of copies:",
    "printCopies": "no copies | {n} copy | {n} copies | {n} copies",
    "budgetReceiversSearch": "Search for budget recipients",
    "enterAccountOrInnOrName": "Enter the account, TIN or name of the budget recipient",
    "treasuryReceiversSearch": "Search for treasury accounts",
    "treasurySearchLabel": "Enter account, mask, unique number or treasury name",
    "documentNumberHint": "The document number is generated automatically. You can change it.",
    "login": "Login",
    "password": "Password",
    "forgot-password": "Forgot password",
    "sign-in": "Sign in",
    "feedback-form": "Feedback form",
    "main-page": "Main page",
    "work-with-documents-online-24": "Work with documents online 24/7",
    "documents": "Documents",
    "additionally": "Additionally",
    "reports": "Reports",
    "letters": "Letters",
    "settings": "Settings",
    "logout": "Logout",
    "fromDocumentDate": "From the date of the document",
    "documentType": "Document type",
    "documentNumber": "Document Number",
    "byDocumentDate": "By document date",
    "destinationCode": "Destination code",
    "filterTemplates": "Filter Templates",
    "success": "Successfully!",
    "search": "Search",
    "clear": "Clear",
    "cancel": "Cancel",
    "find": "Find",
    "delete": "Delete",
    "save": "Save",
    "saveAndCreateAnother": "Save and create new",
    "update": "Update",
    "areYouSure": "Are you sure?",
    "validation": {
      "required": "Field cannot be empty",
      "min": "Minimum number of characters: {length}!",
      "max": "The field must contain no more than {length} characters!",
      "inputFieldRowsCountMax": "The field must contain no more than {length} lines",
      "inputFieldRowLengthMax": "The row {row} must contain no more than {length} characters",
      "receiverAccountEqualToSenderAccount": "validation.receiverAccountEqualToSenderAccount",
      "receiverAccountNumberInvalid": "Bank code (MFO) or account entered incorrectly",
      "receiverAccountNumberInvalidLength": "validation.receiverAccountNumberInvalidLength",
      "budgetAccountNumberInvalid": "The account number must contain 27 digits",
      "treasuryAccountNumberInvalid": "The account number must contain 25 digits",
      "innInvalid": "Incorrect TIN",
      "swiftInnInvalid": "TIN can contain 10 or 12 characters",
      "pinflInvalid": "Incorrect PINFL",
      "cardNumberLength": "The card number must contain 16 digits",
      "cardNumberInvalid": "The card number was entered incorrectly",
      "enterLogin": "Enter login",
      "enterPassword": "Enter password",
      "invalidLogin": "Incorrect login",
      "invalidPassword": "Incorrect password",
      "enterOldPassword": "Enter old password",
      "enterNewPassword": "Enter a new password",
      "repeatEnterNewPassword": "Re-enter your new password",
      "confirmPassword": "Password doesn't match",
      "invalidOtpCode": "Incorrect SMS code",
      "firstName": "Enter your name",
      "phone": "Enter your phone number",
      "email": "Enter email",
      "invalidEmail": "Incorrect Email",
      "idnInvalid": "IDN must contain 27 digits",
      "inputFieldInvalid": "Field contains invalid characters",
      "amountZero": "Amount cannot be zero",
      "maximum": "Maximum character limit exceeded",
      "invalidFormat": "Invalid  format",
      "numbersOnly": "The field must contain numbers only",
      "innOrPinflInvalid": "validation.innOrPinflInvalid",
      "minLength": "validation.minLength",
      "receiverAccountMainInvalid": "The field must contain the company's main account",
      "paymentPurpose": "validation.paymentPurpose"
    },
    "branch": "Branch",
    "account": "Account",
    "accounts": "Accounts",
    "name": "Name",
    "dayStartBalance": "Day start balance",
    "dayEndBalance": "Day end balance",
    "volumeDebit": "Volume debit",
    "volumeCredit": "Volume Credit",
    "state": "State",
    "statementPrevious": "Statement for the previous day",
    "statementCurrent": "Statement for the current day",
    "statementPeriod": "Statement for the period",
    "lastOperationDate": "Last operation date",
    "openingDate": "Opening date",
    "closingDate": "Closing date",
    "filter": "Filter",
    "accountOrName": "Account or name",
    "accountInformation": "Account information",
    "payrolls": "Payrolls",
    "payroll": "Payroll",
    "details": "Details",
    "sendToBank": "Send to bank",
    "employeesList": "A list of employees",
    "orderNumber": "Order number",
    "orderCardNumber": "The number of order card",
    "paymentPurpose": "Purpose of payment ",
    "year": "Year",
    "month": "Month",
    "loadDate": "Load date",
    "file": "File",
    "fileType": "File type",
    "payrollsFilter": "Payrolls' filter",
    "for": "For",
    "description": "Description",
    "mainInformation": "Main information",
    "unloadTemplateFile": "Upload template file",
    "uploadFile": "Upload file",
    "unloadFileForPrint": "Upload file for printing",
    "statement": "Statement",
    "encoding": "Encoding",
    "user": "User",
    "totalSumPayroll": "Total sum of payroll",
    "employeeAccount": "Employee account",
    "employeeName": "Employee name",
    "embossedName": "Embossed name",
    "swiftAbbr": "SWIFT",
    "resetFilter": "Reset filter",
    "add": "Add",
    "attachFiles": "Attach files",
    "saveAsTemplate": "Save as template",
    "paymentDocument": "Payment document",
    "messageType": "Message type (MT)",
    "senderBank": "Sender's bank",
    "swift": {
      "senderBicCode": "Sender's BIC code&nbsp;(52A)",
      "senderBank": "Sender's bank&nbsp;(52A)",
      "receiverBank57A": "Receiver's bank&nbsp;(57A)",
      "intermediaryBank56A": "Intermediary bank&nbsp;(56A)",
      "intermediaryBankCountry": "Intermediary bank country&nbsp;({field})",
      "receiverBankCountry": "Receiver bank country&nbsp;({field})",
      "valueDate": "Value date&nbsp;(32A)",
      "amount": "Transfer amount&nbsp;(32A)",
      "currency": "Transfer currency&nbsp;(32A)",
      "sender50A": "Sender's name and address &nbsp;(50A)",
      "senderAccount": "Sender's account&nbsp;(50A)",
      "intermediaryBank": "Intermediary bank&nbsp;(56)",
      "receiverBank": "Receiver's bank &nbsp;(57)",
      "intermediaryBankName": "Name of intermediary bank&nbsp;(56A)",
      "receiverAccount": "Receiver's account &nbsp;(59K)",
      "receiver59A": "Receiver's name and address &nbsp;(59A)",
      "remittanceInformation": "Payment details&nbsp;(70)",
      "chargePayer": "Cost details&nbsp;(71A)",
      "beneficiaryCorrespondentBank": "Correspondent bank of the recipient's bank &nbsp;(56A)",
      "bicCodeField": "BIC code&nbsp;({field})",
      "receiverBankName": "Name of the recipient's bank(57A)",
      "bankDataField": "{field} – Bank details",
      "bankDataNameAndAddressField": "{field} – Bank details - Name and address",
      "nameAndAddressField": "Bank name and address&nbsp;({field})",
      "BEN": "BEN Recipient",
      "OUR": "OUR Sender",
      "SHA": "SHA Both",
      "operation": {
        "type1": "1 - Import",
        "type2": "2 - Loan guaranteed",
        "type3": "3 - Non-guaranteed loan",
        "type4": "4 - Repatriation",
        "type5": "5 - Other",
        "type6": "6 - Export",
        "type7": "7 - On account until clarification"
      },
      "prepaymentForGoods": "10100 - Advance payment for goods",
      "paymentForGoods": "10200 - Payment for goods",
      "prepaymentForServices": "20100 - Prepayment for services",
      "paymentForServices": "20200 - Payment for services",
      "other": "99090 - Other",
      "currencyOperationCode": "Currency operation code&nbsp;(70)",
      "bicNumber": "Number (BIC)",
      "correspondentAccountNumber": "Correspondent account&nbsp;(57D)",
      "receiverKpp": "Receiver KPP"
    },
    "receiverBank": "Receiver's bank",
    "senderCountry": "Sender country",
    "receiverCountry": "Receiver country",
    "comment": "A comment",
    "swiftFilter": "SWIFT filter",
    "newSwiftTransfer": "New SWIFT transfer",
    "operationType": "Operation type",
    "senderAccount": "Sender's account",
    "receiverAccount": "Receiver's account",
    "receiver": "Receiver",
    "senderToReceiverInformation": "Additional payment details &nbsp;(72B)",
    "idn": "IDN {n}",
    "paymentTerms": "Terms of payment",
    "transactionData": "Transaction data",
    "template": "Template",
    "templates": "Templates",
    "templateComment": "Comment on the template",
    "sender": "Sender",
    "bankCorrespondent": "Bank - correspondent",
    "correspondentBank": "Correspondent bank (Field:&nbsp;56D)",
    "months": {
      "january": "January",
      "february": "February",
      "march": "March",
      "april": "April",
      "may": "May",
      "june": "June",
      "july": "July",
      "august": "August",
      "september": "September",
      "october": "October",
      "november": "November",
      "december": "December"
    },
    "detailedInformation": "Detailed information",
    "parameters": "Parameters",
    "extractOnTheDate": "Extract on trhe date",
    "accountMask": "Account mask",
    "sorting": "Sorting",
    "MFO": "MFO ",
    "startingDate": "Starting date",
    "endDate": "End date",
    "turnoverDebit": "Turnover debit",
    "turnoverCredit": "Turnover credit",
    "edit": "Edit",
    "Upload-the-report-to-t": "Upload the report to",
    "Print-the-report": "Print report",
    "Employee-filter": "Employee filter",
    "documentsInBank": "Documents in the bank",
    "Document_templates": "Document templates",
    "Do-you-want-to-leave": "Do you really want to leave? You have unsaved changes!",
    "document": "Document #{0}",
    "documentHistory": "Document action history",
    "Modal-to-protect-the-entered-data": "Order number of the employee's card",
    "income": "income",
    "soato": "SOATO",
    "uniqueAccountNumber": "Unique account number",
    "treasuryName": "Treasury name",
    "Search-for-templates": "Search for templates",
    "templateDetails": "Template details",
    "newTemplate": "New  template",
    "numberAndContractDate": "Number and contract date",
    "idnContract": "IDN of the contract",
    "number": "Number",
    "date": "Date",
    "failedToSign": "Failed to sign",
    "failedToDelete": "Failed to delete",
    "selectAccountToCreatePaymentOrder": "Select an account to create a payment order",
    "salaryPayrollSentFromAnotherBank": "Payroll was sent from another bank",
    "transitAccountForSalary": "Transit account for salary",
    "clientAccount": "Client account",
    "paymentOrderNumber": "Payment order number",
    "transitAccountBalance": "Transit account balance",
    "payrollAmount": "Payroll amount",
    "paymentPurposeNote": "*Note: in the purpose of payment you must indicate the contract number, salary period, name of payment, statement number. Otherwise, credit to your PC will not be processed.",
    "sendPayroll": "Send payroll",
    "sendPayrollToBank": "Send the payroll to the bank",
    "Support-service-24-7": "Support service 24/7",
    "Login": {
      "title": "Login to your account",
      "desc": "Please enter the login and password issued at the bank branch."
    },
    "All-documents": "All documents",
    "All-accounts": "All accounts",
    "auth-form": {
      "title": "Login to your account"
    },
    "Amount": "Amount cannot be zero",
    "Show-all": "Show all",
    "newDocument": "New document",
    "General-information": "General information",
    "List-of-banks": "List of banks",
    "Payment-destination-codes": "Payment destination codes",
    "References": "References",
    "Code": "Code",
    "Code-or-name": "Code or name",
    "Sending-a-payment-via-the-Online-24-7-service": "Sending a payment via the Online service 24/7",
    "The-commission-for-online-payment-24-7-may-vary": "Fees for 24/7 Online payments may vary.",
    "exchangeRates": "Exchange rates",
    "currentDate": "Current date",
    "businessDay": "Business day",
    "businessDayState": "Business day state",
    "contacts": "Contacts",
    "Password-change": "Password change",
    "Change-password": "Change password",
    "Old-password": "Old Password",
    "New-password": "New Password",
    "Repeat-password": "Repeat password",
    "The-password-has-been-changed": "Password has been changed",
    "Enter-the-code-from-the-SMS": "Enter the code from SMS",
    "We-sent-an-SMS-with-a-code-to-the-number": "We sent an SMS with a code to the number",
    "The-code-from-the-SMS": "The code from the SMS",
    "You-can-repeat-the-sending-via": "You can resend via",
    "Plural-after-second": "{n} seconds | {n} second | {n} seconds | {n} seconds",
    "Repeat": "Repeat password",
    "accountStatement": "Account statement",
    "byDocumentNumber": "By document number",
    "byAccounts": "By accounts",
    "byDebit": "By debit turnover",
    "byCredit": "By credit turnover",
    "htmlReport": "Download in HTML",
    "rtfReport": "Download in RTF",
    "pdfReport": "Download in PDF",
    "wordReport": "Download in Word",
    "excelReport": "Download in Excel",
    "accountStatementForPeriod": "Account statement for the period",
    "all": "All",
    "debitCreditTurnover": "Turnover debit/Turnover credit",
    "partiallySigned": "Partially signed",
    "helpModal": {
      "title": "If something does not work or it is not clear how it works, then contact us:",
      "recomendedBrowsersText": "To work in your personal account, we recommend using one of the following browsers:"
    },
    "Employees-card-number": "Employee card number",
    "onboardingWelcomeModal": {
      "title": "Thank you for your trust and access to the new personal account for legal entities from Kapitalbank and Uzum Business.",
      "recomendedBrowsersText": "The new personal account can be used in any of the modern browsers:",
      "ieSupport": "Internet Explorer is no longer supported.",
      "advantages": "The new personal account will become more functional and convenient to use. All functions remain in the same places.",
      "changes": "The changes affected the “Working with documents 24/7” section. Now, in order to send a transfer at any time of the day, it is enough to create a regular payment document and, when creating it, specify the use of the service for sending payments 24/7."
    },
    "contactTechnicalSupport": "If you have any questions, you can contact technical support:",
    "onboardingHelpButtonText": "Help is needed!",
    "To-the-account": "To the account",
    "approve": "Approve",
    "check": "Check",
    "mfo": "MFO ",
    "currencySum": "Amount in currency",
    "totalSum": "Total sum",
    "currency": "Currency",
    "contractNumber": "Contract number",
    "operDate": "Operation date",
    "byPhone": "By phone",
    "byTelegram": "By Telegram",
    "itIsClear": "It is clear",
    "chrome": "Chrome",
    "opera": "Opera",
    "firefox": "Mozilla Firefox",
    "yandexBrowser": "Yandex browser",
    "edge": "Edge",
    "singleContactCenterNumber": "Single contact center number:",
    "technicalSupportAndAdditionalInformation": "Technical support and additional information:",
    "userSupportDepartment": "User support department",
    "workingHours": "Working hours:",
    "resendCode": "Resend the code",
    "confirmLogin": "Confirm Login",
    "confirmWithSmsCode": "Confirm with SMS code",
    "confirmWithDigitalSignature": "Confirm with digital signature",
    "confirmSecurityNote": "For security purposes, you need to confirm your account. A confirmation code will be sent via SMS to number {n}",
    "confirmWithDigitalSignatureNote": "If you do not have access to this number, you can choose an alternative confirmation using  digital signature",
    "confirmLoginByDigitalSignature": "Confirm login by  digital signature",
    "insertDigitalSignatureToken": "Insert digital signature token",
    "done": "Done",
    "createDocumentFromTemplate": "Create a document using a template",
    "send": "Send",
    "Contracts": "Contracts",
    "Name-of-the-contract-holder": "Name of the contract holder",
    "IDN": "Contract number",
    "Name-of-the-foreign-partner": "Name of foreign partner",
    "Country-of-the-foreign-partner": "Country of foreign partner",
    "Contract-number": "Contract number",
    "Date-of-conclusion-of-the-contract": "Date of conclusion of contract",
    "Sign-of-contract-activity": "Sign of contract activity",
    "MFI-code-of-the-authorized-bank/number-of-the-service-center": "MFO code of the authorized bank/service center number",
    "accountNumber": "Account number",
    "accountStatementExtended": "Personal account statement extended",
    "txtReport": "Download in TXT",
    "reportFailed": "Failed to get report",
    "Temporary-blocked-modal": {
      "title": "We have temporarily blocked your account",
      "subtitle": "To unblock your account, contact the bank support service.",
      "call-to": "Call",
      "call-to-text": "Call and tell the operator the code word. A bank employee will restore access remotely.",
      "write-to": "Write",
      "write-to-text": "Write to the bank and tell the support team the code word. A bank employee will restore access remotely.",
      "write-to-telegram": "Write to Telegram"
    },
    "Contract-details": "Contract details",
    "Additional-agreements": "Additional agreements",
    "Declarations": "Declarations",
    "Payment": "Payment",
    "Specifications": "Specifications",
    "Close": "Close",
    "Signature-token-not-found": "Signature token not found",
    "No-signature-token-message": "The digital signature key could not be found on the computer.\r\nInsert the digital signature key into your computer and try again.",
    "Contact-support-service": "If you have any questions, you can contact the support service:",
    "Contract-date": "Contract date",
    "Subject-of-the-contract": "Subject of contract",
    "Contract-currency": "Contract currency {n}",
    "Contract-amount-in-contract-currency": "Contract amount in contract currency {n}",
    "Serial-number-of-the-addendum": "Serial number of the addendum",
    "Document-status": "Document status",
    "Contract-currency-rate": "Contract currency rate",
    "Name-of-the-importer-recipient": "Name of importer (recipient)",
    "Customs-regime": "Customs regime",
    "Contract-currency-code": "Contract currency code",
    "Total-invoice-value": "Total invoice value",
    "Code-of-the-customs-post": "Customs post code",
    "Registration-number-of-the-declaration": "Declaration registration number",
    "Date-of-registration-of-the-declaration": "Declaration registration date",
    "Country-code-of-departure": "Departure country code",
    "Code-of-the-nature-of-the-transaction": "Transaction nature code",
    "Date-of-shipment-receipt-of-the-goods": "Date of shipment (receipt) of goods",
    "Name-of-the-exporter-sender": "Name of exporter (sender)",
    "Contract-Specification-number": "Contract specification number",
    "Attribute-import-export": "Attribute (import/export)",
    "Serial-number-of-the-specification-in-the-EEISVO": "Serial number of the specification in EEISVO",
    "Date-and-time-of-data-entry-into-the-EEISVO": "Date and time of data entry into EEISVO",
    "Specification-date": "Specification date",
    "Specification-amount": "Specification amount",
    "Specification-currency": "Specification currency code",
    "Attribute-goods-services": "Attribute (goods/services)",
    "Payment-date": "date of payment",
    "Payment-currency": "Payment currency",
    "Source-of-currency-funds": "Source of currency funds",
    "Receipt-amount": "Payment amount",
    "The-amount-of-receipt-for-the-goods-in-the-currency-of-the-contract": "The payment amount for goods in contract currency {n}",
    "The-amount-of-receipt-for-services-works-in-the-currency-of-the-contract": "The amount of payment for services (work) in contract currency {n}",
    "Full-name-of-the-bank-user-who-entered-edited-the-document": "Full name of the bank user who entered (edited) the document",
    "Payment-number-in-ABS": "Payment number in ABS",
    "The-serial-number-of-the-document-in-the-EEISVO": "Serial number of the document in EEISVO",
    "Setting-notifications-for-Telegram": "Setting up notifications for Telegram",
    "Additional-Information": {
      "title": "Additional information",
      "content": "This information can be used for password recovery, email and SMS mailings."
    },
    "First-name": "First name",
    "Phone": "Phone",
    "Email": "Email not required",
    "File-name": "File name",
    "Imported-documents": "Imported documents",
    "No-phone-message": "The phone number for your account was not specified during registration or is specified incorrectly. You can confirm authorization with an virtual digital key or contact technical support:",
    "Support-service-contacts": "Support service contacts:\nBy phone - {phone}\nIn Telegram - {telegram}",
    "Uploaded-files": "Uploaded files",
    "Generated-documents-according-to-the-file": "Generated documents according to file",
    "Payment-documents": "Payment documents",
    "System": "System",
    "User-login": "User login",
    "User-name": "Username",
    "IP-address": "IP address",
    "Action": "Action",
    "Payment-history-modal": {
      "title": "History of actions on document No.",
      "unloading-date": "Upload date:",
      "unloading-author": "Uploaded:"
    },
    "Certificate-not-actual-message": "Your digital signature key is inactive.\nTo issue a new digital signature key, you must contact a bank branch.",
    "Certificate-not-belong-to-user-message": "The digital signature key inserted into the computer does not match your account.\nPlease insert the correct EDS key into your computer and try again.",
    "filters": "Filters",
    "Enter-the-name-or-code-of-the-payment-destination": "Enter the payment purpose name or code",
    "Back": "Back",
    "Dear-user": "Dear user",
    "No-confirm-types-message": "For successful authorization, you must confirm your login via SMS or digital signature. Since your account does not have a phone number or digital signature certificate attached to it, you must contact the bank.",
    "Bank-news": "Bank news",
    "User-manual": {
      "title": "Setting up a computer to work with the Internet banking program with an digital signature key",
      "tab-new-users": "For new users",
      "tab-old-users": "For pc.bank24.uz users"
    },
    "FAQ": {
      "title": "FAQ",
      "login": "For password and login questions",
      "deleted": "If the payment order is not accepted for execution “Deleted”",
      "rejected": "If the payment order is not accepted for execution “Rejected”"
    },
    "Contacts-tab": {
      "title": "Contacts",
      "tech-support-phone": "24/7 support phone number",
      "tech-support-telegram": "Telegram support service 24/7"
    },
    "Swift-details": "SWIFT transfer details",
    "Error": "Error",
    "message type": "Message type (MT)",
    "Save-changes": "Save changes",
    "cntrTtype": "Contract type",
    "bankId": "MFO code of the authorized bank",
    "okpo": "OKPO (contractor) code",
    "okonx": "OKONH code (contractor)",
    "contractorUzParentCompany": "Name of the superior organization of the contract holder",
    "contractorUzName": "Name of the contract holder",
    "rgnCode": "Contract holder area",
    "contractorUzAddress": "Contract holder's address",
    "cntrSubject": "Subject of contract",
    "currCode1": "Contract currency 1",
    "currCode2": "Contract currency 2",
    "amount1": "Contract amount in currency 1",
    "amount2": "Contract amount in currency 2",
    "accCurrCode1": "Settlement currency 1",
    "accCurrCode2": "Settlement currency 2",
    "contractorForName": "Name of foreign partner",
    "contractorForCountryCode": "Country of foreign partner",
    "contractorForAddress": "Legal address of the foreign partner",
    "bankForAttributes": "Bank details of the foreign partner",
    "productsName": "General name of products",
    "docNo": "Contract number",
    "docDate": "Contract date",
    "execType": "Duration of the contract",
    "execDate": "Contract execution date",
    "cntrStatus": "Sign of contract activity",
    "lockDate": "Date of cancellation (deletion, blocking, execution) of the contract",
    "cstDate": "Date of registration of the contract with the customs authorities",
    "contractorUzInn": "TIN of the contract holder",
    "receivableTotal1": "Amount of accounts receivable in contract currency 1",
    "receivableOverdue1": "Amount of overdue receivables in contract currency 1",
    "receivableTotal2": "Amount of accounts receivable in contract currency 2",
    "receivableOverdue2": "Amount of overdue receivables in contract currency 2",
    "payableTotal1": "Amount of accounts payable in contract currency 1",
    "payableOverdue1": "Amount of overdue accounts payable in contract currency 1",
    "payableTotal2": "Amount of accounts payable in contract currency 2",
    "payableOverdue2": "Amount of overdue accounts payable in contract currency 2",
    "insTime": "Date and time of data entry into EEISVO",
    "noContractData": "No data was found for this contract",
    "status": "Status",
    "Failed-to-check": "Failed to check",
    "Failed-to-approve": "Failed to approve",
    "Failed-to-override": "Failed to override",
    "Download-file": "Upload file",
    "Download-failed": "Failed to receive file",
    "Contract-number-additional": "Additional agreement number",
    "Contract-date-additional": "Additional agreement date",
    "Subject-of-the-contract-additional": "Subject of the additional agreement",
    "contractorUzPhone": "Contract holder's phone number",
    "bankForName": "Name of the foreign partner's bank",
    "bankForCountryCode": "Country of Foreign Partner Bank",
    "bankForAddress": "Legal address of the foreign partner's bank",
    "Instruction": "Instructions",
    "Swift-user-instruction": "User instructions for creating a SWIFT transfer",
    "Currency-exchange": {
      "confirm-modal": {
        "content-text": "Are you sure you want to delete your application?"
      }
    },
    "Currency-conversion": {
      "page-title": "New application",
      "details-page-title": "Conversion order details",
      "form": {
        "currency-from": "Currency to be written off",
        "currency-to": "Currency for replenishment",
        "account-from": "Write-off account",
        "account-to": "Replenishment account",
        "account-from-balance": "Balance on the debit account",
        "account-to-balance": "The balance of replenishment account ",
        "required-amount-currency": "Required amount in currency for replenishment",
        "purpose": "Purpose",
        "approved-amount": "Approved amount"
      }
    },
    "UZS amount": "UZS amount",
    "Back-to-list": "Back to list",
    "Sign-and-send": "Sign and send",
    "Change": "Change",
    "Code-for-support": "Support Code",
    "Creation-date": "Date of creation",
    "Payment-details": "Payment details",
    "Organization-name": "Name of the organization",
    "Bank-branch": "MFO Bank",
    "Requirement-details": "Requirement details",
    "Payment-type": "Payment type",
    "Nonpayment-code": "Non-payment code",
    "Destination-code": "Destination code",
    "removed payment requirement records": "Payment request successfully deleted",
    "did not remove payment requirement records": "Failed to delete payment request",
    "Exchange-rate": "Exchange rate",
    "Rejection-reason": "Reason for rejection",
    "Reject-reason": "Rejection reason",
    "Extend-reason": "Reason for extension",
    "Partial-payment-reason": "Partial payment reason",
    "Payment-request": "Payment request ",
    "Payment-requests": "Payment requirements",
    "Payment-request-number": "Payment request No.{n}",
    "Payment-destination-code-for-unpaid-documents": "Payment destination code for unpaid documents",
    "Failed-to-remove": "Failed to remove",
    "Are-you-sure-to-remove-payment-requirement": "Are you sure you want to delete the payment requirement?",
    "Are-you-sure-to-remove-payment-requirements": "Are you sure you want to delete the payment requirements?",
    "Payment-requirement-removing-warning": "Once a payment request is deleted, you will not be able to restore it.",
    "Payment-requirements-removing-warning": "Once payment requests are deleted, you will not be able to restore them.",
    "no payment reqs for sign": "No payment requirements at signing",
    "payment reqs for sign will appear here": "Payment requirements for signing will appear on this page.",
    "create payment req": "Create a payment request",
    "Create": "Create",
    "InnOrPinfl": "TIN or PINFL",
    "PaymentCodeForUnpaidDoc": "Purpose of payment for unpaid documents",
    "Amount-can-not-be-equal": "Amount cannot be equal to {amount}",
    "Successfully-checked-number": "Successfully verified: {n}",
    "Failed-to-check-number": "Failed to check: {n}",
    "Successfully-approved-number": "Successfully approved: {n}",
    "Failed-to-approve-number": "Failed to approve: {n}",
    "Only allowed chars": "The field must contain {n} characters",
    "Failed-to-sign-number": "Failed to sign: {n}",
    "Successfully-signed-number": "Successfully signed: {n}",
    "Document-sending-date": "Document sending date",
    "No-payment-reqs-in-card-index-second": "No payment requirements in Card Index 2",
    "No-payment-reqs-in-card-index-second-will-appear": "Payment requests from Card Index 2 will appear on this page.",
    "Test-text": "Test text",
    "No-payment-reqs-in-card-index-first": "No payment requirements in Card Index 1",
    "No-payment-reqs-in-card-index-first-will-appear": "Payment requests from Card File 1 will appear on this page.",
    "Please-confirm": "Please confirm",
    "card-index-first": "Card index 1",
    "card-index-second": "Card index 2",
    "waiting-for-approval": "Awaiting action",
    "Pay": "Pay",
    "Partial-pay": "Partial pay",
    "Reject": "Reject",
    "Reason": "Reason",
    "Requirement-amount-value": "Request amount: {amount} {currency}",
    "Extend": "Extend",
    "Partial-pay-success": "Partial payment was successful.",
    "download-template": "Download the template",
    "check-template": "Check the data",
    "upload-template": "Download the completed template",
    "documents-import": "Importing documents",
    "Insert-token-to-do-this-action": "Insert the digital signature key and refresh the page to perform this action with the document",
    "choose-file-or-drag": "Select file or drag and drop here",
    "xlxs-no-more-20mb": "XLSX no more than 20 MB",
    "file size should be less than 20mb": "The file size must not exceed 20 MB.",
    "choose file to proceed": "Choose File",
    "Specify-payment-requirement-reject-reason": "Specify the payment requirement rejection reason",
    "Requirement-reject-success": "The request was successfully rejected.",
    "Pay-success": "The payment was successful.",
    "Pay-failed": "Failed to pay.",
    "create-payment-claim": "Create a payment request",
    "closed-payment-requirements": "Closed payment requirements",
    "Document-create-date": "Document creation date",
    "currency-conversion-title": "Inquire for purchase/sale of in. currencies",
    "try-again-later": "Error! try later!",
    "get-back-to-import": "Get back to import",
    "remove-error-and-go": "Remove error and continue",
    "cancel-import": "Cancel import",
    "download-template-for-upload": "Download the template for upload",
    "template-will-be-automatically-downloaded": "The document template will be automatically downloaded to your computer. It's absolutely safe. You can open the template in Excel or another spreadsheet editor.",
    "continue": "Continue",
    "upload-ready-template": "Upload the completed  template",
    "upload-template-we-will-create-docs": "After filling out the template on your computer, drag the file into the upload window.",
    "unfortunately-we-couldnt-create-from-your-file": "Unfortunately, we were unable to load payments from your file.",
    "probably-you-sent-incorrect-file": "Probably, you may have submitted an incomplete file. Please try uploading a different file.",
    "check-files": "Check that the downloaded payments are correct",
    "if-you-found-mistakes": "If you find an error, you can edit the payment in the work with documents section after creation.",
    "documents-uploaded": "Documents are uploaded",
    "payment-requirements": "Payments",
    "payment-req": "Payment",
    "are-you-sure-you-want-to-cancel-upload": "Are you sure you want to cancel the document uploading?",
    "not-able-to-restore": "It will be impossible to restore the data. Next time the document will have to be imported again.",
    "couldnot-upload-properly": "Unfortunately, some documents could not be uploaded properly. ",
    "partly-incorrect": "Some fields are missing or incorrect data has been entered. You can continue creating successfully downloaded payments or return to importing.",
    "couldnot-store": "Failed to load",
    "show-mistakes": "Show errors/ mistakes",
    "paymentNumber": "Document Number",
    "senderAccountNumber": "Payer's account",
    "receiverBranch": "MFO Bank",
    "receiverAccountNumber": "Recipient's account",
    "receiverInn": "Recipient's TIN",
    "amount": "Sum",
    "paymentPurposeCode": "Payment purpose code",
    "No-closed-payment-reqs-for-sign": "No closed payment requests at signing",
    "No-closed-payment-reqs-in-card-index-first": "No closed payment requests in Card Index 1",
    "No-closed-payment-reqs-in-card-index-second": "No closed payment requests in Card Index 2",
    "No-closed-payment-reqs": "No closed payment requests",
    "Payment-reqs-will-appear-here": "Closed payment requests will appear on this page",
    "Extend-payment-date": "Extend payment date",
    "Extend-payment-date-description": "The payment date will be extended.",
    "amount_left": "Amount left",
    "Chars max length": "The maximum number of characters cannot exceed {n} characters",
    "Step-number": "Step {current} from {total}",
    "Confirm-number": "Confirm number",
    "Confirm-number-change-with-token-key-text": "Confirm the change of number with the digital signature key.\nInsert the flash drive with the key into the computer and press the \"Confirm number\" button.",
    "card-index-first-details-title": "Card index first ",
    "card-index-second-details-title": "Card index second",
    "Date-of-review": "Date of review",
    "Date-of-expected-payment": "Date of expected payment",
    "Token-key-not-found": "We could not find the digital signature key in the computer",
    "Insert-token-key-and-try-again": "Insert the token key into your computer and try again.",
    "Insert-correct-token-key-and-try-again": "Insert the correct token key into the computer and try again.",
    "Token-key-not-match": "Token key does not match the account",
    "Enter-new-phone-number": "Enter a new phone number. We will send an SMS with a confirmation code.",
    "Send-code": "Send code",
    "Phone-number": "Phone number",
    "Find-department-address": "Find department address",
    "Actualize-phone-number": "Actualize phone number",
    "Contact-bank-to-change-phone-number": "To continue, contact your bank. A bank employee will help you change your phone number to log into online banking.",
    "Operating-day-is-closed-for-actions-with-payment": "The bank's operating day is closed for actions with payment requests in Card Index 1",
    "Available-for-actions-with": "Available for actions with { time }",
    "Network-error": "Network error",
    "back-to-work-with-documents": "Back to work with documents",
    "No-results-found-for-your-search": "No results found for your search",
    "Try-searching-using-a-different-parameter": "Try searching using a different parameter",
    "There-are-no-closed-payment-requests-in-card-index-1": "There are no closed payment requests in Card Index 1",
    "There-are-no-closed-payment-requests-in-card-index-2": "There are no closed payment requests in Card Index 2",
    "create_payments": "Create payments",
    "could-not-import-because-of-lack-of-rights": "Unfortunately, some documents could not be created due to restricted access rights",
    "next-payments-have-limitations": "The following lines contain restrictions on creating payments:",
    "please-make-sure-to-check-before-import": "Please make sure that you have the right to create payments and/or access transactions on these accounts.",
    "Partially-completed": "Partially fulfilled/ completed",
    "Left-to-pay": "Left to pay",
    "please-make-sure-you-have-access-for-account": "Please make sure that you have the right to create payments and/or access transactions on these accounts.",
    "Try-changing-the-filter-values": "Try changing the filter values",
    "No-active-payment-requests": "No active payment requests",
    "Active-payment-requests-will-appear-on-this-page": "Active payment requests will appear on this page",
    "file size format is not xlxs": "Inappropriate file format. The format must be XLSX",
    "New-requirements": "New requirements",
    "You-have-new-payment-requirements-in-Card-Index-1": "You have new payment requirements in Card Index 1.",
    "Please-confirm-that-you-have-read-the-requirements": "Please confirm that you have read the requirements.",
    "To-perform-this-action-familiarize-yourself-with-new-payment-requirements": "To perform this action, you must first familiarize yourself with the new payment requirements in Card Index 1",
    "There-are-no-payment-requests-in-this-status": "There are no payment requests in this status",
    "Payment-requests-in-status-will-appear-on-this-page": "If available, payment requests { status } will appear on this page",
    "In-status": "In status",
    "Application-for-conversion-(SKV-SKV)": "Application for conversion (SKV-SKV)",
    "Payment-requests-and-card-index": "Payment requirements and files",
    "deposits-for-business": "Deposits for business",
    "let-money-work-and-increase-your-budger": "Let your money work: we will help you increase your funds.",
    "open-until": "Open until {n}",
    "open-new-deposit": "Open a new deposit",
    "promo": "Promo",
    "open_deposit": "Open a deposit",
    "back_to_deposits": "To deposits",
    "overnight": "Interest every day",
    "term_deposit": "Term deposit",
    "turnover_money": "Money remains completely for turnover",
    "seven_percent_deposit": "7% per annum - accrued every day",
    "from_one_to_month": "From 1 to 24 months",
    "till_twenty_one_percent": "Up to 17% per annum",
    "untouchable_deposit_24_month": "Non-removable / untouchable  deposit for 24 months",
    "seven_percent_every_month": "7% per annum - monthly",
    "deposit_term": "Deposit “Urgent”",
    "choose-conditions": "Select conditions",
    "sign-the-application": "Sign the application",
    "overnight-deposit": "Deposit “Interest every day”",
    "payments-history": "Payment history",
    "deposit-information": "Deposit Information",
    "Applications-for-conversion-SKV-SKV": "Applications for conversion (SKV-SKV)",
    "Applications-for-the-purchase-of-foreign-currencies": "Applications for the purchase of foreign currencies",
    "Accounts-and-cards": "Accounts and cards",
    "Loading-onto-plastic-cards-salary": "Loading onto plastic cards (salary)",
    "Salary-statements": "Salary statements",
    "List-of-employees": "A list of employees",
    "List-of-currencies": "List of currencies",
    "Exchange-rates": "Exchange rates",
    "Expanded-issuance-of-personal-accounts": "Expanded issuance of personal accounts",
    "Plastic-cards-info": "Information on plastic cards",
    "Document-id": "Document ID",
    "Application-amount": "Application amount",
    "Write-off-account-balance": "Write-off account balance",
    "Replenishment-account-balance": "Replenishment account balance",
    "Date-time": "The date of process",
    "Enter-code-or-name": "Enter code or name",
    "Setting-up-notifications-in-telegram": "Setting up notifications in Telegram",
    "Adding-a-new-number": "Adding a new number",
    "The-user-will-automatically-have-access-to-the-telegram-bot": "The user will automatically have access to the Telegram bot.",
    "Special-account-in-foreign-currency": "Special account in foreign currency",
    "Foreign-currency-account": "Foreign currency account",
    "Account-for-reservation": "Account for reservation",
    "Client-name": "Client name",
    "Address": "Address",
    "Director": "Director",
    "Chief-accountant": "Chief accountant",
    "Fax": "Fax",
    "Input-information": "Input information",
    "Application-for-the-purchase-of-foreign-currency": "Application for the purchase of foreign currency",
    "Currency-name": "Currency name",
    "Maximum-rate": "Maximum  rate",
    "Minimum-rate": "Minimum rate",
    "Total-amount": "Total amount",
    "Interest-rate-in%": "Interest rate in %",
    "Commission-amount": "Commission amount",
    "Purpose-of-use": "Purpose of use",
    "Brief-description-of-the-goal": "Brief description of the goal",
    "Country-to-which-currency-funds-are-sent": "Country to which currency funds are sent",
    "Attach-copies-of-documents": "Attach copies of documents",
    "Find-a-contract": "Find a contract",
    "Select-a-file": "Select a file",
    "Attached-files": "Attached files",
    "Download": "Download",
    "Are-you-sure-you-want-to-delete-the-file": "Are you sure you want to delete the file?",
    "Search-for-a-contract": "Search for a contract",
    "Enter-IDN-contract-number-or-contract-date": "Enter the name of the contract holder, identification number, contract number or date",
    "Forgot-your-username-or-password": "Forgot your username or password?",
    "Forgot-your-username-or-password-content-text": "You need to call the bank and tell the operator the code word. A bank employee will restore access to the application remotely.",
    "How-to-log-into-Internet-Banking": "How to log into Internet Banking?",
    "Install-software-files-download-link": "Install 3 software files. Download link",
    "Download-file-n": "Download file {n}",
    "If-an-error-message-appears-during-installation-click-skip": "If an error message appears during installation, click “Skip”.",
    "Step": "Step",
    "Follow-the-link-below-and-log-in-using-your-username-and-password": "Follow the link below and log in using your username and password.",
    "Instruction-step-3-content-text": "After entering your username and password, we will ask you to confirm your login using a one-time code from SMS. If the phone number is out of date, you can restore it using the digital signature key or by contacting the bank.",
    "Change-phone-number": "Change phone number",
    "No-access-to-number": "No access to number",
    "With-total-for-each-day-of-discharge": "With a total for each day of discharge",
    "Corporate-cards": "Corporate cards",
    "Statement-of-documents-processed-for-the-period": "Statement of documents processed for the period",
    "The-rate-cannot-be-equal": "The rate cannot be equal to { rate }",
    "The-amount-in-currency-cannot-be-equal-to": "Amount cannot be equal to { rate }",
    "There-is-no-client-account-in-this-currency-and-it-must-be-opened-at-the-bank": "There is no client account in this currency and it must be opened at the bank",
    "main-page-sidebar-title": "Main ",
    "deposit_overnight": "Deposit “Interest every day”",
    "Balance-and-turnover-statement": "Balance and turnover statement",
    "products": "Products",
    "statement-project": "Statement project",
    "workers": "Workers",
    "swift-transfers": "Swift - translations",
    "exchange-currency": "Currency exchange",
    "buy-sell": "Buy/Sell",
    "kartoteka-payment-requirement": "Card index and payment requirements",
    "ved": "ved",
    "others": "Others",
    "Select-all-cards": "Select all cards",
    "Personal-account-statement": "Issuance of personal accounts",
    "Account-statement-reference": "Account statement reference",
    "Report-on-terminals": "Report on terminals",
    "Terminal": "Terminal",
    "You-do-not-have-corporate-cards": "You do not have corporate cards",
    "Please-note-that-the-entered-amount-is-greater-than-the-contract-amount": "Please note that the entered amount is greater than the contract amount.",
    "need-to-fill-the-sum": "You need to fill in the amount",
    "deposit_sum": "Deposit amount",
    "choose-time-and-interest-of-deposit": "Select deposit term and rate",
    "what-account-to-use-in-order-to-fill": "What account to use in order to fill?",
    "how-much": "how much",
    "automatically-from-bank-account": "Automatically from the current account, after processing the application by the bank",
    "deposit-fulfillment": "Deposit fulfillment",
    "interest-rate": "Interest rate",
    "every-month-on-date": "Every month - { day } dates",
    "interest-payment": "Interest payment",
    "i-agree-to-open-deposit": "I agree to the deposit terms",
    "everymonth-payments": "monthly payments",
    "deposit_type_and_term": "Type and term of deposit",
    "deposit_percent": "Interest rate",
    "accept-agreement": "Confirm your consent",
    "Expanded-issuance": "Expanded issuance",
    "processed-reports-period": "Statement for the period",
    "valueDate": "Value date",
    "orderingCustomer": "Sender's name and address (50A)",
    "fieldType56": "Intermediary bank (56)",
    "fieldType57": "Recipient's bank (57)",
    "receiver59A": "Recipient's name and address (59A)",
    "remittanceInformation": "Payment details (70)",
    "chargePayer": "Expense Details (71A)",
    "intermediaryBank": "Intermediary bank (56)",
    "intermediaryBankCountry": "Name of intermediary bank (56A)",
    "beneficiaryCorrespondentBank": "Name of intermediary bank (56A)",
    "nameAndAddress56": "Bank name and address (56D)",
    "partyIdentifier56": "BIC code (56D)",
    "receiverBankBic": "Receiver Bank Bic",
    "receiverBankName": "Receiver Bank Name",
    "receiverBankCountry": "Receiving bank country (57A)",
    "partyIdentifier57": "BIC code (57D)",
    "nameAndAddress57": "Bank name and address (57D)",
    "Please-note-that-the-contract-amount-is-large": "Please note that the contract amount is large",
    "The-file-exceeds-the-maximum-size": "File exceeds maximum size { size }",
    "KB": "KB",
    "MB": "MB",
    "GB": "GB",
    "TB": "TB",
    "The-selected-period-should-not-exceed": "The period must not exceed {n}",
    "days": "days",
    "The-dates-of-the-period-are-entered-incorrectly": "Period dates entered incorrectly",
    "report-saldo-reference": "Report saldo reference",
    "report-accounts": "Account information",
    "deposits": "Deposits",
    "Personal-data": "Personal data",
    "Notifications": "Notifications",
    "Your-password": "Your  password",
    "My-documents": "My documents",
    "My-documents-subtitle": "Here you will find all documents and information about their condition.",
    "organization": "Organization",
    "create-document": "Create document",
    "Current-password": "Current password",
    "Last-change": "Last change",
    "This-is-the-password-you-will-use-to-log-into-online-banking": "This is the password you will use to log into online banking.",
    "New-again": "New again",
    "Selected": "Selected",
    "Update-your-name-and-passport-details-in-the-office": "Update your name and passport details in the office",
    "We-can-change-your-data-only-if-you-contact-bank-branch-in-person": "We can change your data only if you contact bank branch in-person",
    "Download-the-application-for-changing-data": "Download the application for changing data",
    "All-clear": "All clear",
    "Update-phone-number": "Update phone number",
    "Confirm-the-number-change-with-the-digital-signature-key": "Confirm the number change with the digital signature key. Insert the flash drive with the key into the computer and click the “Verify number” button.",
    "Update-email-address": "Update email address",
    "Enter-a-new-email-address": "Enter a new email address. We will send an email with a confirmation code.",
    "Email-address": "Email address",
    "Wrong-login-or-password": "Wrong login or password",
    "You-have-successfully-changed-your-password": "You have successfully changed your password.",
    "This-password-can-only-be-used-to-log-into-this-personal-account": "This password can only be used to log into this personal account.",
    "To-log-into-the-updated-persona-account": "To log into the updated personal account",
    "Link-to-new-personal-account": "link to new personal account",
    "and-the-mobile-application-will-need-to-enter-your-old-password": "and the mobile application will need to enter your old password",
    "Passport": "Passport",
    "New": "New",
    "Pending": "Pending",
    "Waiting-execution": "Waiting execution",
    "For-security-purposes-you-need-to-verify-your-account": "For security purposes, you need to verify your account",
    "Such-a-number-exists": "Such a number exists",
    "Minimum-length-characters": "Minimum length {n} characters",
    "Characters": "Characters",
    "To-increase-the-security-of-your-data-bla-bla-bla": "To increase the security of your data, the complexity of the password should be high, and you should not share the password with others, if this happens, change your password immediately",
    "Virtual-signatures": "Virtual signatures",
    "Virtual-digital-signatures": "Virtual digital signatures",
    "Change-pin-code": "change pin code",
    "Active": "Active",
    "Expired": "Expired",
    "Issue-a-new-signature": "Issue a new signature",
    "You-do-not-have-a-virtual-digital-signature-yet": "You do not have a virtual digital signature yet. A virtual digital signature allows you to sign documents using only a PIN code, without physical media.",
    "You-need-to-download-and-install-the-software-on-your-personal-computer": "You need to download and install the software on your personal computer. After installation is complete, click “Issue new digital signature”.",
    "Change-signing-pin-code": "Change Signing Pin Code",
    "It-will-need-to-be-entered-each-time-you-sign-documents": "It will need to be entered each time you sign documents",
    "Create-a-new-Pin-code": "Create a new Pin code",
    "Repeat-your-Pin-code": "Repeat your Pin code",
    "Pin-code-does-not-match-try-again": "The pin code does not match. Try again",
    "Pin-code-has-been-successfully-changed": "Pin code successfully changed",
    "Are-you-sure-you-want-to-remove-the-signature": "Are you sure you want to remove the signature?",
    "Next-time-it-will-have-to-be-released-again": "Next time it will have to be released again.",
    "Confirm": "Confirm",
    "Virtual-signature-released": "Virtual signature released",
    "Install-signature-release-software": "Install signature release software",
    "You-need-to-download-and-install-the-software-on-your-pc": "You need to download and install the software on your personal computer. After installation is complete, try again and click “Continue”.",
    "Download-installation-software": "Download installation software",
    "Software-not-found": "Software not found",
    "please-install-software-and-try-again": "Please install the software on your computer and try again.",
    "You-already-have-an-active-digital-signature-on-this-computer": "You already have an active digital signature on this computer",
    "You-can-use-it": "You can use it",
    "Signature-limit-exceeded": "Signature limit exceeded",
    "You-can-have-a-maximum-of-3-signatures-on-3-different-devices": "You can have a maximum of 3 signatures on 3 different devices. To issue a new virtual signature, please delete one of the current signatures.",
    "New-virtual-signatures": "New virtual signatures",
    "Release-the-signature-on-your-work-pc-and-the-USB-key-will-no-longer-be-needed": "Release the signature on your work computer and the USB key will no longer be needed.",
    "Later": "Later",
    "Release-signature": "Release signature",
    "Date-and-time": "Date and time",
    "Repeat-the-payment": "Repeat the payment",
    "Create-a-template": "Create a template",
    "Email-address-changed": "Email addresshas been changed",
    "Change-email": "Change email",
    "From-the-template": "From the template",
    "There-are-no-documents-in-the-bank": "No documents in the bank",
    "Sign-and-send-the-documents-for-processing-to-the-bank": "Sign and submit the documents\nfor processing to the bank",
    "There-are-no-documents-for-approval": "No documents for approval",
    "Create-a-new-one-or-wait-for-the-approval-documents-to-appear": "Create a new one or wait until documents appear for approval",
    "There-are-no-documents-to-sign": "No documents to sign",
    "Create-a-new-one-or-wait-for-the-documents-to-be-signed": "Create a new one or wait for documents to sign",
    "No-future-payments": "No future payments",
    "Schedule-the-payment-for-a-future-date-and-it-will-appear-here": "Schedule your payment for a future date and it will appear here",
    "Phone-number-changed": "Phone number changed",
    "There-are-no-matches": "There are no matches",
    "Try-to-change-the-query": "Try changing your request",
    "Until": "Until",
    "Search-my-documents-placeholder": "Search by recipient's account, payment amount or document number",
    "ID-card": "ID card of the Republic of Uzbekistan",
    "Passport-uz": "Passport of a citizen of the Republic of Uzbekistan",
    "Military-id": "Military ID",
    "Service-id": "Service ID",
    "International-passport": "Foreign citizen's passport",
    "Residence-permit": "Resident card",
    "Biometric-passport": "Biometric passport of a citizen of the Republic of Uzbekistan",
    "Birth-certificate": "Birth certificate",
    "Drivers-license": "New national driver's license",
    "Reset-the-request": "Reset request",
    "At-the-signing": "At the signing",
    "Signed": "Signed",
    "Rejected": "Rejected",
    "On-performance": "On performance",
    "You-entered-the-wrong-password": "You entered the wrong password",
    "Signed-by": "Signed by",
    "Incorrect-code": "Incorrect code",
    "Enter-the-code-from-the-email": "Enter the code from the email",
    "We-sent-an-email-with-a-code-to-the-address": "We sent a letter with a code to the address",
    "Awaiting-approval": "Pending approval",
    "Awaiting-signing": "Awaiting signing",
    "Future-payments": "Future payments",
    "Documents-in-the-bank": "Documents in the bank",
    "Connection-error": "Connection error",
    "Internal-Server-Error": "Internal Server Error",
    "Something-went-wrong-on-our-end": "Please try again later or contact support",
    "Apply": "Apply",
    "Reset": "Reset",
    "Download-document": "Download a document",
    "Create-signing-pin-code": "Create a PIN code for signing",
    "Completed": "Completed",
    "Open-details": "Open details",
    "The-bank-will-consider-your-proposed-rate-when-considering-your-application": "The bank will consider the rate you offer when considering your application",
    "Account-from": "Account from",
    "Payment-number": "Payment №{n}",
    "Action-history": "Action history",
    "Pinfl": "PINFL",
    "inquiries": "Inquiries",
    "my-inquiries": "My inquiries",
    "about-client": "About client",
    "Loading-data-error": "Error loading data",
    "Receiver-card": "Recipient's card",
    "Transit-account": "Transit account",
    "full_name": "Full Name",
    "inn_or_pinfl": "TIN or PINFL of the organisation",
    "product": "Product",
    "enter_number": "Enter number",
    "email": "Email",
    "additional": "Additionally",
    "manager_comments": "Comment for manager - optional",
    "add-comment": "Add a comment",
    "send-inquiry": "Send inquiry",
    "new-inquiry": "New inquiry",
    "incorrect-email": "Please enter your email in the correct format.",
    "manager-will-contact": "The bank manager will contact the client shortly - monitor the status.",
    "account-open": "Opening a current account",
    "Sign": "Sign",
    "Logout": "Log out",
    "Sorting-by-date": "Sorting by date",
    "From-new-payments-to-old-ones": "From new payments to old",
    "From-old-payments-to-new-ones": "From old payments to new",
    "sum-purpose": "Amount and purpose",
    "payment-amount": "Enter payment amount",
    "account-tin-name": "Account, TIN or name of the budget recipient",
    "who-send": "To whom to send",
    "corporate-card": "To a corporate card",
    "to-individual": "To an individual",
    "min-max-letters": "Minimum/maximum characters",
    "from-account-send": "From which account to send?",
    "template-info": "Template information",
    "template-name": "Template name",
    "select-bank": "Selecting the recipient's bank",
    "bank-name-mfo": "Name of the bank or microfinance organization (MFO)",
    "name-template": "Name your template",
    "create-payment": "Create payment",
    "update-template": "Update template",
    "save-template": "Save the template",
    "template-created": "Template is created",
    "make-payment-by-template": "Make a payment using the created template.",
    "template-updated": "Template updated",
    "selecting-payment-purpose": "Selecting a payment purpose code",
    "purpose-name-code": "Name of purpose or code",
    "type": "Type",
    "sender-branch": "Recipient bank {0}",
    "receiver-branch": "Sender's bank {0}",
    "create-templates-and-chill": "Create templates and simplify your processes.",
    "create-template": "Create template",
    "find-by-account-amount-documents": "Search by recipient's account, payment amount or document number",
    "no-templates": "No templates",
    "no-templates-create": "There are no templates here yet. Create your own to make processes even more efficient and convenient",
    "template-deleted": "Template deleted",
    "create-new-template-and-chill": "Create a new template and simplify your processes.",
    "cant-delete-template": "Failed to delete template",
    "try-delete-template-later": "Try removing it later.",
    "document-details": "Document details",
    "no-active-inq": "You have no active requests",
    "invite-and-watch": "Start inviting clients and monitor the status of applications here",
    "Client-name-or-INN": "Client name or INN",
    "Created": "Created",
    "In-process": "In progress",
    "Client-refusal": "Client refusal",
    "Finished": "Finished",
    "statements": {
      "title": "Statements",
      "desc": "Create and view statements without difficulties.",
      "tab1": "New statement",
      "tab2": "Previously created",
      "item1": {
        "title": "Account statement",
        "desc": "In Excel format"
      },
      "item2": {
        "title": "Extended extract",
        "desc": "In TXT format"
      },
      "item3": {
        "title": "Reference on account operation",
        "desc": "Excel and TXT"
      },
      "item4": {
        "title": "Issuance of personal accounts",
        "desc": "In TXT format"
      },
      "item5": {
        "title": "Turnover balance statement",
        "desc": "In TXT format"
      },
      "item6": {
        "title": "Ведомость за период",
        "desc": "Posted documents for selected accounts"
      },
      "item7": {
        "title": "Payment documents",
        "desc": "PDF with all documents "
      },
      "item8": {
        "title": "Corporate cards",
        "desc": "In Excel format"
      },
      "item9": {
        "title": "Terminal report",
        "desc": "Transactions carried out on the terminal"
      }
    },
    "Inquiry-number": "Application №",
    "client": "Client",
    "phone": "phone",
    "Full-name": "Full-name",
    "The-current-account-has-been-opened": "The current account has been opened",
    "Payments-creation": "Payments creation",
    "Opened": "Opened",
    "Sum-payments": "Sum payments",
    "Currency-payments": "Foreign exchange",
    "To-any-Uzbekistan-bank": "To any bank in Uzbekistan",
    "To-card": "To the card",
    "To-budget": "To budget",
    "By-requisites": "By requisites",
    "To-treasury": "To treasury",
    "Government-services": "Government services",
    "Payment-via-MUNIS": "Payment via MUNIS",
    "Swift-transfer": "Swift transfer",
    "All-over-the-world": "All over the world",
    "Buy-currency": "Buy currency",
    "Sell-currency": "Sell currency",
    "To-currency-account": "To currency account",
    "From-currency-account": "From currency account",
    "currencyConversion": "Currency conversion",
    "Discharge-information": "Extract/ Discharge information",
    "Sorting-and-turnover-type": "Sorting and turnover type",
    "Loan-contract": "Loan contract",
    "Loan-repayment-account": "Loan repayment account",
    "In-the-presence-of": "in the presence of",
    "payment-history": "payment history",
    "here-find-history": "Here you will find all completed documents and information about their status.",
    "search-payment": "Searches by payments",
    "in-out": "Incoming/Outgoing",
    "incoming": "incoming",
    "outcoming": "outcoming",
    "date-range": "Date or period",
    "buy": "buy",
    "sell": "sell",
    "out-sum": "out sum",
    "in-sum": "in sum",
    "short-payrolls": "short payrolls",
    "convertation": "convertation",
    "in-currency": "In currency",
    "in-account": "In account",
    "out-currency": "out currency",
    "gov-services": "Government services",
    "try-change-request": "Try changing your request",
    "no-payments": "No payments made",
    "create-payment-and-store-here": "Create a new payment and it will be stored here",
    "history": "History",
    "confirmWithOnlineDigitalSignature": "Confirm with Online Digital Signature",
    "enter-virtual-signature-pin": "Enter the virtual digital signature PIN code",
    "forgot-pin-code": "Forgot your Pin code?",
    "found-n-results": "{n} results found. | {n} result found. | {n} results found. | {n} results found.",
    "Period": "Period",
    "Select-a-service": "Select a service",
    "failed-to-submit-modal": "An error occurred, please try later",
    "submit-review": "Post a review",
    "tell-us-why-you-evaluated-that-way": "Tell us why you gave this rating? (it's not obligatory)",
    "very-bad": "very bad",
    "nice": "nice",
    "we-see-that-you-recently-have-opened-the-account": "We see that you recently opened an account with our bank.",
    "tell-us-how-it-was": "Tell us how it went - we want to become even better.",
    "evaluate-the-service": "Rate the quality of service",
    "rate-us-before-submitting": "Please fill out the rating field",
    "review-is-submitted": "Review sent/ submitted",
    "thank-you-for-making-uzum-better": "Thank you for helping to make Uzum Business better :)",
    "services": "services",
    "wrong-login-or-password": "Incorrect login or password",
    "input-placeholder": "Input",
    "create-payment-order-for-transit-salary-account": "Create a payment order for a transit salary account",
    "gov-service": "Government service",
    "add-payroll": "Add statement / payroll",
    "user-not-activated": "User is not active",
    "": "Government service",
    "import-document": "Import a document",
    "create-from-template": "Create from template",
    "payment-to-account": "Payment to account",
    "payment-to-card": "Payment to card",
    "payment-to-budget": "Payment to the budget",
    "payment-to-treasury": "Payment to the Treasury",
    "Create-statement": "Create a statement",
    "Create-statement-desc": "You can find all your previously created statements on this page",
    "Restore-statements": "Restore statements",
    "Create-a-new-statement": "Create a new statement",
    "All-reports-have-been-deleted": "All statements have been deleted",
    "Are-you-sure-you-want-to-clean-everything-up": "Are you sure you want to clear everything up?",
    "The-report-is-being-generated-and-will-be-uploaded-soon": "The report is being generated and will be uploaded soon",
    "Report-succes-notify-title": "Statement / report created",
    "Report-succes-notify-desc": "You can find it in the “Previously Created” section at any time.",
    "purpose": "purpose",
    "document-info": "Document information",
    "phone-for-call": "Contact phone number for call",
    "copy-currency-contract": "Copies of the currency contract",
    "document-name": "Title of the document",
    "variant": "variant",
    "no-document": "Document missing",
    "request-for-currency-operation": "Application for currency transactions",
    "currency-conversion-info-text": "To make a positive decision on your application, you must\n reserving funds to a block account for conversion.",
    "currency-conversion-info-text-sub": "Reservations are strongly recommended.",
    "left-sum-block-account": "left sum on block account",
    "sum-request-variant": "Application amount in equivalent",
    "send-from-my-documents-page": "Send the document to the bank from the “My Documents” Menu section.",
    "are-you-sure-you-to-continue": "Are you sure you want to continue?",
    "yes": "yes",
    "no": "no",
    "cancel-download": "Cancel download",
    "Poisk-documentov": "Search documents",
    "Try-to-choose-other-dates": "Try choosing other dates",
    "Try-to-choose-other-filters": "Try different filters",
    "Creating-an-extract": "We form an extract",
    "Error-during-formation": "Error during formation",
    "An-error-has-occurred-please-try-again-later": "An error occurred, please try again later",
    "invalid-date": "Invalid date",
    "Reset-dates": "Reset dates",
    "Reset-filters": "Reset filters",
    "Requisites": "Requisites",
    "Service1": "Service 1",
    "Service2": "Service 2",
    "Region": "Region",
    "Area": "Area",
    "Branch": "Branch",
    "Create-statement2": "Create statement2",
    "payment-monthly": "monthly payments",
    "deposit-terms-agreement": "I agree to the deposit terms",
    "extended-successfully": "extended successfully",
    "extended-unsuccessfully": "Failed to extend",
    "Select-a-card": "Select a card",
    "date-from": "date from",
    "date-to": "date to",
    "agreed": "agreed",
    "turn-on-tg-bot": "Enable access to bot",
    "available-for-tg-users": "Available only for Telegram users",
    "add-phone-number": "Add new phone number",
    "are-you-sure-delete-number": "Are you sure you want to delete the number?",
    "nex-time-should-add-again": "Next time you will have to add it again",
    "number-added-successfully": "The number was added successfully",
    "number-deleted-successfully": "The number was successfully deleted",
    "for-safety-confirm-account": "For security purposes, you need to verify your account",
    "enter-corresponded-account-user": "Enter the Correspondent account of the recipient's bank",
    "show-client-name-account-name-payment-name": "Indicate the client's name, client account number, payment purpose",
    "open-deposit-title": "Open for {term} months at {rate}% per annum - interest is paid on the 25th of each month.",
    "Terminal-number": "Terminal number",
    "forbidden-page": "Forbidden 403 do not have access to this route",
    "back-to-main": "Return to home page",
    "password-change-hint-text": "To increase the security of your data, the password complexity should be\n high, you also cannot transfer the password to other persons if this\n happened, change your password immediately",
    "password-hint-text-start": "\"When you change your password, you can use it to log into your personal account.\r\n office and mobile application. To log into your old personal account\"",
    "password-hint-text-end": " need to use the old password.",
    "language": "language",
    "key-is-not-for-account": "The key does not match the account",
    "put-the-proper-key-and-try-again": "Insert the proper key and try again.",
    "we-could-not-find-virtual-key": "Could not find the digital signature key",
    "put-the-key-and-try-again": "Put the key and try again.",
    "your-key-is-out-of-date": "Your digital signature key is outdated",
    "try-with-virtual-signature-or-contact-us": "Continue with a virtual signature or close and contact support.",
    "send-via-anor": "Send via Anor 24/7",
    "payment-can-be-sent-at-any-time": "Payment can be sent at any time",
    "even-after-end-of-banking-day": "Even after the end of the banking day",
    "search-documents": "Search documents",
    "Destination-codes": "Payment codes",
    "Select-account": "Select account",
    "Munis-subtitle": "Sign documents or view payment history.",
    "Supplier": "Supplier",
    "receiverNameNew": "Recipient's account name",
    "Debit-turnover": "Debit turnover",
    "Credit-turnover": "Credit turnover",
    "Munis": "Munis",
    "Personal-account": "Personal account",
    "Payment-created": "Payment created",
    "Commission": "Commission",
    "waiting-documents": "Waiting for documents",
    "Select-service": "Select service",
    "Select-region": "Select region",
    "Select-area": "Select area",
    "Select-branch": "Select branch",
    "Enter-amount": "Enter amount",
    "Sum-account": "Sum account",
    "failed-to-load-inquiry-details": "Failed to load inquiry details, try later",
    "partner": "Partner",
    "inquiries-created": "Total applications created",
    "inquiries-in-progress": "Applications/ Inquiries  in progress",
    "inquiries-declined": "Inquiries declined",
    "inquiries-succeed": "Inquiries succeed.",
    "You-can-use-it-or-delete-to-create": "You can use it or delete the existing one to create a new one.",
    "incorrect-pin-try-again": "The PIN code was entered incorrectly. Try again.",
    "if-no-styx-on-the-device": "If you do not yet have a digital signature on this device",
    "enter-sms-to-commit": "Enter the code from SMS to confirm",
    "enter-sms-to-delete": "Enter SMS code to delete signature",
    "styx-removed": "The virtual signature has been removed.",
    "how-use-styx": "How to use digital signature?",
    "download-and-install-by-button": "Download and install the software on your personal computer by clicking on the button below.",
    "download-mac": "Download on Mac",
    "download-windows": "Download on Windows",
    "new-styx-by-button-and-pin": "Click the button below to release a new signature. We will prompt you to set a PIN.",
    "understandable": "All clear / understandable",
    "styx-guide": "\"To use a virtual digital signature, download the necessary software in the virtual signature settings.\r\n\r\nTo use a virtual digital signature on your device, it must be linked to it.\r\n\r\nOne device can be linked to one virtual digital signature. Within your account, you can issue up to 3 virtual digital signatures for 3 different devices.\r\n\r\nIf you have forgotten your PIN code or your virtual digital signature has expired, delete it from your computer and issue a new one by creating a new PIN code.\"",
    "how-does-it-work": "How it works?",
    "deposit-pay": "We sum up the interest accumulated for the month and pay it to the current account once a month.",
    "deposit-at-night": "In the evening the money is credited",
    "deposit-money-will-go-in-the-day-end": "Money from the account will be transferred to the deposit account at the end of the day.",
    "deposit-money-works-at-night": "Deposit money works at night. ",
    "deposit-money-are-located-in-account": "The money is in a deposit account and earns interest.",
    "deposit-in-the-morning": "In the morning the money will be written off",
    "deposit-money-back-in-the-morning": "Money from the deposit account will be returned to the current account at 9:00 am.",
    "choose-account": "Select account",
    "what-will-be-interest": "What will the interest rate be?",
    "what-will-be-term": "What will be term?",
    "how-to-increase-deposit": "How to increase your deposit?",
    "can-i-withdraw-before": "Can Iwithdraw before?",
    "how-much-to-deposit": "How much to deposit?",
    "only-for-24-month": "Only for 24 months",
    "progressive-deposit-rate": "This deposit has a progressive interest rate - increases by 1 percent over three months.",
    "deposit-is-taken-from-account": "The deposit will be replenished from the selected current account immediately after processing your application",
    "you-can-do-it-only-in-the-bank-office": "This can only be done at the bank’s office; interest calculation will depend on the term",
    "everyday-for-the-leftover-amopunt": "Daily, for the amount of the selected account balance",
    "how-interest-is-paid": "How is interest calculated?",
    "what-account": "From what account should replenish?",
    "only-for-12-month": "for 12 months",
    "sever-percent-interest": "7% (percent) per annum",
    "you-dont-need-to-do-anything-bank-will-do": "You don't need to do anything, the money will automatically transfer from the selected checking account to the deposit account at the end of the day",
    "this-could-be-done-only-in-the-office": "This can only be done at the bank office",
    "every-month-in-25": "Every month - 25th",
    "how-interest-is-being-paid": "How is interest be paid?",
    "which-account-should-be-paid": "which account should interest be calculated?",
    "deposit-account": "Deposit account",
    "rs-account": "Checking account",
    "for-the-leftover-amount-you-will-get-money": "We will accrue interest on the balance every day",
    "the-leftover-we-will-take-in-the-eod": "The left balance of the account will be transferred to deposit at the end of the banking day",
    "for-all-time": "For all time",
    "to-the-account": "To the account •",
    "from": "from",
    "to": "to",
    "here-you-will-have-interests": "Interest will be charged here",
    "inquiry-to-open-deposit": "Application/ Inquire for opening a deposit",
    "faq": "FAQ",
    "deposit_faq_first": "\nIt is possible to withdraw money from the deposit ahead of schedule. To do this, go to a bank branch, write an application and receive the money.",
    "deposit_faq_second": "Soon you'll be able to do this without having to go in person.",
    "how-to-withdraw-money-from-deposit": "How to withdraw money from deposit?",
    "deposit_faq_third": "You can deposit money into a deposit, but interest will not accrue on the payment amount.",
    "deposit_faq_fourth": "To increase the deposit amount, contact the bank branch and write an application to change the terms of the deposit.",
    "deposit_faq_fifth": "Soon you'll be able to do this without having to go in person.",
    "how-to-close-deposit": "How to close a deposit?",
    "you-can-close-deposit-but-it-will-affect-interest": "It is possible to close the deposit early, but the interest rate will effect the interest.",
    "steps-to-close-deposit": "To close the deposit, contact the bank branch and write an application to close the deposit.",
    "deposit": "Deposit",
    "is-open-untill": "open until",
    "term_dep": "Urgent",
    "deposit_rules": "Deposit rules",
    "account-for-deposit": "Current account for deposit",
    "uzs-account": "uzs-account",
    "for-24-month": "for 24 months",
    "deposit-till-17%": "Deposit up to 17%",
    "for-12-month": "for 12 months",
    "I-agree-with": "I agree with",
    "dep-rules": "registration rules",
    "rules-of-dep": "Deposit up to 17%",
    "select-placeholder": "Select",
    "you-should-have-more-than-thousand": "There must be at least 1,000 sum in the account - top up your account or choose another",
    "not-enough-money": "Insufficient funds - top up your account or choose another one",
    "deposit-is-created": "Deposit successfully created",
    "10_days": "10 days",
    "1_month": "1 month",
    "2_months": "2 months",
    "3_months": "3 months",
    "One": "One",
    "Two": "Two",
    "Three": "Three",
    "Four": "Four",
    "Five": "Five",
    "Six": "Six",
    "Seven": "Seven",
    "Eight": "Eight",
    "Nine": "Nine",
    "Ten": "Ten",
    "Eleven": "Eleven",
    "Twelve": "Twelve",
    "Thirteen": "Thirteen",
    "Fourteen": "Fourteen",
    "Fifteen": "Fifteen",
    "Sixteen": "Sixteen",
    "Seventeen": "Seventeen",
    "Eighteen": "Eighteen",
    "Nineteen": "Nineteen",
    "Twenty": "Twenty",
    "Thirty": "Thirty",
    "Fourty": "Fourty",
    "Fifty": "Fifty",
    "Sixty": "Sixty",
    "Seventy": "Seventy",
    "Eighty": "Eighty",
    "Ninety": "Ninety",
    "one-hundred": "One-hundred",
    "two-hundred": "Two-hundred",
    "three-hundred": "Three hundred",
    "four-hundred": "Four-hundred",
    "five-hundred": "Five-hundred",
    "six-hundred": "Six-hundred",
    "seven-hundred": "Seven0hundred",
    "eight-hundred": "Eight-hundred",
    "nine-hundred": "Nine-hundred",
    "Sum": "Sum",
    "Suma": "Suma",
    "Sumov": "Sumiov",
    "US-dollar": "US dollar",
    "US-dollara": "US dollara",
    "US-dollars": "US dollars",
    "thousand": "Thousand",
    "thousands": "Thousands",
    "thousand2": "Thousands2",
    "one1": "One1",
    "two2": "two2",
    "million": "million",
    "million2": "million2",
    "millions": "millions",
    "billion": "billion",
    "billion2": "billion2",
    "billions": "billions",
    "trillion": "trillion",
    "trillion2": "trillion2",
    "trillions": "trillions",
    "tiyin": "tiyin",
    "tiyin2": "tiyin2",
    "tiyins": "tiyins",
    "created": "Created",
    "carried-out": "carried out",
    "Dear-clients": "Dear clients",
    "Dear-clients-message": {
      "part1": "We ask you not to send the same statement at the same time through the old and new “Bank Client” to avoid double enrollment. Send statements one at a time and avoid duplication. After sending the payment to transit salary account 23106, send the statement within one day so that crediting occurs on time. We also ask you to send salary slips before the close of the banking day.",
      "part2": "Regards, Uzum Business - Kapitalbank team",
      "part3": "Currently, payments to the treasury and budget via the 24/7 quick payment system (ANOR) are temporarily unavailable in the mobile application and Internet Bank. We are already working on solving this problem in order to restore full operation of the services as soon as possible.",
      "part4": "We apologize for any inconvenience. Thank you for your understanding and patience!"
    },
    "Missed-payment-date": "Missed payment date",
    "File-deleted": "File deleted",
    "Russian-federation": "Russian Federation",
    "Months": "Months",
    "Month": "Months",
    "24-months": "24 months",
    "Successfully-deleted": "Successfully deleted",
    "This-functionality-is-being-improved-and-will-soon-be-available-in-a-new-version": "This functionality is being improved and will soon be available in a new version. If necessary, you can perform the necessary operation in the old version by opening the following link",
    "in-the-browser": "in browser {browser}",
    "sec": "sec",
    "Are-you-sure-you-want-to-do-this?": "Are you sure you want to do this?",
    "Insufficient-funds": "Insufficient funds",
    "Send-error": "Send error",
    "Withdrawn": "Withdrawn",
    "Partially-withdrawn": "Partially withdrawn",
    "Revocation-error": "Revocation error",
    "Moved-to-K2": "Moved to K2",
    "Without-execution-due-to-failure-to-appear": "Without execution due to failure to appear. class",
    "Returned-due-to-clients-no-show": "Returned due to client no-show",
    "Partially-rejected-and-paid": "Partially rejected and paid",
    "Partially-rejectedand-in-K2": "Partially rejected in K2",
    "The-deadline-for-transfer-for-consideration-has-expired": "The deadline for transfer for consideration has expired",
    "Completed2": "Completed",
    "Refusal": "Refusal",
    "Partially-completed2": "Partially completed ",
    "Without-execution-due-to-failure-to-appear2": "Without execution due to failure to appear. cl",
    "Move-to-the-next-step": "Move to the next step",
    "403 Forbidden": "403 - access denied",
    "Some of the doors are always close to you": "Sorry, you do not have permission to access this page",
    "Go back": "Go back",
    "To-main": "Return to main page",
    "404 Not Found": "404 Not Found",
    "You know life is always ridiculous": "The page you are requesting does not exist. It may be out of date, deleted, or an incorrect address was entered in the address bar.",
    "Internal Error": "500 - server error",
    "Internal error may prove that you need hiring more developers": "An unexpected error occurred on the server. Please wait, it will be fixed soon.",
    "Open-until-date": "Open until {date}",
    "Month2": "Month",
    "Month-plural": "{n} months | {n} month | {n} months | {n} months",
    "type-of-turnover": "Type of turnover",
    "additional-params-sort": "Additional sorting options",
    "Amount-of-credit": "Amount of credit",
    "Credit-account": "Credit account",
    "virtual-signature-created": "The virtual signature has been created.",
    "something-went-wrong": "Something went wrong. Please try again later.",
    "hint": "Hint",
    "Start-making-money-on-the-Uzum-Market": "Start making money on Uzum Market!",
    "Open-a-store-right-now": "Open a store right now",
    "Open": "Open",
    "revenues": "Revenues",
    "for-period": "For period",
    "overall-fixex-payments": "Total fixed payments in soums",
    "after-inquiries-there-will-be-a-sum": "After completing the first inquiries, the preliminary amount to be paid will appear here.",
    "successfull-inquiries": "Successful inquiries",
    "fixed-payment": "Fixed payment",
    "Delete-all": "Delete all",
    "we-could-not-create": "We were unable to create a request at this time. try later",
    "otp-signin-footer-text": "{phone} or login with {epass} or {virtual}",
    "csr-key-genitive": "Electronic digital signature key ",
    "signin-virtual-signature": "Login using a virtual digital signature key",
    "signin-virtual-signature-description": "If you are running Styx Token Manager, click “Login”.",
    "enter": "enter",
    "or-enter-with": "or enter with",
    "or-with": "or with",
    "go-with-signature": "Go with Electronic digital signature key",
    "go-with-virtual-signature": "Go with virtual signature",
    "change-phone-guide": "Confirm the change of number with an electronic digital signature key or a virtual signature.\r\n\r\nInsert the flash drive with the key into the computer in order to continue with the digital signature key.\r\n\r\nClick the “Continue with Signature” button and enter the PIN code to use the virtual signature for the change.",
    "company_name": "Company name",
    "operation_date_bank": "Operation date of the bank",
    "stats": "Statistics ",
    "inn/pinfl": "INN or PINFL",
    "account_opened": "Account opened",
    "failedToFetchInquiries": "Failed to fetch inquiries. Try later",
    "business": "Business",
    "open-the-account": "Opening the ccount.",
    "your-manager": "Your manager",
    "Download-for-1C": "Download for 1C",
    "opened-products-and-their-payments": "Opened products and their payments",
    "search-branches": "Search bank branches",
    "refresh-phone-number": "Refresh your phone number",
    "actualize-phone-number-by-branch": "Actualize your number to continue using online banking.\n\nThis can be done at a bank branch - there our employees will help you update your phone number.",
    "phone-number-change-not-allowed": "Changing your phone number through online banking is not allowed.\n\nThis can be done at a bank branch - there our employees will help you update your phone number.",
    "actualize-phone-number-by-signature": "Update your number to continue using online banking.\n\nTo do this, insert the flash drive with the key into the computer and click “Confirm.\"",
    "confirm-with-virtual-signature-genitive": "Virtual signature",
    "could-not-find-virtual-signature": "Could not find virtual signature",
    "reload-styx-try-again-or-other-auth": "Reload Styx Token Manager and try again or use another authentication method.",
    "could-not-create-virtual-signature-try-later": "Couldn't create virtual signature, try again later",
    "address-or-name-bank": "Bank address or name",
    "try-change-search-request": "Try to change search request",
    "bank-branches": "Bank branches",
    "this-device": "This device",
    "Download-the-statement": "Download the statement",
    "We-will-call-and-tell-you-about-the-products": "We will call and tell you about the products",
    "Leave-your-contact-information-so-that-the-manager-can-contact-you": "Leave your contact information so that the manager can contact you",
    "counterparty-banner-title": "Checking counterparties has appeared in the Services section",
    "counterparty-banner-subtitle": "Now you can make a decision\r\nabout cooperation with a partner based on data about him/her.",
    "All-services": "All services ",
    "All-services-and-facilities": "All services and facilities",
    "Checking-counterparties": "Checking counterparties",
    "counterparty-card-subtitle": "Make decisions about cooperation\r\nwith a partner based on data about him/her.",
    "Send-contacts": "Send contacts",
    "counterparty-search-title": "Find a company to check",
    "counterparty-search-subtitle": "We help obtain information about enterprises and organizations, analyze court cases and use data on government procurement",
    "first_name": "First name",
    "second_name": "Second name",
    "patronymic": "Patronymic",
    "Thank-you": "Thank you",
    "Found-count-results": "Found {count} results",
    "counterparty-search-placeholder": "Company name, manager's full name or tax identification number",
    "The-manager-will-call-you-back-within-5-minutes-and-answer-your-questions": "The manager will call you back within 5 minutes and answer your questions.",
    "Application-sent": "Application sent",
    "Registered-date": "Registered date",
    "Phone-number-or-login": "Phone number or login",
    "Or": "Or",
    "Login-to-Uzum-Business": "Login to Uzum Business",
    "check-counterparty-rating-modal-title": "This is the Entrepreneur's Resilience Rating",
    "check-counterparty-rating-modal-content": "“The rating is formed on the electronic platform of the Tax Committee, based on the entrepreneur’s compliance with legal requirements.\r\n{br}{br}\r\n“AAA”, “AA” and “A” - High{br}\r\n“ВВВ”, “ВВ” and “В” – Average{br}\r\n“ССС”, “СС” and “С” – Satisfactory{br}\r\n\"D\" - Low\r\n{br}{br}\r\nData for the rating comes through interdepartmental exchange. The procedure is determined by the Cabinet of Ministers.\"",
    "check-counterparty-sme-popover-title": "Belonging to small businesses",
    "Information-updated": "Information updated",
    "check-counterparty-header-bottom-info": "Information is provided by MyAccountant LLC based on {link} from {date}",
    "Become-a-client": "Become a client",
    "The-first-6-months-are-free": "The first 6 months are free.",
    "Consultation": "Consultation",
    "We-will-call-you-and-tell-you-about-the-product": "We will call you and tell you about the product.",
    "View-all": "View all",
    "Become-a-bank-client": "Become a bank client",
    "DownloadTemplate": "Download template",
    "Company-name-or-individual-entrepreneur": "Company name or individual entrepreneur",
    "We-are-considering-the-application": "We are considering the application",
    "We-check-the-information-and-prepare-everything-for-opening-an-account": "We check the information and prepare everything for opening an account.",
    "Enter-the-number-in-the-correct-format": "Enter the number in the correct format",
    "IIN-contains-9-digits-and-PINFL-14": "IIN contains 9 digits, and PINFL - 14.",
    "please-check-status-zp-vedemost-inside": "Please go inside the statement and check the status of employee cards",
    "Registered": "Registered",
    "Not-registered": "Not registered",
    "The-company-was-liquidated": "The company was liquidated",
    "Operating-company": "Operating company",
    "Inactive-company": "Inactive company",
    "Active-entrepreneur": "Active entrepreneur",
    "Activities-are-temporarily-suspended": "Activities are temporarily suspended",
    "Liquidated": "Liquidated",
    "The-certificate-has-expired": "The certificate has expired",
    "there-will-be-payment-and": "On the payment day, there will be a fixed payment , as well as",
    "banks-revenue": "30% of bank revenue",
    "Activity": "Activity",
    "Tax-regime": "Tax regime",
    "Kind-of-activity": "Kind of activity",
    "Additional-activities": "Additional activities",
    "Date-of-liquidation": "Date of liquidation",
    "Suspension-date": "Suspension date",
    "VAT-payer": "VAT payer",
    "VAT-registration-date": "VAT registration date",
    "Flat-tax": "Flat tax",
    "Tax-based-on-declaration": "Tax based on declaration",
    "Turnover-tax": "Turnover tax",
    "VAT": "VAT",
    "Fixed-turnover-tax": "Fixed turnover tax",
    "check-counterparty-header-top-info-text": "This service is provided exclusively by Assistant Business Market LLC. JSCB \"Kapitalbank\" is not responsible for the accuracy of information received through the \"Counterparty Verification System\" and for the consequences of using the information posted on it, as well as for possible loss or damage to data, as well as other consequences  that may occur.",
    "Tax-debt": "Tax debt",
    "No-data": "No data",
    "To-search-results": "To search results",
    "You-already-have-an-account": "You already have an account",
    "Use-your-username-and-password-to-login-to-Uzum-Business": "Use your username and password to log in to Uzum Business",
    "Scan-the-QR-code-to-download-the-Uzum-Business-app": "Scan-the-QR-code-to-download-the-Uzum-Business-app",
    "Checked-the-application": "Checked the application",
    "Your-company-has-been-liquidated": "Your company has been liquidated",
    "We-will-not-be-able-to-open-an-account": "We will not be able to open an account.",
    "If-you-have-an-existing-business-then-contact-support": "If you have an existing business, then contact support.",
    "Hotline": "Hotline",
    "Chat-in-telegram": "Chat in telegram",
    "It-is-not-possible-to-open-an-account-remotely": "It is not possible to open an account remotely",
    "Contact-technical-support-and-we-will-help-you-figure-it-out": "Contact technical support and we will help you figure it out.",
    "Founder": "Founders",
    "Opening-an-account": "Opening an account",
    "Legal-address": "Legal address",
    "Company-address": "Company address",
    "Please-provide-your-actual-address": "Please provide your actual address",
    "If-the-address-is-determined-incorrectly-please-indicate-the-correct-one": "If the address is determined incorrectly, please indicate the correct one.",
    "Actual-address": "Actual address",
    "Revenue-in-soums-for-the-year": "Revenue in soums for the year",
    "Indicate-the-approximate-revenue-you-expect": "Indicate the approximate revenue you expect",
    "Service-tariff": "Service tariff",
    "Choose-a-plan-that-suits-your-business": "Choose a plan that suits your business.",
    "Select-tariff": "Select tariff",
    "One-of-the-founders-is-not-a-resident-of-the-Republic-of-Uzbekistan": "One of the founders is not a resident of the Republic of Uzbekistan",
    "Add-access-to-an-employee": "Add access to an employee",
    "Remove-employee": "Remove employee",
    "Also-takes-part-in": "Also takes part in {name}",
    "company-count-plural": "no | {n} companies | {n} companies | {n} companies",
    "Participates-in-only-one-company": "Participates in only one company",
    "Meeting-point": "Meeting point",
    "How-to-meet-with-a-bank-representative": "How to meet with a bank representative",
    "Come-to-me": "Come to me",
    "I-will-come-to-the-bank-branch": "I will come to the bank branch",
    "Find-out-where-exactly-to-look-for-you": "Find out where exactly to look for you",
    "Apartment-office": "Apartment / office",
    "Entrance": "Entrance",
    "Floor": "Floor",
    "Intercom": "Intercom",
    "The-meeting-will-take-no-more-than-15-minutes-when-is-convenient-for-you": "The meeting will take no more than 15 minutes, when is convenient for you?",
    "Bank-Branch": "Bank branch",
    "In-this-department-you-will-be-able-to-resolve-all-emerging-issues": "In this department you will be able to resolve all emerging issues.",
    "Completely-satisfied": "Completely satisfied",
    "Partially-satisfied": "Partially satisfied",
    "Meeting-date-and-time": "Meeting date and time",
    "The-meeting-will-take-no-more-than-15-minutes": "The meeting will take no more than 15 minutes",
    "There-are-count-documents-available-on-this-case": "{count} documents available for this case",
    "Case-category": "Case category",
    "Court-name": "Court name",
    "Plaintiff": "Plaintiff",
    "Defendant": "Defendant",
    "Basics": "Basics",
    "Government-procurement": "Government procurement",
    "Court-cases": "Court cases",
    "Connections": "Connections",
    "Customer": "Customer",
    "Provider": "Provider",
    "amount-contract-for-sum": "{amount} contract for {sum} UZS",
    "Registration-number": "Registration number",
    "Registration-date": "Registration date",
    "Registration-Center": "Registration Center",
    "Cases-involving-a-company": "Cases involving a company",
    "By-founder": "By founder",
    "By-manager": "By manager",
    "By-individual-entrepreneur": "By individual entrepreneur",
    "Founders": "Founders",
    "OPF": "OPF",
    "SOOGU": "SOOGU",
    "Statistics-codes": "Statistics codes",
    "About-registration": "About registration",
    "Counterparty": "Counterparty",
    "Category": "Category",
    "SME": "SME",
    "Certificate-number": "Certificate number",
    "Start-of-activity": "Start of activity",
    "End-of-activity": "End of activity",
    "could-not-upload-try-again": "Couldn't upload a file, try again.",
    "email-not-required": "email not required",
    "your-inquiry-is-created": "Your inquiry for opening an account is created",
    "manager-will-contact-you-shortly": "The bank manager will contact the client shortly - monitor the status.",
    "LLC": "LLC",
    "IE": "IE",
    "Transfers-to-other-banks": "Transfers to other banks",
    "Payment-fees": "Payment fees",
    "SWIFT-transfers": "SWIFT transfers",
    "Cash-withdrawal-at-the-cash-desk": "Cash withdrawal at the cash desk",
    "Acquiring": "Acquiring",
    "Transfers-via-Kapitalbank": "Transfers via Kapitalbank",
    "Supervisor": "Supervisor",
    "search-counterparty-drawer-footer-text": "This service is provided exclusively by Assistant Business Market LLC. JSCB \"Kapitalbank\" is not responsible for the accuracy of information received through the \"Counterparty Verification System\" and for the consequences of using the information posted on it, as well as for possible loss or damage to data, as well as other consequences of any nature that may occur.",
    "Payment-approved": "Payment approved",
    "Payment-signed": "Payment signed",
    "to-account": "To account",
    "swift-transfer": "SWIFT - transfer",
    "After-sending-to-bank-payment-will-be-displayed-in-history-section": "After sending to the bank, the payment \nwill be displayed in the history section.",
    "Go-to-history": "Go to history",
    "no-payments-for-sign": "No payments for signing yet.",
    "create-new-payment-he-will-be-here-for-sign": "Create a new payment, it will appear here for signature",
    "if-no-virtual-signature": "If no  a virtual signature",
    "payments-on-sign": "Payments upon signing",
    "sign-documents-by-signature": "Sign documents with a virtual signature or digital signature key.",
    "do-you-really-want-delete-this-document": "Do you really want to delete this document?",
    "signing": "For signing",
    "counterparty-empty-founders": "No information about counterparty in other companies as a founder or manager.",
    "Create-Bank24-report": "Create a Bank24 report",
    "counterparty-deal-subtitle": "No. {lotDisplayNo} from {dealDate}",
    "current-payrolls": "Current payrolls",
    "payrolls-bank24": "Payrolls from Bank24",
    "Statement-of-documents-posted-for-the-period": "Statement of documents posted for the period (xls)",
    "Balance-turnover-statement": "Balance turnover statement (txt)",
    "Statement-of-personal-accounts": "Statement of personal accounts",
    "Statement-of-personal-accounts-extended": "Statement of personal accounts extended (txt)",
    "Reports-history-of-cards-and-terminals": "Reports (History of cards and terminals)",
    "Type-of-statement": "Type of statement",
    "Terminal-and-card-account": "Terminal and card accoun",
    "Terminal-UPI": "Terminal (UPI)",
    "Terminal-HUMO": "Terminal (HUMO)",
    "At-approving-number": "At approving (n)",
    "Approved-at": "Approved on {date} at {time}",
    "Signed-at": "Signed on {date} at {time}",
    "date-idn-or-number": "Date, identification number or number",
    "send-on-account": "Which account to send?",
    "currency-rate-and-sum": "Currency rate and sum",
    "currency-rate": "Currency rate",
    "contract-info": "Contract information",
    "upload-template-we-will-create-docs-automatically": "After filling out the template on your computer, drag the file into the upload window. We will create the document(s) automatically.",
    "Statements-ibk": {
      "account-statement": "Account statement (old sample)",
      "payment-documents": "Payment documents (old sample)",
      "account-statement-for-period": "Account statement for the period (old sample)",
      "statement-of-documents-processed": "Statement of processed documents for the period (old sample)",
      "certificate-of-account-operation": "Certificate of account operation (old sample)",
      "saldo": "Balance turnover statement (old sample)",
      "statement-of-personal-accounts": "Issuance of personal accounts (old sample)",
      "statement-of-personal-accounts-extended": "Extended statement of personal accounts (old sample)",
      "terminal": "Terminal and card account (old sample)",
      "terminal-upi": "UPI terminal (old model)",
      "terminal-humo": "HUMO terminal (old model)",
      "terminal-report": "Report on terminals (old sample)"
    },
    "created-singular": "Created",
    "pinfl": "PINFL",
    "currency-amount-and-sum": "Currency amount and sum",
    "Not-enough-money-balance-is": "Insufficient funds. Account balance is: ",
    "x-no-more-y-mb": "{0} no more than {1} MB",
    "countries-receiver-bank": "Countries of receiver bank transfer",
    "search-by-contry": "Search by country",
    "New-Bank24-report": "New Bank24 report",
    "These-are-old-reports-transferred-without-changes-from-the-old-personal-account": "These are old reports, transferred without changes from the old personal account.",
    "Loan-repayment-account-if-any": "Loan repayment account (if any)",
    "written-off": "Will be written off",
    "Sort-type": "Sort type",
    "You-can-select-multiple-terminals": "You can select multiple terminals.",
    "Data-will-be-updated-after-sending-to-bank": "The data will be updated after sending to the bank.",
    "Swift-bic-invalid": "Must contain 8 or 11 characters",
    "amount-and-source": "Amount and source",
    "will-enroll": "To be enrolled",
    "Account-or-card-number": "Account or card number",
    "more-count": "more",
    "conversion-course-will-be-set-while-proceed": "Conversion course will be set while proceeding payment",
    "create-document-by-template-failed": "Create a document  by template failed",
    "get-sms-code": "Get sms code",
    "cant-get-sms": "Can't get SMS?",
    "get-email-code": "Get email code",
    "enter-code-from-email": "Enter the code from your email",
    "we-sent-code-on-email": "We sent the code on your email",
    "or": "or",
    "change-phone-guide-no-virtual": "Confirm the change of the number with an ESK (electronic signature key).\n\nInsert the USB flash drive with the key into the computer to proceed with the ESK (electronic signature key).",
    "Add-an-employee": "Add an employee",
    "provided_access_for_partner": "Access provided to the partner",
    "provided_access_details": "Log into your partner account to invite clients and earn with the bank",
    "provided_access_checkout_to_profile": "Go to partner profile",
    "receiver-card-number": "Receiver's card number",
    "cardholder-name": "Cardholder name",
    "treasury-account-or-name": "Treasury account or name",
    "treasury-account-selecting": "Treasury account selection",
    "payer-info": "Payer's details",
    "who-sends": "Who sends",
    "payer-name": "Payer's name",
    "payer-number": "Taxpayer number",
    "payer-address": "Payer's address",
    "sender-bank-name": "Sender's bank name",
    "sender-bank-code": "Sender's bank code",
    "operation-info": "Transaction information",
    "imported-documents-amount": "Total amount of uploaded documents",
    "plural-payments": "{n} payments | {n} payment | {n} payment | {n} payments",
    "who-pays-commission": "Who pays the commision?",
    "additional-payment-details": "Additional payment details",
    "intermediary-bank-if-any": "Intermediary bank, if applicable",
    "by-swift-code": "By SWIFT code",
    "manually": "Manually",
    "for-period-from-to": "For the period from {0} to {1}",
    "from-date": "For {0}",
    "sign-with-signature-key": "Signing using an EDS key",
    "total-sum-payments": "Total amount of payments",
    "total-amount-payments": "Total amount payments",
    "select-intermediary-bank": "Selecting an intermediary bank",
    "intermediary-bank": "Intermediary bank",
    "bank-name-swift-code-or-address": "Bank name, SWIFT code or address",
    "swift-code": "SWIFT code",
    "intermediary-bank-country": "Country of intermediary bank",
    "select-intermediary-bank-country": "Selecting the country of the transfer intermediary bank",
    "search-by-number-name-or-code": "Search by number, name or code",
    "bic-code": "BIC code",
    "intermediary-name": "Intermediary name",
    "receiver-bank-account": "Recipient's bank account",
    "receiver-bank-country": "Country of the transfer recipient's bank",
    "select-receiver-bank-country": "Selecting the transfer recipient's bank country",
    "signed-payments-amount": "Number of signed payments",
    "not-signed-payments-amount": "Number of unsigned payments",
    "payments": "Payments",
    "signed": "signed",
    "some-payments-not-signed-try-again": "Some payments remained unsigned. Try signing again.",
    "approved": "approved",
    "approved-payments-amount": "Number of approved payments",
    "not-approved-payments-amount": "Number of unapproved payments",
    "receiver-bank-name-and-address": "Name and address of the recipient's bank",
    "approve-with-signature-key": "Approval using the digital signature of the key",
    "select-all-payments": "Select all payments",
    "select-all-payments-on-current-page": "Select all payments on the current page",
    "cancel-select": "Cancel selection",
    "currency-operation-code": "currency-operation-code",
    "swift-sha": "swift-sha",
    "swift-our": "swift-our",
    "Almost-ready-lets-complete-the-check": "Almost-ready-lets-complete-the-check",
    "receiver-name-and-address": "receiver-name-and-address",
    "correspondent-account": "correspondent-account",
    "receiver-kpp": "receiver-kpp",
    "Show-other-rates": "Show-other-rates",
    "Hide-other-rates": "Hide-other-rates",
    "not-signed": "not-signed",
    "not-approved": "not-approved",
    "all-payments-not-signed-try-again": "all-payments-not-signed-try-again",
    "your-signature-expired": "your-signature-expired",
    "go-ask-bank": "go-ask-bank",
    "ask-bank": "ask-bank",
    "enter-zero-for-receiver-outside-russia": "enter-zero-for-receiver-outside-russia",
    "counterparty-deals-not-found": "counterparty-deals-not-found",
    "by-customer": "by-customer",
    "by-provider": "by-provider",
    "If-you-need-to-reschedule-meeting-please-contact-support-by-phone-or-telegram": "If-you-need-to-reschedule-meeting-please-contact-support-by-phone-or-telegram",
    "What-you-need-to-have-with-you": "What-you-need-to-have-with-you",
    "Dont-forget-to-prepare-the-documents-from-the-list-so-that-the-meeting-goes-successfully-and-as-quickly-as-possible": "Dont-forget-to-prepare-the-documents-from-the-list-so-that-the-meeting-goes-successfully-and-as-quickly-as-possible",
    "Everything-worked-out-see-you-soon": "Everything-worked-out-see-you-soon",
    "On-the-appointed-day-we-will-call-and-remind-you-about-the-meeting": "On-the-appointed-day-we-will-call-and-remind-you-about-the-meeting",
    "party-id": "party-id",
    "We-could-not-get-through-to-you": "We-could-not-get-through-to-you",
    "Contact-bank-employees-by-phone-or-telegram-to-continue-opening-an-account": "Contact-bank-employees-by-phone-or-telegram-to-continue-opening-an-account",
    "An-additional-meeting-was-scheduled": "An-additional-meeting-was-scheduled",
    "Edit-access": "Edit-access",
    "Add-access": "Add-access",
    "symbols": "symbols",
    "Access-to-payments": "Access-to-payments",
    "Select-terminal": "Select-terminal",
    "Contract-offer": "Contract-offer",
    "Select-date-and-time": "Select-date-and-time",
    "Select-a-meeting-option": "Select-a-meeting-option",
    "Select-this-date-and-time": "Select-this-date-and-time",
    "First-half-of-the-day": "First-half-of-the-day",
    "Second-half-of-the-day": "Second-half-of-the-day",
    "Select-a-meeting-date": "Select-a-meeting-date",
    "Select-a-meeting-time": "Select-a-meeting-time",
    "Company": "Company",
    "Meeting": "Meeting",
    "Select-the-expected-revenue-for-the-year": "Select-the-expected-revenue-for-the-year",
    "Select-a-tariff": "Select-a-tariff",
    "Service-rates": "Service-rates",
    "Status-code": "Status-code",
    "The-meeting-was-a-success": "The-meeting-was-a-success",
    "We-prepare-documents-and-prepare-the-account-for-opening-this-takes-no-more-than-one-working-day": "We-prepare-documents-and-prepare-the-account-for-opening-this-takes-no-more-than-one-working-day",
    "sum-currency": "sum-currency",
    "number-of": "number-of",
    "next": "next",
    "could-not-get-manager": "could-not-get-manager",
    "recipient-bank-copied-successfully": "recipient-bank-copied-successfully",
    "failed-to-copy-recipient-bank": "failed-to-copy-recipient-bank",
    "symbols-count": "symbols-count",
    "munis-modal-description": "In accordance with the requirement of the State Tax Committee of the Republic of Uzbekistan from 13.08.2024, all payments in favor of customs authorities must be made using the MUNIS system, indicating the code of the State Customs Committee in accordance with the directory. MUNIS payments are available from 9:00 to 16:30.",
    "customs-code-directory": "Directory of SCC codes:",
    "City": "City",
    "Street-and-house": "Street-and-house",
    "If-you-have-a-chief-accountant-and-he-is-on-the-company-staff-then-add-access-to-him": "If-you-have-a-chief-accountant-and-he-is-on-the-company-staff-then-add-access-to-him",
    "my-money": "my-money",
    "today": "today",
    "finance-info": "finance-info",
    "expenditure": "expenditure",
    "cards": "cards",
    "invite_to_partnership": "invite_to_partnership",
    "become_bank_partner": "become_bank_partner",
    "partnership-reward": "partnership-reward",
    "partnership-interest": "partnership-interest",
    "partnership-online": "partnership-online",
    "partnership-account": "partnership-account",
    "partnership-go-to-site": "partnership-go-to-site",
    "after_inquiry_we_will_answer": "after_inquiry_we_will_answer",
    "name-and-family-name": "name-and-family-name",
    "organization_tin": "organization_tin",
    "partnership_inquiry": "partnership_inquiry",
    "partnership-inquiry-sent": "partnership-inquiry-sent",
    "couldnt-create-inquiry-try-again-later": "couldnt-create-inquiry-try-again-later",
    "account-name": "account-name",
    "exchange-rate-for": "exchange-rate-for"
  }
};