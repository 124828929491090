import { AxiosError } from 'axios';
import { ApiErrorResponse } from '@/shared/types';
import { useAuthStore } from '@/app/store/authStore';
import { IBaseApiErrorManager } from './managers.types';

export default function useApiLogoutManager(): IBaseApiErrorManager {
  return {
    checkIfErrorMatchesTheResponse(error: AxiosError): boolean {
      return !!error.config?.logoutOnError;
    },
    async handleErrorResponse(): Promise<ApiErrorResponse> {
      await useAuthStore().signOut();
    }
  };
}
