import { AxiosError, AxiosRequestConfig } from 'axios';
import axiosInstance from '@/shared/plugins/axios';
import { ApiErrorResponse } from '@/shared/types';
import { useAuthStore } from '@/app/store/authStore';
import { IBaseApiErrorManager } from './managers.types';

export default function useApiRefreshTokenManager(): IBaseApiErrorManager {
  let handler: Promise<boolean> | null = null;

  async function generate(): Promise<boolean | null> {
    if (!handler) {
      handler = useAuthStore()
        .tryToRefreshToken()
        .then((isRefreshTokenUpdated) => {
          setTimeout(() => (handler = null));

          return isRefreshTokenUpdated;
        });
    }

    return handler;
  }

  return {
    checkIfErrorMatchesTheResponse(error: AxiosError): boolean {
      return (
        !error.config?.skipRefreshTokenHandling &&
        error.response?.status === 401
      );
    },
    async handleErrorResponse(error: AxiosError): Promise<ApiErrorResponse> {
      const originalConfig = error.config;
      const refreshTokenGenerated = await generate();

      if (refreshTokenGenerated) {
        if (originalConfig?.headers?.Authorization)
          delete originalConfig.headers.Authorization;

        return axiosInstance.request(originalConfig as AxiosRequestConfig);
      }
    }
  };
}
