import {
  createRouter,
  createWebHistory,
  RouterScrollBehavior
} from 'vue-router';
import routes from './routes';

interface IScrollPosition {
  top: number;
  left: number;
}
const scrollBehavior = ((_, __, savedPosition: IScrollPosition) => {
  return savedPosition;
}) as RouterScrollBehavior;

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior
});

router.onError((error, to) => {
  if (
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed')
  ) {
    window.location = to.fullPath as unknown as Location;
  }
});

export default router;
