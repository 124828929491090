import { IBaseApiErrorManager } from './managers.types';
import useApiLogoutManager from './useApiLogoutManager';
import useApiMessageManager from './useApiMessageManager';
import useApiPassErrorManager from './useApiPassErrorManager';
import useApiRefreshTokenManager from './useApiRefreshTokenManager';
import useApiRetryManager from './useApiRetryManager';

export default function useManagers(): IBaseApiErrorManager[] {
  return [
    useApiLogoutManager(),
    useApiRetryManager(),
    useApiRefreshTokenManager(),
    useApiPassErrorManager(),
    useApiMessageManager()
  ];
}
