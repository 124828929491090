
export const settingsRoutes = [
  {
    path: '/settings/change-password',
    name: 'change-password',
    component: () =>
      import('@/pages/settings/change-password/ChangePasswordPage.vue')
  },
  {
    path: '/settings/telegram-notifications',
    name: 'telegram',
    component: () => import('@/pages/settings/telegram/TelegramPage.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/pages/settings/SettingsPage.vue')
  }
];
