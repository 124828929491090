// https://gist.github.com/mikemunsie/d58d88cad0281e4b187b0effced769b2

import kazFlagIcon from '@/shared/icons/kaz-flag.svg?component';
import kgzFlagIcon from '@/shared/icons/kgz-flag.svg?component';
import rusFlagIcon from '@/shared/icons/rus-flag.svg?component';
import tjkFlagIcon from '@/shared/icons/tjk-flag.svg?component';
import uzbFlagIcon from '@/shared/icons/uzb-flag.svg?component';
import { ISelectPhoneInputOption } from '@/shared/types/select.types';

export const countryPhoneCodesList: ISelectPhoneInputOption[] = [
  {
    label: '+998',
    value: '860',
    flag: uzbFlagIcon,
    mask: '+998 (##) ### ## ##',
    iso: {
      short: 'UZ',
      long: 'UZB',
      code: '860'
    }
  },
  {
    label: '+7',
    value: '643',
    flag: rusFlagIcon,
    mask: '+7 (###) ### ## ##',
    iso: {
      short: 'RU',
      long: 'RUS',
      code: '643'
    }
  },
  {
    label: '+7',
    value: '398',
    flag: kazFlagIcon,
    mask: '+7 (###) ### ## ##',
    iso: {
      short: 'KZ',
      long: 'KAZ',
      code: '398'
    }
  },
  {
    label: '+996',
    value: '417',
    flag: kgzFlagIcon,
    mask: '+996 (###) ### ###',
    iso: {
      short: 'KG',
      long: 'KGZ',
      code: '417'
    }
  },
  {
    label: '+228',
    value: '762',
    flag: tjkFlagIcon,
    mask: '+228 (##) ### ###',
    iso: {
      short: 'TJ',
      long: 'TJK',
      code: '762'
    }
  }
];
